import { LoadingSmall } from "@components/Utils/Loading";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { AiOutlineClose, AiOutlineUp } from "react-icons/ai";
import { secondsToMinSec } from "@components/Utils/Functions";
import React from "react";
import { MusicType } from "../MusicPlayer";

interface MiniPlayerProps {
  isLoadingMusic?: boolean;
  toastMsg?: string;
  musicType?: MusicType;
  logo_image?: string;
  title?: string;
  description?: string;
  staff_status?: "staff" | string;
  currentTime?: number;
  totalDuration?: { current: number };
  music?: string;
  isPlayingLesson?: boolean;
  handelClose?: () => void;
  togglePlay?: () => void;
  handleShowBigModal?: () => void;
  handelClosePlayer?: () => void;
}

const MiniPlayer: React.FC<MiniPlayerProps> = ({
  isLoadingMusic,
  toastMsg,
  musicType,
  logo_image,
  title,
  description,
  staff_status,
  currentTime,
  totalDuration,
  music,
  isPlayingLesson,
  handelClose,
  togglePlay,
  handleShowBigModal,
  handelClosePlayer,
}) => {
  return (
    <div
      className={`relative flex h-[80px] w-full flex-row justify-between rounded-lg border border-darkGray2 bg-activeMenu p-2 pl-4 pr-4 shadow-2xl md:w-[728px] items-center`}
    >
      {isLoadingMusic && (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full opacity-50 bg-activeMenu">
          <LoadingSmall />
        </div>
      )}

      <div className="flex items-center w-2/4">
        {musicType === "sleepSound" ? (
          <>
            <div className="mr-5 h-10 w-10 max-w-[40px] overflow-hidden rounded-full">
              <img
                src={logo_image}
                alt="Avatar"
                className="object-cover w-full h-full"
              />
            </div>
            <p
              className="... max-w-[280px] truncate text-lg font-bold"
              data-testid="music-title"
            >
              {title}
            </p>
          </>
        ) : (
          <div>
            <p className="text-1xl ... truncate font-bold">{description}</p>
            <p className="text-sm">{title}</p>
          </div>
        )}
      </div>

      <div className="relative flex items-center justify-end w-2/4">
        {(staff_status === "staff" || musicType === "sleepSession") &&
          !isLoadingMusic && (
            <div className="flex items-center justify-center gap-1 mt-1 mr-2">
              <p>{secondsToMinSec(Number(currentTime))}</p>
              <span>/</span>
              <p>{secondsToMinSec(Number(totalDuration?.current))}</p>
            </div>
          )}

        {isPlayingLesson && !isLoadingMusic ? (
          <PauseIcon
            className="w-10 h-10 mr-2 text-white cursor-pointer"
            onClick={togglePlay}
            data-testid="stop-music-button"
          />
        ) : (
          <PlayIcon
            className="w-10 h-10 mr-2 text-white cursor-pointer"
            onClick={togglePlay}
          />
        )}
        {/* {
            musicType === "sleepSession" && <AiOutlineUp className="w-6 h-6 mr-4 text-white cursor-pointer" onClick={handleShowBigModal} />
          } */}

        <AiOutlineUp
          className="w-6 h-6 mr-4 text-white cursor-pointer"
          onClick={handleShowBigModal}
        />

        <AiOutlineClose
          className="absolute top-0 right-0 w-4 h-4 text-white cursor-pointer"
          onClick={handelClosePlayer}
        />
      </div>
    </div>
  );
};

export default MiniPlayer;
