import { useToday } from "backend/hooks/useUser";
import CloseButton from "components/CloseButton";
import { FC, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoChevronBackSharp } from "react-icons/io5";
import { VscDebugRestart } from "react-icons/vsc";
import { useParams } from "react-router-dom";
import CardButton from "./CardButton";
import { Loading } from "@components/Utils/Loading";
import CustomButton from "@components/CustomButton";
import {
  useCompleteLessonProgress,
  useLesson,
  useStartLessonProgress,
  useUpdateLessonProgress,
} from "@backend/hooks/useLessons";
import { format } from "date-fns";
import { localDel, localPut, localSessionGet } from "@backend/storage";
import QuizResult from "./QuizResult";
import QuizItem from "./QuizItem";
import "./DetailLessonCard.css";

const DetailLessonCard: FC = () => {
  const { id } = useParams();

  const { data, isLoading, refetch: fetchLesson } = useLesson(id);
  const { refetch: fetchTodayData } = useToday();

  const [contentPage, setContentPage] = useState<number>(0);
  const lessonContent = data?.body?.lesson?.items;

  const startLessonProgress = useStartLessonProgress();
  const updateLessonProgress = useUpdateLessonProgress();

  const completeLessonProgress = useCompleteLessonProgress();

  const view = localSessionGet("view");

  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   if(view && view==="web"){
  //     searchParams.set('hide', 'true');
  //     setTimeout(() => {
  //       navigate({ pathname: location.pathname, search: searchParams.toString() });
  //     }, 0);
  //   }
  // }, [view]);

  const startLessonFromScratch = async (id: any, total_items: any) => {
    const startMutationData = {
      author_id: "",
      item_id: id,
      len: total_items,
      type: "lesson",
      loop: false,
      timer_dur: 0,
      timer_remain: 0,
    };
    try {
      await startLessonProgress(startMutationData).catch((e) => {
        throw e;
      });
    } catch (e) {
      await startLessonProgress(startMutationData);
    }
    fetchLesson();
  };

  const updateLessonIfNotComplete = async (progress: { id: any; len: any }) => {
    const { id } = progress;

    const updateMutationData = {
      id,
      position: contentPage + 1,
      increment: 1,
      loop: false,
      timer_dur: 0,
      timer_remain: 0,
    };

    updateLessonProgress(updateMutationData);
    setContentPage((prevContentPage) => prevContentPage + 1);
  };

  const restartLesson = async () => {
    const lessonid = data?.body?.lesson?.id;
    const totalItems = data?.body?.lesson?.total_items;
    startLessonFromScratch(lessonid, totalItems);
    setContentPage(0);
  };

  const endLesson = async () => {
    const { id } = data.body.lesson.progress;

    const completeMutationData = {
      id,
      position: contentPage - 1, // We loop past on the final item
      increment: 1,
      completed_local_date: format(new Date(), "yyyy-MM-dd"),
    };
    try {
      await completeLessonProgress(completeMutationData);
    } catch (e) {
      await completeLessonProgress(completeMutationData);
    }
    fetchLesson();

    fetchTodayData();
  };

  useEffect(() => {
    if (
      data?.body?.lesson?.progress &&
      contentPage === data?.body?.lesson?.progress?.len - 1
    ) {
      endLesson();
    }
  }, [contentPage]);

  const handelNextPage = async () => {
    const { progress } = data.body.lesson;
    // * First of all we didn't update the lesson page # when we clicked next page button the first time.
    // * if we update the state here it will take some time and our if condition will fail so we will store the current state in a new variable and then increment the page number to check.
    let newPage = contentPage + 1;
    // * Now I didn't update the state yet because it is being updated in updateLessonIfNotComplete() fn. so it will cause a logical error and the page will be + 2;
    localPut("viewPastLesson", true);
    // * checking page number
    if (newPage === 0) {
      const lessonid = data?.body?.lesson?.id;
      const totalItems = data?.body?.lesson?.total_items;
      setLoader(true);
      await startLessonFromScratch(lessonid, totalItems); // needs to return progress ID before we can proceed.
      setContentPage(newPage);
      setLoader(false);
    } else {
      await updateLessonIfNotComplete(progress);
    }
  };

  const handelPreviousPage = () => {
    if (contentPage > 0) {
      setContentPage(contentPage - 1);
    }
  };

  useEffect(() => {
    localDel("viewPastLesson");

    // * ADDED LESSON START ON FIRST TIME LOAD. First check the lesson details that are being fetched. if the loading has stopped meaning we got the data then make a start call.
    if (!isLoading) {
      const lessonid = data?.body?.lesson?.id;
      const totalItems = data?.body?.lesson?.total_items;
      startLessonFromScratch(lessonid, totalItems);
    }
  }, [isLoading]);

  if (isLoading || loader) {
    return (
      <div className="flex justify-center mt-3 mb-3">
        <Loading />
      </div>
    );
  }

  const handleClose = () => {
    fetchTodayData();
    fetchLesson();
  };

  const handleQuiz = () => {
    const choicesArray: { key: string; value: any }[] = Object.entries(
      lessonContent[contentPage],
    )
      .filter(([key]) => key.startsWith("choice"))
      .map(([key, value]) => ({ key, value }));

    const correctChoiceKey = `choice${lessonContent[contentPage]?.answer}`;

    const correctChoice = choicesArray.find(
      (choice) => choice.key === correctChoiceKey,
    );

    return (
      <>
        {selectedChoice && (
          <QuizResult
            isCorrectAnswer={isCorrectAnswer}
            correctChoice={correctChoice?.value}
            description={lessonContent[contentPage]?.description}
            handleContinue={handleContinue}
          />
        )}
        <QuizItem
          selectedChoice={selectedChoice}
          question={lessonContent[contentPage]?.question}
          options={choicesArray}
          correctChoice={correctChoice?.value}
          handleCorrectAnswer={handleCorrectAnswer}
        />
      </>
    );
  };

  const handleCorrectAnswer = (selectedOption: string, answer: string) => {
    if (selectedOption === answer) {
      setIsCorrectAnswer(true);
    } else {
      setIsCorrectAnswer(false);
    }

    setSelectedChoice(selectedOption);
  };

  const handleContinue = () => {
    setSelectedChoice(null);
    setIsCorrectAnswer(false);

    handelNextPage();
  };

  return (
    <div className={`flex flex-col m-auto`}>
      {!view && view !== "web" && (
        <CloseButton
          Icon={<AiOutlineClose />}
          backPressed={handleClose}
          text=" Close"
          className="mt-3"
          backLink="/lesson-detail"
        />
      )}

      {contentPage > 0 && (
        <CardButton
          Icon={<IoChevronBackSharp />}
          handleClick={handelPreviousPage}
          text="Previous Page"
        />
      )}

      <div className="bg-darkGray2 p-4 rounded-lg shadow mb-2 mt-1 max-w-[530px] w-full lesson-content">
        <div className="inline-flex flex-col justify-between align-center">
          {lessonContent && !lessonContent[contentPage]?.question && (
            <div
              dangerouslySetInnerHTML={{
                __html: lessonContent[contentPage]?.content,
              }}
              className="mb-6 text-sm font-light opacity-80 customTextWhite"
            ></div>
          )}
          {data?.body?.lesson?.total_items !==
            lessonContent[contentPage]?.order &&
            !lessonContent[contentPage]?.question && (
              <button
                onClick={handelNextPage}
                className="blueBtn enabled:hover:opacity-80"
              >
                {lessonContent[contentPage]?.next_btn_text}
              </button>
            )}
        </div>
        {lessonContent[contentPage]?.question && handleQuiz()}
      </div>

      {data?.body?.lesson?.progress &&
        contentPage === data?.body?.lesson?.progress?.len - 1 && (
          <CardButton
            Icon={<VscDebugRestart />}
            handleClick={restartLesson}
            text="Start Over"
          />
        )}
    </div>
  );
};

export default DetailLessonCard;
