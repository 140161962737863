import React, { useState, FC, useEffect } from "react";
import AccordionContainer from "./AccordionContainer";
import { TimePicker } from "react-ios-time-picker";
import { useUser, useUpdateUserSettings } from "@backend/hooks/useUser";
import { convertTime, formatTime } from "@components/Utils/Functions";
import { handleOpenTimePicker } from "@components/Utils/TimePicker";

const BedTimeSelection: FC = () => {
  const { data: { body: { settings = {} } = {} } = {}, refetch } = useUser();

  const mutateUserSettings = useUpdateUserSettings(refetch);

  const [bedTime, setBedTime] = useState(
    () => formatTime(settings.bedtime_hour, settings.bedtime_min) || "11:00 PM",
  );
  useEffect(() => {
    setBedTime(
      formatTime(settings.bedtime_hour, settings.bedtime_min) || "11:00 PM",
    );
  }, [settings]);

  const onChange = (timeValue: string) => {
    setBedTime(timeValue);
  };

  const handleSave = async (data: string) => {
    const { hours, minutes } = convertTime(data);

    const apiData = { ...settings, bedtime_hour: hours, bedtime_min: minutes };

    await mutateUserSettings.mutate(apiData);
  };

  return (
    <AccordionContainer>
      <div onClick={handleOpenTimePicker}>
        <TimePicker
          onOpen={handleOpenTimePicker}
          onChange={onChange}
          value={bedTime}
          use12Hours
          inputClassName="border-none small-white-text cursor-pointer"
          id="bedtime"
          name="bedtimepicker"
          onSave={handleSave}
        />
      </div>
    </AccordionContainer>
  );
};

export default BedTimeSelection;
