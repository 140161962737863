import { TrophyFilled } from "@ant-design/icons";
import RibbonIcon from "@assets/icons/RibbonIcon";
import React from "react";

type PropType = {
  points: number;
  dailyPoints: number;
};
// * Header that will contains Total Points above Challenges list.
function InfiniteChallengeHeader({ points, dailyPoints }: PropType) {
  return (
    <div className={"mx-2 mt-1 flex w-full cursor-pointer flex-col-reverse items-start gap-4 rounded-lg bg-blueC1 p-3 text-[inherit] text-white shadow sm:flex-row sm:items-center sm:gap-8"}>
      <div className="flex items-center">
        <div className="m-auto min-w-[3.5em] rounded-lg bg-darkGray2 px-2 py-0.5 text-center text-sm font-bold text-white sm:min-w-[4em] sm:px-2.5 sm:py-1 sm:text-[inherit]">{dailyPoints}</div>
        <div className="ml-2 flex items-center gap-2 sm:ml-4">
          <RibbonIcon className="w-[19px] min-w-[19px] fill-white sm:w-[22px] sm:min-w-[22px]" />
          <span className="font-bold">Daily Points</span>
        </div>
      </div>
      <div className="flex items-center">
        <div className="m-auto min-w-[3.5em] rounded-lg bg-darkGray2 px-2 py-0.5 text-center text-sm font-bold text-white sm:min-w-[4em] sm:px-2.5 sm:py-1 sm:text-[inherit]">{points}</div>
        <div className="ml-1.5 flex items-center gap-2 sm:ml-4">
          <TrophyFilled className="min-w-[20px] fill-white sm:min-w-[23px]" />
          <span className="font-bold ">Total Points</span>
        </div>
      </div>
    </div>
  );
}

export default React.memo(InfiniteChallengeHeader);
