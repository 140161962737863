import { useUpdateUserTimeZone, useUser } from "backend/hooks/useUser";
import React, { useState, useEffect } from "react";
import TimezoneSelection from "./TimezoneSelection";
import AccordionContainer from "./AccordionContainer";

const TimezoneSetting = () => {
  const {
    data: {
      body: {
        user: { timezone },
      },
    },
    refetch,
  } = useUser();

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [selectedTimezone, setSelectedTimezone] = useState(timezone || "");

  let mutateUserTimeZone = useUpdateUserTimeZone(refetch);

  useEffect(() => setSelectedTimezone(timezone || ""), [timezone]);

  const handleTimeZoneUpdate = async () => {
    if (timezone !== selectedTimezone && selectedTimezone !== "") {
      await mutateUserTimeZone({ timezone: selectedTimezone });
    }
  };

  const handleCurrentTimeZoneUpdate = () => {
    mutateUserTimeZone(null);
  };

  return (
    <AccordionContainer>
      <p className="small-white-text mb-3 sm:text-base">
        Currently set as: {timezone}
      </p>
      <p
        className={`small-white-text mb-3 sm:text-base ${currentTimeZone !== timezone && "text-journal-red"} `}
      >
        {currentTimeZone === timezone ? (
          `Your current time zone is ${currentTimeZone}`
        ) : (
          <>
            You are currently in the timezone {currentTimeZone} which doesn’t
            match your current setting of {timezone}.
            {currentTimeZone !== timezone && (
              <span
                className="cursor-pointer text-sm font-normal text-blue-500 underline ml-2 sm:text-base"
                onClick={handleCurrentTimeZoneUpdate}
              >
                Click to Update
              </span>
            )}
          </>
        )}
      </p>

      <div className="w-full items-start sm:flex">
        <div className="sm:w-1/4">
          <p className="small-white-text mb-3 leading-none sm:text-base">
            Set Manually
          </p>
        </div>
        <TimezoneSelection
          className="flex-grow"
          onChange={setSelectedTimezone}
          value={selectedTimezone}
        />
      </div>
      <div className="mt-3 flex items-center justify-end gap-x-3">
        <span
          className="cursor-pointer rounded-md bg-journal-blue p-[5px] px-3 text-sm font-medium sm:text-base"
          onClick={handleTimeZoneUpdate}
        >
          Update
        </span>
      </div>
    </AccordionContainer>
  );
};

export default TimezoneSetting;
