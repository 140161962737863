import { LeftOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IGobackButton {
  className?: string;
  backTitle?: string;
  backPressed?: () => void;
  backLink?: string;
}

const GobackButton: FC<IGobackButton> = ({
  className,
  backTitle,
  backPressed,
  backLink,
}) => {
  let navigate = useNavigate();
  let location = useLocation();
  const backTo = location?.state?.from;

  // * Previously they didn't check if the user is new to page like he clicked a link so there won't be any back for this except the browser homepage so we need to place a check on history if history is >2 means one is the link you opened and one will be the browser / tab home page so it must be greater than 2 to have a back state.
  const handleBack = () => {
    if (window.history.length > 2) navigate(-1);
    else {
      if (backLink) {
        navigate(backLink);
      } else navigate("/");
    }
    backPressed && backPressed();
  };

  return (
    <div
      className={`bg-darkGray2 p-4 rounded-lg shadow  mb-3 w-full ${className} `}
      data-testid="gobackbutton"
    >
      <div className=" flex items-center">
        <LeftOutlined className="cursor-pointer" onClick={handleBack} />
        <p
          className="ml-3 cursor-pointer"
          onClick={handleBack}
          data-testid="gobackbuttontext"
        >
          {backTitle ? backTitle : `Go Back ${backTo ? "To " + backTo : ""}`}
        </p>
      </div>
    </div>
  );
};

export default GobackButton;
