import { getATAndClear, getQueryStringParam } from "@backend/query";
import { API_URL } from "./constants";
import { getAT, getUserFromStorage, sendApiJson, sendRQApiJson } from "./service";
import { findAuthToken, logout } from "@backend/auth_funcs";
import { localPut } from "@backend/storage";
import { anaIdentify } from "@backend/ana";
import { APIError } from "@backend/api/service";
import { Filters } from "@backend/hooks/useStats";

export const apiLogin = async (email: string) => {
  return await sendRQApiJson("POST", "/users/login", null, { email });
};

/**
 * @description Clear LocalStorage.
 */
export function clearLocalStorage() {
  localStorage.clear();
}

export const apiUseCode = async ({ email, pin }: { email: string; pin: string }) => {
  const data = await sendRQApiJson("POST", "/users/code", getAT(), {
    email,
    code: pin,
  });
  if (!data || !data.auth_token) {
    throw new Error("No auth token returned");
  }
  storeUserData(data);
};

export const apiGetUserGroup = async (id: string) => {
  return await sendApiJson("GET", `/users/groups/${id}`, "", null);
};

export const apiGetToday = async () => {
  return await sendApiJson("GET", `/users/today`, getAT(), null);
};

// Going to throw this error if we don't have an auth token
export class LoggedOutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "LoggedOutError";
  }
}

const storeUserData = (data: { auth_token: string; body: { user: any; group: any } }) => {
  localPut("at", data.auth_token);
  localPut("user", data.body.user);
  localPut("group", data.body.group);
  anaIdentify(data.body.user.id, data.body.user.email);
  getATAndClear();
};

// This just doesnt work if we pass in the AT like due to react-query caching he
export const apiGetUser = async () => {
  // We use find AT for this call is it's our primary way of confirming auth when the app is starting up
  const at = findAuthToken();
  if (!navigator.onLine) {
    return "Error";
  }
  // Assume we'll have thrown an error inside this function on failure
  if (!at) {
    // throw new LoggedOutError('No auth token present');
    return "No auth token present";
  }
  try {
    const data = await sendRQApiJson("GET", `/users`, at, null);
    storeUserData(data);
    return data;
  } catch (error) {
    console.log("GET USER ERROR!: ", error);

    if (error instanceof APIError) {
      console.log("GET USER APIERROR!: ", error?.code, error?.title, error?.msg);
      if (error.code === "INVALID_AUTH_TOKEN") {
        // TODO this needs to be properly handled, for now we just kill their local storage
        // and force a reload
        console.log("*** Logging user out");
        localStorage.clear();
        window.location.href = "/";
        // window.history.replaceState(null, "", "/");
        // setTimeout(() => window.location.reload(), 0);
      }
    }
    return error;
  }
};

export const apiPostUserLt = async (lt: string | null) => {
  if (!navigator.onLine) {
    return "Error";
  }
  // Assume we'll have thrown an error inside this function on failure
  if (!lt) {
    // throw new LoggedOutError('No auth token present');
    return "No auth token present";
  }
  try {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = await sendRQApiJson("POST", `/users/login-lt`, lt, {
      timezone,
    });
    storeUserData(data);
    return data;
  } catch (error) {
    console.log("apiPostUserLt ERROR!: ", error);
    if (error instanceof APIError) {
      console.log("apiPostUserLt APIERROR!: ", error?.code, error?.title, error?.msg);
      if (error.code === "INVALID_AUTH_TOKEN") {
        // TODO this needs to be properly handled, for now we just kill their local storage
        // and force a reload
        console.log("*** Logging user out");
        localStorage.clear();
        const linkParam = getQueryStringParam("link");
        if (linkParam !== null && linkParam.startsWith("posts")) {
          // Strange case, if their session is no good and they are trying to view a posts page,
          // redirect them to the website sans auth
          window.location.href = `${process.env.REACT_APP_WEB_ENDPOINT}/${linkParam}`;
        } else {
          window.location.href = "/";
        }
      }
    }
    return error;
  }
};

// Fire and forget user progress events
// Start lesson is a bit odd as it currently calls start then immediately calls
// progress update which the client really shouldnt be concerned with so let's take
// care of that complexity here
export const apiProgressStartLesson = async (lessonID: string) => {
  return await sendApiJson("POST", "/users/progress/start", getAT(), {
    item_id: lessonID,
    type: "lesson",
  });
};

/*
{
    "id": "hckrvsahxai63bojraq3zs72na:lesson:lesson40",
    "increment": 1,
    "loop": false,
    "position": 1,
    "timer_dur": 0,
    "timer_remain": 0
}
*/
export const apiProgressUpdateLesson = async (lessonID: string, position: number) => {
  const u = getUserFromStorage();
  return await sendApiJson("POST", "/users/progress/update", getAT(), {
    id: `${u.id}:lesson:${lessonID}`,
    increment: 1,
    position,
  });
};

export const apiProgressCompleteLesson = async (lessonID: string) => {
  const u = getUserFromStorage();
  return await sendApiJson("POST", "/users/progress/complete", getAT(), {
    id: `${u.id}:lesson:${lessonID}`,
  });
};

/**
 *
 * @returns Takes filters state in and converts the filters to search parameters and make Stats API call.
 */
export const apiGetChartData = async (filters: Filters) => {
  // We use find AT for this call is it's our primary way of confirming auth when the app is starting up
  const at = findAuthToken();
  // Assume we'll have thrown an error inside this function on failure
  if (!at) {
    throw new LoggedOutError("No auth token present");
  }
  // * Sample URL for generating Search Params
  let url = new URL("https://www.google.com");
  if (Object.keys(filters).length > 0) {
    Object.keys(filters).forEach((key) => {
      if ((filters as any)[key]) url.searchParams.append(key, (filters as any)[key]);
    });
  }
  // console.log(`${API_URL.STATS_ENDPOINT}${url.search}`);
  const data = await sendRQApiJson("GET", `${API_URL.STATS_ENDPOINT}${url.search}`, at, null);

  return data;
};

export const apiGetSleepHistoryData = async () => {
  // We use find AT for this call is it's our primary way of confirming auth when the app is starting up
  const at = findAuthToken();
  // Assume we'll have thrown an error inside this function on failure
  if (!at) {
    throw new LoggedOutError("No auth token present");
  }
  const data = await sendRQApiJson("GET", `${API_URL.SLEEP_HISTORY_ENDPOINT}`, at, null);

  return data;
};

export const apiGetUserJournalLogs = async (date: string) => {
  const at = findAuthToken();
  if (!at) {
    throw new LoggedOutError("No auth token present");
  }
  const data = await sendRQApiJson("GET", `${API_URL.USER_LOG_ENDPOINT}?day=${date}`, at, null);

  return data;
};

export const apiUpdateUsersLogs = async (data: any) => {
  return await sendApiJson("PUT", "/users/log", getAT(), data);
};

export const apiUpdateUserEveningLogs = async (data: any) => {
  return await sendApiJson("PUT", "/users/nog", getAT(), data);
};

export const apiGetMetaData = async (): Promise<any> => {
  return await sendRQApiJson("GET", `/meta`, getAT(), null);
};

export const apiUpdateUserSettings = async (data: any) => {
  return await sendApiJson("POST", "/users/settings", getAT(), data);
};

export const syncUserTimeZone = async (data: any) => {
  return await sendApiJson("POST", "/users/sync2", getAT(), data);
};

export const getSessionById = async (id: string) => {
  return await sendRQApiJson("GET", `/media/${id}`, getAT(), null);
};
