import { FC } from "react";
import ReactApexChart from "react-apexcharts";
import {
  IChartConfiguration,
  ISleepHistoryChart,
} from "./SleepHistoryInterfaces";

const SleepHistoryChart: FC<ISleepHistoryChart> = ({
  data,
  min,
  max,
  yAxisUnit,
}) => {
  const chartLabels = data?.labels;
  const chartSeries = data?.seriesData;

  const state: IChartConfiguration = {
    options: {
      colors: ["#767090", "transparent"], // Changed line color to blue
      stroke: {
        show: true,
        colors: ["#187ddc", "transparent"], // Changed stroke color to blue
        curve: "smooth",
      },
      xaxis: {
        categories: chartLabels ? chartLabels : [],
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
      yaxis: {
        min: min ? min : 0,
        max: max ? max : 100,
        tickAmount: 4,
        labels: {
          formatter: (value: any) => `${value}${yAxisUnit}`,
          style: {
            color: "#FFF",
            colors: ["#FFF"],
          },
        },
        title: {
          style: {
            color: "#fff",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: "#282828",
        padding: {
          left: 23,
        },
      },
      legend: {
        labels: {
          colors: "#FFF",
        },
      },
    },
    series: chartSeries ? chartSeries : [],
  };

  return (
    <div className="customized-chart w-full block py-4 relative sm:h-[400px] h-[250px]">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="line"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default SleepHistoryChart;
