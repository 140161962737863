import Container from "components/Container";
import Accordion from "components/Settings/Accordion";
import BedTimeSelection from "components/Settings/BedTimeSelection";
import WakeupTimeSelection from "components/Settings/WakeupTimeSelection";
import TimezoneSetting from "components/Settings/TimezoneSetting";
import GenderSelection from "components/Settings/GenderSelection";
import SleepGoal from "components/Settings/SleepGoal";
import TroubleSleeping from "components/Settings/TroubleSleeping";
import AgeSelection from "components/Settings/AgeSelection";
import { useUser, useUserMeta } from "backend/hooks/useUser";
import { keyBy } from "lodash";
import { LoadingPage } from "components/Utils/Loading";
import { localGet, localSessionGet } from "backend/storage";
import { useQueryClient } from "react-query";
import { logout } from "backend/auth_funcs";
import { clearLocalStorageLocationKeys } from "components/Utils/CustomHooks/clearLocalStorageLocations";
import packageInfo from "../../../package.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FC, useEffect } from "react";
import { isB2BGroup, isStaff } from "@components/Utils/Functions";
import { anaScreen } from "@backend/ana";

interface SettingsPageProps {
  collapsed?: boolean;
}

const SettingsPage: FC<SettingsPageProps> = ({ collapsed }) => {
  const {
    isLoading,
    data: { body: { onboard_questions } } = { body: { onboard_questions: [] } },
  } = useUserMeta();
  const { data } = useUser();
  const questionsById = keyBy(onboard_questions, "id");

  const navigate = useNavigate();

  const email = localGet("user").email || "";

  const version = packageInfo.version;

  const queryClient = useQueryClient();

  const handleLogout = async () => {
    try {
      await logout(queryClient);
      window.history.replaceState(null, "", "/");
      clearLocalStorageLocationKeys();
      window.location.reload();
    } catch (err) {
      console.error("Logout Error", err);
    }
  };

  const openMail = () => {
    window.open("mailto:support@chorussleep.com", "_blank");
  };

  const [searchParams] = useSearchParams();
  const hiddenParam = searchParams.get("hide");

  const view = localSessionGet("view");

  useEffect(() => {
    if (view && view === "web") {
      searchParams.set("hide", "true");
      setTimeout(() => {
        navigate({ pathname: "/settings", search: searchParams.toString() });
      }, 0);
    }
  }, [view]);

  useEffect(() => {
    anaScreen("settings_view", null);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Container subClass="p-0" collapsed={collapsed}>
      <Accordion title="What time do you typically go to bed?">
        <BedTimeSelection />
      </Accordion>

      <Accordion title="What time do you typically wake up?">
        <WakeupTimeSelection />
      </Accordion>

      <Accordion title="Timezone">
        <TimezoneSetting />
      </Accordion>

      <Accordion title={getQuestions(questionsById?.sleep_goal)}>
        <SleepGoal data={questionsById?.sleep_goal} />
      </Accordion>

      <Accordion title={getQuestions(questionsById?.nights_per_week)}>
        <TroubleSleeping data={questionsById?.nights_per_week} />
      </Accordion>

      <Accordion title={getQuestions(questionsById?.gender)}>
        <GenderSelection data={questionsById?.gender} />
      </Accordion>

      <Accordion title={getQuestions(questionsById?.age)}>
        <AgeSelection data={questionsById?.age} />
      </Accordion>

      {hiddenParam !== "true" && (
        <>
          <p className="mt-5 text-sm  font-light text-white text-opacity-70">
            You are logged in as
          </p>
          <p>{email}</p>
          <p className=" font-light text-white text-opacity-70">
            Version: {version}
          </p>
          <p
            className="cursor-pointer font-light text-white text-opacity-70 underline"
            onClick={handleLogout}
          >
            Logout
          </p>
          <p
            className="cursor-pointer font-light text-opacity-70 text-white underline"
            onClick={openMail}
          >
            Delete Account
          </p>
        </>
      )}
    </Container>
  );
};

export default SettingsPage;

export const getQuestions = (
  question: { text?: any; questionNumber?: number } = {},
  questionNumber: number = 1,
) => {
  const text = question.text || "";
  const parts = text.split(".");
  if (questionNumber === 2) return parts;
  return parts[questionNumber] ? parts[questionNumber].trim() : text.trim();
};
