import { useState, useEffect, useCallback } from "react";

function useBackButton(): [boolean, () => void] {
  const [isBackButtonPressed, setIsBackButtonPressed] =
    useState<boolean>(false);

  useEffect(() => {
    const handlePopState = () => {
      setIsBackButtonPressed(true);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const reset = useCallback(() => {
    setIsBackButtonPressed(false);
  }, []);

  return [isBackButtonPressed, reset];
}

export default useBackButton;
