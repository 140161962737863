/* eslint-disable react-hooks/exhaustive-deps */
import useSliderPosition from "@components/Utils/CustomHooks/useSliderPosition";
import React, { useState, useEffect } from "react";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";

interface SliderProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  scrollKey: string;
  dataTestid?: string;
}

const Slider: React.FC<SliderProps> = ({
  children,
  width = "w-10",
  height = "h-10",
  scrollKey,
  dataTestid,
}) => {
  const sliderRef = useSliderPosition(scrollKey);

  const [isEnd, setIsEnd] = useState(false);
  const [isStart, setIsStart] = useState(true);

  const checkSliderPosition = () => {
    const container = sliderRef.current;
    if (container) {
      setIsEnd(
        container.scrollWidth - container.offsetWidth <= container.scrollLeft,
      );
      setIsStart(container.scrollLeft === 0);
    }
  };

  useEffect(() => {
    const container = sliderRef?.current;
    if (container) {
      container.addEventListener("scroll", checkSliderPosition);
      return () => {
        container.removeEventListener("scroll", checkSliderPosition);
      };
    }
  }, []);

  const scrollLeft = () => {
    const container = sliderRef.current;
    if (container) {
      // * CHANGED THE SCROLL AMOUNT FROM width of slider div to dynamic first child width.
      // * By Dynamic width => as all child will have the save width and we will scroll the children 1 by 1 either left or right
      // const scrollAmount = container.children[0].scrollWidth * 2 ?? 150;
      const scrollAmount = container.offsetWidth * 0.8;
      const scrollLeft = container.scrollLeft - scrollAmount;
      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
      checkSliderPosition();
    }
  };

  const scrollRight = () => {
    const container = sliderRef.current;
    if (container) {
      // * CHANGED THE SCROLL AMOUNT FROM width of slider div to dynamic first child width.
      // * By Dynamic width => as all child will have the save width and we will scroll the children 1 by 1 either left or right
      // const scrollAmount = container.children[0].scrollWidth * 2 ?? 150;
      const scrollAmount = container.offsetWidth * 0.8;
      const scrollRight = container.scrollLeft + scrollAmount;
      container.scrollTo({
        left: scrollRight,
        behavior: "smooth",
      });
      checkSliderPosition();
    }
  };

  return (
    <div className="relative" data-testid={dataTestid}>
      {!isStart && (
        <div
          className="absolute flex items-center h-full pl-1 "
          onClick={scrollLeft}
          data-testid="backarrow"
        >
          <IoMdArrowBack
            className={`${width} ${height} z-[1] cursor-pointer bg-lightGray1 p-2`}
          />
        </div>
      )}
      {!isEnd && (
        <div
          className="absolute right-0 flex items-center h-full pr-1"
          onClick={scrollRight}
          data-testid="forwardarrow"
        >
          <IoMdArrowForward
            className={`${width} ${height} z-[1] cursor-pointer bg-lightGray1 p-2`}
          />
        </div>
      )}
      <div className="customOverFlowClass" ref={sliderRef}>
        {children}
      </div>
    </div>
  );
};

export default Slider;
