import { useMutation, useQuery } from "react-query";
import { COACH_QUERY } from "./hooksConstant";
import {
  apiGetCoachMessages,
  apiUserSendCoachMessage,
} from "@backend/api/coach";

export const useCoach = () => {
  return useQuery([COACH_QUERY], () => apiGetCoachMessages(), {
    staleTime: 0,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useSendCoachMessage = () => {
  return useMutation((message: string) => apiUserSendCoachMessage(message), {
    onSettled: (data, error) => {
      if (data) {
        console.log("useSendCoachMessage was successful, returned data:");
      } else if (error) {
        console.log("Mutation encountered an error:", error);
      }
    },
  });
};
