import {  anaLogout } from "./ana";
import { clearLocalStorage } from "./api/users";
import { getATAndClear } from "./query";
import { localGet } from "./storage";
import { QueryClient } from "react-query";

export function findAuthToken(): string | null {
  let at: string | null = getATAndClear();

  if (at) {
    return at;
  }

  at = localGet("at");

  if (at) {
    return at;
  }

  return null;
}

// TODO: note the following happens in the 3 spots that we allow the user to log out! Ideally this should all be done in here!
/*
try {
      await logout(queryClient);
      window.history.replaceState(null, "", "/");
      clearLocalStorageLocationKeys();
      window.location.reload();
    } catch (err) {
      console.error("Logout Error", err);
    }
*/
// * Yeah the above has been implemented!.

export const logout = async (queryClient: QueryClient): Promise<void> => {
  console.log("**** Trying to log user out");
  try {
    // * Why Remove one by one when we can use localStorage.clear() to clear the whole?
    // localDel("at");
    // localDel("user");
    // localDel("group");
    // localDel("confirm-login");
    // localDel("sleep-portal");
    // localDel("viewPastLesson");
    // localDel("JournalData");
    // localSessionDel("view");
    anaLogout();
    queryClient.removeQueries("user");
    clearLocalStorage();
    window.history.replaceState(null, "", "/");
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
