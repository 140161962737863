import React, { FC, useEffect, useState, useRef } from "react";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";
import { useUser } from "@backend/hooks/useUser";
import { DEFAULT_USER } from "@pages/Sleep/SleepTodayPage";
import FullPlayer from "./MusicPlayerComponents/FullPlayer";
import MiniPlayer from "./MusicPlayerComponents/MiniPlayer";
import { useNavigate } from "react-router-dom";
import { startSessionProgress, updateSessionProgress } from "@backend/hooks/useSessions";
import { useQueryClient } from "react-query";
import { USER_TODAY } from "@backend/hooks/hooksConstant";
// import useTimer from "@backend/hooks/useTimer/useTimer";

export type MusicType = "sleepSound" | "sleepSession" | "meditation" | string;

interface IMusicPlayer {
  handelClose: () => void;
  musicType?: MusicType;
  currentSound?: any;
  setMusicPlaying?: (action: IMusicAction) => void;
  isPlayingLesson?: boolean;
  isMiniPlayer?: boolean;
}

/**
 *
 * @description
 * This component plays the audio sessions.
 *
 * @returns Music player component that plays the Sleep session / audio's
 */
const MusicPlayer: FC<IMusicPlayer> = (props) => {
  const { handelClose, musicType, currentSound, setMusicPlaying, isPlayingLesson, isMiniPlayer } = props;

  const audioContextRef = useRef<AudioContext | null>(null);

  const navigate = useNavigate();
  const [source, setSource] = useState<AudioBufferSourceNode | null>(null);
  const previousSoundRef = useRef<any>(null);

  const { data: dataUser = DEFAULT_USER } = useUser();
  const staff_status = dataUser?.body?.user?.staff_status ?? "";
  const [ended, setEnded] = useState(false);
  const elapsedTimeRef = useRef<number>(0);

  const totalDuration = useRef(0);

  const [currentTime, setCurrentTime] = useState<number | undefined>(undefined);
  const { title, logo_image, description, content_link: music } = currentSound;

  const [isPlayingRef, setisPlayingRef] = useState(isPlayingLesson);
  // const isPlayingRef = useRef(isPlayingLesson);

  const [isLoadingMusic, setIsLoadingMusic] = useState(false);

  const animationFrameRef = useRef<any>(null);

  const startTimeRef = useRef<number | null>(null);

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [progressId, setProgressId] = useState();

  const [timeAt, setTimeAt] = useState(30);
  const fullPlayerPlayerButtonRef = useRef<HTMLButtonElement | null>(null);
  const initExpdate = new Date();
  initExpdate.setSeconds(initExpdate.getSeconds() + 29); // 30 seconds timer
  const queryClient = useQueryClient();
  // const [expiryTimestamp, setExpiryTimestamp] = useState<Date>(initExpdate);
  // const [prevTimeReference,setPrevTimeReference] = useState(0);
  const [tempCallDuration, setTempCallDuration] = useState(0);
  const [timerPosition, setTimerPosition] = useState(0);

  const [updatePosition, setUpdatePosition] = useState(30);

  /**
   * @description This useEffect is used to track the timer (in seconds) and track 30 seconds to send API update call.
   */
  useEffect(() => {
    // console.log("timer ", timerPosition);
    if (timerPosition % 30 === 0 && timerPosition > 0) {
      handleSendUpdateCall();
    }
  }, [timerPosition]);

  /**
   * @description This Effect is only used to take seconds and remove decimal from duration and store seconds.
   * Is used to track seconds only and set the consecutive timer. This will also check if the rounded value was set before or not then update the timer value.
   */
  useEffect(() => {
    let seconds = Math.floor(currentTime ? currentTime : 0);
    if (seconds !== tempCallDuration) {
      setTempCallDuration(seconds);
      setTimerPosition((prev) => prev + 1);
    }
  }, [currentTime]);

  /**
   * @description This fn() is used to send update call. If the /update call fails then it will try to send the call again.
   */
  async function handleSendUpdateCall() {
    let data = {
      id: progressId,
      increment: 30,
      position: updatePosition,
    };
    try {
      await updateSessionProgress(data)
        .then(() => {
          queryClient.refetchQueries({ queryKey: [USER_TODAY] });
        })
        .catch((e) => {
          throw e;
        });
    } catch (e) {
      await updateSessionProgress(data).then(() => {
        queryClient.refetchQueries({ queryKey: [USER_TODAY] });
      });
    } finally {
      setUpdatePosition((prev) => prev + 30);
    }
  }

  //   useEffect(()=>{
  //     const time = Math.ceil(currentTime||0);
  //     if(time !==prevTimeReference && time%30===0 &&time!==0){
  //     setPrevTimeReference(time);
  //   console.log("SEND API CALL");
  // }
  //     // console.log(time%30===0)
  //   },[currentTime]);

  // const {
  //   start,
  //   pause,
  //   resume,
  //   restart,
  //   stop,
  //   // totalSeconds
  // } = useTimer({
  //   expiryTimestamp, onExpire: () => {
  //     timeAt > totalDuration?.current ? setTimeAt(30) : setTimeAt(timeAt + 30);
  //     // A background restart Call so that it is not delayed by API call.
  //     setTimeout(() => {
  //       const restartedTime = new Date();
  //       restartedTime.setSeconds(restartedTime.getSeconds() + 29); // 30 seconds timer
  //       restart(restartedTime, true);
  //     }, 0);

  //     try {
  //       let data = {
  //         id: progressId,
  //         increment: 30,
  //         position: timeAt,
  //       };
  //       updateSessionProgress(data);
  //     } catch (e) {
  //       setTimeAt(prev => {
  //         let temp = prev - 30;
  //         if (temp > 0) {
  //           return temp
  //         }
  //         else return 0;
  //       });
  //       throw (e);
  //     }
  //   }, autoStart: false
  // });

  async function handleNewSoundPlay() {
    const data = {
      author: props.currentSound.author_id,
      item_id: props.currentSound.id,
      len: props.currentSound.duration * 60,
      title: props.currentSound.title,
      type: props.currentSound.type,
    };
    try {
      // stop();
      if (animationFrameRef.current) {
        clearInterval(animationFrameRef.current);
        animationFrameRef.current = null;
      }
      await startSessionProgress(data).then((res) => {
        setProgressId(res?.body?.progress?.id);
      });
    } catch (e) {
      await startSessionProgress(data).then((res) => {
        setProgressId(res?.body?.progress?.id);
      });
      throw e;
    }
  }
  useEffect(() => {
    handleNewSoundPlay();
  }, [props.currentSound.id]);

  async function playSoundWithTryCatch() {
    try {
      // @ts-ignore
      await audioRef.current?.play();
      // start();
      setMusicPlaying &&
        setMusicPlaying({
          type: MUSIC_STATES.IS_PLAYING,
          payload: { isPlaying: true },
        });
      setCurrentTime(0);
    } catch (e) {
      fullPlayerPlayerButtonRef.current?.click();
    }
  }
  useEffect(() => {
    // let newExpTime = new Date();
    // newExpTime.setSeconds(newExpTime.getSeconds() + 29); // 30 seconds timer
    // setExpiryTimestamp(newExpTime);
    setTimeAt(30);
    setUpdatePosition(30);
    if (!isLoadingMusic && progressId) {
      if (isMiniPlayer) setTimerPosition(0);
      // restart(newExpTime, true);
      if (musicType === "sleepSound") {
        // isPlayingRef.current = true;
        setisPlayingRef(true);
        updatePlaybackTime();
      } else {
        setCurrentTime(0);
        handelPlay();
      }
    } else setTimerPosition(0);
    // restart(newExpTime, false)
  }, [isLoadingMusic, progressId]);

  useEffect(() => {
    // if (isPlayingRef.current) {
    if (isPlayingRef) {
      // console.log("clear interval");

      if (animationFrameRef.current) clearInterval(animationFrameRef.current);
      // console.log("set interval");

      animationFrameRef.current = setInterval(updatePlaybackTime, 100);
    } else {
      if (animationFrameRef.current) {
        // console.log("clear interval");
        clearInterval(animationFrameRef.current);
      }
      try {
        audioContextRef.current?.suspend();
        source?.stop();
      } catch (e) {
        // console.log("ERROR IN STOPPING; SleepSOUND");
      }
    }
    return () => {
      if (animationFrameRef.current) {
        // console.log("CLEAR INTERVAL");
        clearInterval(animationFrameRef.current);
      }
    };
  }, [isPlayingRef]);
  const handleLoadedData = () => {
    setIsLoadingMusic(false);
  };

  const handleError = (error: Event) => {
    setIsLoadingMusic(false);
    console.error("Playback error:", error);
  };

  useEffect(() => {
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_PLAYING,
        payload: { isPlaying: true },
      });
  }, []);

  useEffect(() => {
    let sourceNode: AudioBufferSourceNode | null = null;
    if (audioContextRef.current) {
      source?.stop();
      source?.disconnect();
      audioContextRef.current.suspend();
      audioContextRef.current.close();
      audioContextRef.current = null;
      setTimerPosition(0);
      setUpdatePosition(30);
      // stop();
    }
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current.removeEventListener("loadeddata", handleLoadedData);
      audioRef.current.removeEventListener("error", handleError);
      audioRef.current = null;
      setTimerPosition(0);
      setUpdatePosition(30);
      // stop();
    }
    // console.log(currentSound.type)
    if (musicType === "sleepSound") {
      setCurrentTime(0);
      totalDuration.current = 0;

      const initializeAudioContext = async () => {
        const context = new AudioContext();
        audioContextRef.current = context;

        try {
          setIsLoadingMusic(true);
          const response = await fetch(music);
          const arrayBuffer = await response.arrayBuffer();
          const audioBuffer = await context.decodeAudioData(arrayBuffer);

          sourceNode = context.createBufferSource();
          sourceNode.buffer = audioBuffer;
          sourceNode.loop = true;
          sourceNode.connect(context.destination);

          setSource(sourceNode);
          totalDuration.current = audioBuffer.duration;

          sourceNode.start(0);
          // audioContextRef.current.resume();
          startTimeRef.current = context.currentTime;
          setIsLoadingMusic(false);
          // if(animationFrameRef.current)clearInterval(animationFrameRef.current)
          if (!isMiniPlayer) {
            handlePause();
            setMusicPlaying &&
              setMusicPlaying({
                type: MUSIC_STATES.IS_PLAYING,
                payload: { isPlaying: false },
              });
          }
          // if(!isMiniPlayer){setMusicPlaying}

          // animationFrameRef.current=setInterval(()=>updatePlaybackTime(),100)
        } catch (error) {
          console.error("Error initializing audio context:", error);
        }
      };

      initializeAudioContext();
    } else {
      const initializeAudio = (src: string) => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
          setTimerPosition(0);
          setUpdatePosition(30);
          // stop();
        }
        const audio = new Audio(src);
        audio.autoplay = !isMiniPlayer;
        audioRef.current = audio;
        setIsLoadingMusic(true);

        audio.addEventListener("loadeddata", () => {
          setIsLoadingMusic(false);
          totalDuration.current = audio.duration;
        });

        audio.addEventListener("error", (error) => {
          setIsLoadingMusic(false);
          console.error("Playback error:", error);
        });

        audio.addEventListener("ended", handleAudioEnded);
      };
      initializeAudio(music);
    }
    return () => {
      sourceNode?.stop();
      audioContextRef.current?.suspend();
      if (animationFrameRef.current) {
        // cancelAnimationFrame(animationFrameRef.current);
        // clearInterval(animationFrameRef.current);
        animationFrameRef.current = null;
      }

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("ended", handleAudioEnded);
        audioRef.current = null;
        setTimerPosition(0);
        setUpdatePosition(30);
        // stop();
      }
    };
  }, [music]);

  const handleAudioEnded = () => {
    setEnded(true);
    // setPrevTimeReference(0);
    setCurrentTime(0);
    // let newExpTime = new Date();
    // newExpTime.setSeconds(newExpTime.getSeconds() + 29); // 30 seconds timer
    // setExpiryTimestamp(newExpTime);
    setTimeAt(30);
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_PLAYING,
        payload: { isPlaying: false },
      });
    setTimerPosition(0);
    setUpdatePosition(30);
    // restart(newExpTime, musicType === 'sleepSound' ? true : false)
  };

  const handelPlay = async () => {
    if (musicType === "sleepSound") {
      // console.log("SLEEP SOUND PLAY");
      if (audioContextRef.current && source?.buffer) {
        // console.log("SLEEP SOUND PLAY2");
        source?.stop();
        audioContextRef.current.suspend();

        const sourceNode = audioContextRef.current.createBufferSource();
        sourceNode.buffer = source.buffer;
        sourceNode.loop = true;
        sourceNode.connect(audioContextRef.current.destination);
        sourceNode.start(0, elapsedTimeRef.current);
        audioContextRef.current.resume();
        // resume();

        setSource(sourceNode);
        startTimeRef.current = audioContextRef.current?.currentTime as number;
        setisPlayingRef(true);
        // isPlayingRef.current = true;
        // updatePlaybackTime();
        // debugger;
        // if(!animationFrameRef.current) {
        // if(animationFrameRef.current)clearInterval(animationFrameRef.current)
        // animationFrameRef.current=setInterval(()=>updatePlaybackTime(),100)
        //}
      }
    } else {
      if (ended) {
        setEnded((prev) => !prev);
        await handleNewSoundPlay().then(() => {
          audioRef.current?.play();
          // resume();
        });
      } else {
        try {
          await audioRef.current?.play();
          // resume();
        } catch (e) {
          setMusicPlaying &&
            setMusicPlaying({
              type: MUSIC_STATES.IS_PLAYING,
              payload: { isPlaying: !isPlayingLesson },
            });
        }
      }
    }
  };

  const handlePause = () => {
    if (musicType === "sleepSound") {
      source?.stop();
      audioContextRef.current?.suspend();
      const playedFor = (audioContextRef.current?.currentTime ?? 0) - (startTimeRef.current || 0);

      elapsedTimeRef.current = elapsedTimeRef.current + (playedFor || 0);

      startTimeRef.current = null;
      // if (animationFrameRef.current) {
      //   cancelAnimationFrame(animationFrameRef.current);
      // }
      // isPlayingRef.current = false;
      setisPlayingRef(false);
      // pause();
      // if(animationFrameRef.current){clearInterval(animationFrameRef.current); animationFrameRef.current=null}
    } else {
      audioRef.current?.pause();
      // if(animationFrameRef.current){clearInterval(animationFrameRef.current); animationFrameRef.current=null}
      // pause();
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousSoundRef.current) !== JSON.stringify(currentSound)) {
      previousSoundRef.current = currentSound;
      return;
    }
    // console.log(isPlayingLesson ? "play" : "pause");
    isPlayingLesson ? handelPlay() : handlePause();
    previousSoundRef.current = currentSound;
  }, [isPlayingLesson, currentSound]);

  const handleTimeUpdate = () => setCurrentTime(audioRef.current?.currentTime || 0);

  useEffect(() => {
    if ((musicType !== "sleepSession" && musicType !== "meditation") || !isPlayingLesson) return;

    const audioElement = audioRef.current;
    if (!audioElement) return;
    audioElement.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      audioElement.pause();
    };
  }, [isPlayingLesson, currentSound, musicType]);

  const togglePlay = () => {
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_PLAYING,
        payload: { isPlaying: !isPlayingLesson },
      });
  };

  const updatePlaybackTime = async () => {
    const { current: audioContext } = audioContextRef;
    const { current: startTime } = startTimeRef;

    if (!audioContext || startTime === null) return;

    const currentTimePassed = audioContext.currentTime - startTime;

    let updatedTime = elapsedTimeRef.current + currentTimePassed;

    if (updatedTime >= totalDuration.current) {
      elapsedTimeRef.current = 0;
      // REMOVED START CALL ON LOOP
      // await handleNewSoundPlay().then(() => {
      startTimeRef.current = audioContext.currentTime;
      // let newExpTime = new Date();
      // newExpTime.setSeconds(newExpTime.getSeconds() + 29); // 30 seconds timer
      // restart(newExpTime, true)
      // setExpiryTimestamp(newExpTime);
      setTimeAt(30);
      // setUpdatePosition(30);
      // setPrevTimeReference(0)
      // })

      // setStartAt(30);
    }

    setCurrentTime(updatedTime);

    // if (isPlayingRef.current) {
    //   // animationFrameRef.current = requestAnimationFrame(updatePlaybackTime);
    //   animationFrameRef.current = setInterval(updatePlaybackTime,100)
    // }
  };

  const handleShowBigModal = () => {
    // if (musicType === "sleepSound" || audioRef.current) {
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_MINI_PLAYER,
        payload: { isMiniPlayer: false },
      });
    navigate(`/player/${currentSound.id}`, { state: { fromInternal: true } });
    // }
  };

  const handleShowMiniModal = () => {
    // if (audioRef.current || musicType === "sleepSound") {
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_MINI_PLAYER,
        payload: { isMiniPlayer: true },
      });
    // navigate(-1);
    if (window.history.length > 2) navigate(-1);
    else {
      navigate("/");
    }
    // }
  };

  const handelClosePlayer = () => {
    // pause();
    if (musicType === "sleepSound") {
      source?.stop();
      audioContextRef.current?.suspend();
      setisPlayingRef(false);
      // isPlayingRef.current = false;
    } else {
      audioRef.current?.pause();
    }
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_PLAYING,
        payload: { isPlaying: false },
      });
    // stop();
    setTimerPosition(0);
    setUpdatePosition(30);
    if (animationFrameRef.current) {
      clearInterval(animationFrameRef.current);
      animationFrameRef.current = null;
    }
    handelClose();
  };

  const adjustAudioTime = (adjustment: number) => {
    if (isPlayingLesson) {
      const audioElement = audioRef.current;
      if (!audioElement) return;

      let newTime = audioElement.currentTime + adjustment;
      if (adjustment > 0) {
        // const restartedTime = new Date();
        // restartedTime.setSeconds(restartedTime.getSeconds() + 29); // 30 seconds timer
        // restart(restartedTime, true);
        // setUpdatePosition(30);
        newTime = Math.min(newTime, audioElement.duration);
      } else {
        newTime = Math.max(newTime, 0);
      }
      setTimerPosition(0);
      audioElement.currentTime = newTime;
    }
  };

  const resetAudioContext = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    audioContextRef.current = new AudioContext();
  };

  const handleReset = () => {
    setTimeAt(0);
    setTimerPosition(0);
    if (musicType !== "sleepSound") {
      const audioElement = audioRef.current;
      if (!audioElement) return;
      audioElement.pause();
      audioElement.currentTime = 0;
      setCurrentTime(0);
      setMusicPlaying &&
        setMusicPlaying({
          type: MUSIC_STATES.IS_PLAYING,
          payload: { isPlaying: false },
        });
    } else {
      source?.stop();
      audioContextRef.current?.suspend();
      setCurrentTime(0);
      elapsedTimeRef.current = 0;
      startTimeRef.current = 0;
      resetAudioContext();
      setMusicPlaying &&
        setMusicPlaying({
          type: MUSIC_STATES.IS_PLAYING,
          payload: { isPlaying: false },
        });
    }

    if (window.history.length > 2) navigate(-1);
    else {
      navigate("/");
    }
    handelClose();
  };

  const handleForward = () => {
    adjustAudioTime(10);
  };

  const handleBackward = () => {
    adjustAudioTime(-10);
  };

  useEffect(() => {
    if (!isMiniPlayer) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isMiniPlayer]);

  return (
    <>
      {/* {String(currentSound.type).toLowerCase() !== "sound" ? (
        <audio ref={audioRef} src={music} loop={false} autoPlay={true}>
          <source src={music} type="audio/mpeg" />
        </audio>
      ) : null} */}

      {isMiniPlayer ? (
        <MiniPlayer
          isLoadingMusic={isLoadingMusic}
          musicType={musicType}
          logo_image={logo_image}
          title={title}
          description={description}
          staff_status={staff_status}
          currentTime={currentTime}
          totalDuration={totalDuration}
          music={music}
          isPlayingLesson={isPlayingLesson}
          handelClose={handelClose}
          togglePlay={togglePlay}
          handleShowBigModal={handleShowBigModal}
          handelClosePlayer={handelClosePlayer}
        />
      ) : (
        <FullPlayer
          currentSound={currentSound}
          isLoadingMusic={isLoadingMusic}
          isPlayingLesson={isPlayingLesson}
          currentTime={currentTime}
          fullPlayerButtonRef={fullPlayerPlayerButtonRef}
          handleBackward={handleBackward}
          handleForward={handleForward}
          handleReset={handleReset}
          togglePlay={togglePlay}
          staff_status={staff_status}
          totalDuration={totalDuration}
          handleShowMiniModal={handleShowMiniModal}
          showMiniPlayerButton={true}
          musicType={musicType}
        />
      )}
    </>
  );
};

export default React.memo(MusicPlayer);
