import Container from "@components/Container";
import DetailLessonCard from "components/SleepToday/Lessons/DetailLessonCard";
import { FC } from "react";

interface LessonDetailProps {
  collapsed?: boolean;
}

const LessonDetail: FC<LessonDetailProps> = ({ collapsed }) => {
  return (
    <Container collapsed={collapsed}>
      <DetailLessonCard />
    </Container>
  );
};

export default LessonDetail;
