/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import "./App.css";
import { useUser } from "backend/hooks/useUser";
import { localGet, localPut } from "backend/storage";
import Footer from "components/AppHeaderFooter/Footer";
import Header from "components/AppHeaderFooter/Header";
import LeftMenu from "components/AppHeaderFooter/LeftMenu";
import ScrollToTop from "components/Utils/CustomHooks/ScrollToTop";
import useIsMobileScreen from "components/Utils/CustomHooks/useIsMobileScreen";
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import NotFound from "routes/NotFound";
import renderRoutes from "routes/routes";
import {
  ISwitchReducer,
  switchReducer,
  SWITCH_STATES,
} from "./reducers/switchReducer";
import {
  IMusicReducer,
  musicReducer,
  MUSIC_STATES,
} from "./reducers/musicReducer";
import { MusicPlayer } from "@components/SleepToday";
import useBackButton from "@backend/hooks/useBackButton";
import { Loading } from "@components/Utils/Loading";
import { useIsFetching } from "react-query";
import { scrollIntoView } from "@backend/scroller";

// ! Removed as per meeting of 27 Feb, 2024 and replacing it with old one commented below.
// export function isSleepPortal(): boolean {
//   let isSleepPortal = localGet("sleep-portal");
//   if (isSleepPortal === null) {
//     isSleepPortal = true; // default to true always
//     let searchParams = new URLSearchParams(window.location.search);
//     const sleepPortalQueryParam = searchParams.get("sleep-portal");
//     if (sleepPortalQueryParam !== null) {
//       if (sleepPortalQueryParam === "false") {
//         isSleepPortal = false;
//       } else if (sleepPortalQueryParam === "true") {
//         isSleepPortal = true;
//       }
//     }
//   }
//   return isSleepPortal;
// }

/**
 *
 * @description
 * This function return the sleep portal value based on localStorage.
 *
 * but this also sets the value based on queryParams to default set the portal to sleep home.
 */
export function isSleepPortal(): boolean {
  const searchParams = new URLSearchParams(window.location.search);
  // First check to see if the sleep-portal key exists in the query string
  // as this should override all other settings
  if (searchParams.get("sleep-portal") === "true") {
    localPut("sleep-portal", true);
    return true;
  }
  if (searchParams.get("sleep-portal") === "false") {
    localPut("sleep-portal", false);
    return false;
  }
  const sleepPortal = localGet("sleep-portal");

  if (sleepPortal !== null && sleepPortal === false) {
    localPut("sleep-portal", false);
    return false; // ONLY in this case will we return false that they are on the leadership portal
  }
  // Always default to true!
  localPut("sleep-portal", true);
  return true;
}

const App: FC = () => {
  // * To track that if the #id has been scrolled into view or not.
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useIsMobileScreen();
  const [collapsed, setCollapsed] = useState(isMobile);
  const toggleCollapse = () => setCollapsed(!collapsed);
  const { data: userData, isLoading } = useUser();
  // * This is used to keep track of API calls in progress.
  // * It is used in Scroll using hash # because we can't scroll when there is no div or data. So we will keep track of data using this if there is data then scroll into view.
  const isFetching = useIsFetching();

  const [searchParams] = useSearchParams();
  const hiddenParam = searchParams.get("hide");
  const location = useLocation();

  useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "auto";
    }
  }, []);

  // * Whenever the hash changes reset the scroll to false.
  useEffect(() => {
    if (isScrolled) {
      setIsScrolled(false);
    }
  }, [location.hash]);

  const mountRef = useRef<HTMLDivElement | null>(null);
  const [tries, setTries] = useState(0);
  const handleScrollOnMount = useCallback(
    /**
     * @description
     * This function will scroll to target based on # in link.
     *
     * First, it waits for the API calls to complete so that we know the page has been loaded then scroll to element if it exists.
     */
    () => {
      if (isFetching === 0) {
        // * Take out hash from location / address bar.
        let hash = location.hash;
        // * If there is hash?
        if (hash) {
          // * Scroll to that target after 1 sec delay.
          setTimeout(() => {
            // * check reference to the tag to where we want to scroll.
            let element = document.getElementById(hash.substring(1));
            if (!element && tries <= 5)
              setTimeout(() => {
                setTries((prev) => prev + 1);
                handleScrollOnMount();
              }, 700);
            // * If the element is found && it didn't scroll previously then scroll else don't scroll. (Basically refetching will also trigger this so we will check that condition too.)
            if (!isScrolled) {
            let isDaytimePage = location.pathname.includes("daytime-support");
              scrollIntoView(element, false, isDaytimePage);
              // * we have scrolled to the element.
              setIsScrolled(true);
            }
          }, 1000);
        }
      }
  }, [isFetching, location.hash, isScrolled]);

  // * useEffect to check hashes and scroll to that target.
  useEffect(() => {
    // * If no content is fetching or fetch has been done. so that means our content would have loaded on first visit or refresh.
    if (mountRef.current) handleScrollOnMount();
  }, [mountRef]);

  const logIsSleepPortal = (from: any) => {
    const sleepPortal = isSleepPortal();
    // console.log(
    //   `"*********** isSleepPortal: ${sleepPortal} *********** from: ${from} ***********`,
    // );
    return sleepPortal;
  };

  const initialSwitchState: ISwitchReducer = {
    switchToSleepToday: logIsSleepPortal("initialSwitchState"),
  };

  const initialMusicState: IMusicReducer = {
    showPlayer: false,
    musicType: "",
    currentSound: null,
    isPlaying: false,
    isMiniPlayer: true,
  };

  const [switchState, dispatch] = useReducer(switchReducer, initialSwitchState);
  const [musicState, dispatchMucicState] = useReducer(
    musicReducer,
    initialMusicState,
  );

  const stopMusic = () => {
    dispatchMucicState({ type: MUSIC_STATES.CLEAR_MUSIC });
  };

  useLayoutEffect(() => {
    const switchValue = logIsSleepPortal("switchValue");
    dispatch({
      type: switchValue
        ? SWITCH_STATES.SWITCH_TO_SLEEP
        : SWITCH_STATES.SWITCH_TO_LEADERSHIP,
    });
  }, []);

  const routeList = useMemo(
    () => renderRoutes({ ...switchState, user: userData.body, location }),
    [switchState, userData],
  );

  let classConfig: string;

  if (collapsed) {
    const mobileClass = "ml-0 header-width-collapsed";
    const otherClass = "sm:ml-16 sm:header-width-collapsed";
    classConfig = isMobile ? mobileClass : otherClass;
  } else {
    const mobileClass = "header-width-full";
    const otherClass = "lg:ml-64 md:header-width-full";
    classConfig = isMobile ? mobileClass : otherClass;
  }

  const classConfigs = {
    main: classConfig,
  };

  const [isBackButtonPressed, resetBackButton] = useBackButton();

  useEffect(() => {
    if (isBackButtonPressed && !musicState.isMiniPlayer) {
      dispatchMucicState({
        type: MUSIC_STATES.IS_MINI_PLAYER,
        payload: { isMiniPlayer: true },
      });
    }
    resetBackButton();
  }, [isBackButtonPressed, resetBackButton]);
  const musicType =
    musicState.musicType === "sound"
      ? "sleepSound"
      : musicState.musicType === "session"
        ? "sleepSession"
        : musicState.musicType === "meditation"
          ? "meditation"
          : musicState.musicType;

  return (
    <>
      <div
        ref={mountRef}
        className={`custom-height flex ${location.pathname === "/daytime-support" ? "bg-white " : "bg-darkGray"}`}
      >
        {hiddenParam !== "true" && (
          <>
            <LeftMenu
              collapsed={collapsed}
              setMusicPlaying={dispatchMucicState}
              toggleCollapse={toggleCollapse}
              items={routeList.filter((item) => item.label)}
              state={switchState}
              isMobile={isMobile}
            />
            <Header
              collapsed={collapsed}
              toggleCollapse={toggleCollapse}
              dispatch={dispatch}
              state={switchState}
              isMobile={isMobile}
              dispatchMucicState={dispatchMucicState}
            />
          </>
        )}
        {musicState.showPlayer && (
          <div
            className={`fixed ${location.pathname.includes("player") ? "z-18" : "z-20"} ${musicState.isMiniPlayer ? "bottom-[5px]" : ""}  z-2 flex justify-center ${
              collapsed && !isMobile
                ? "header-width-collapsed ml-16"
                : !isMobile && hiddenParam !== "true"
                  ? "header-width-full ml-64"
                  : "w-full"
            }  ${isMobile ? "ml-0 w-full" : ""}`}
          >
            <MusicPlayer
              isPlayingLesson={musicState.isPlaying}
              setMusicPlaying={dispatchMucicState}
              currentSound={musicState.currentSound}
              isMiniPlayer={musicState.isMiniPlayer}
              // musicType={musicState.musicType}
              musicType={musicType}
              handelClose={() => {
                stopMusic();
              }}
            />
          </div>
        )}

        <main
          className={`flex-grow px-4 ${hiddenParam === "true" ? "" : "pt-16"} ${hiddenParam !== "true" && classConfigs.main}  `}
        >
          <ScrollToTop />
          {!isLoading ? (
            <Routes>
              {routeList.map((item, index) => {
                const Component = item.component;
                return (
                  <Route
                    path={item.path}
                    element={
                      <Component
                        {...item.props}
                        collapsed={collapsed}
                        setMusic={dispatchMucicState}
                        musicState={musicState}
                      />
                    }
                    key={index}
                  />
                );
              })}
              <Route path="*" element={<NotFound />} />
            </Routes>
          ) : (
            <Loading />
          )}
        </main>
      </div>
      {hiddenParam !== "true" && (
        <Footer collapsed={collapsed} isMobile={isMobile} />
      )}
    </>
  );
};

export default App;
