import React, { useState, useRef, FC, ReactNode, useEffect } from "react";
import ProgressBar from "./ProgressBar";
// import { ChallengeData } from "types/challengeType";
import ChallengeTask from "./ChallengeTask";
import { ChallengesType, Step } from "@pages/Sleep/SleepTodayPage";
import InfiniteChallengeHeader from "./InfiniteChallengeHeader";
import { anaEvent } from "@backend/ana";

interface IChallengeAccordionProps {
  title: string;
  children?: ReactNode;
  completedPercentage: number;
  // challenge: ChallengeData;
  challenge: ChallengesType;
  onScrollClick?: (target: string) => void;
  pageType?: string;
}

const ChallengeAccordion: FC<IChallengeAccordionProps> = ({ title, completedPercentage, challenge, onScrollClick, pageType }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement | null | any>(null);

  const [firstItem, setFirstItem] = useState<Step | null>();

  const [nextItem, setNextItem] = useState<Step | null>();

  // const [height, setHeight] = useState<string | number>('0');

  // const [initialHeight, setInitialHeight] = useState<any>();

  const handleIsOpen = () => {
    // * Tracking previous state.
    let prev = isOpen;
    // * If it is infinite challenge then=>
    if (challenge.type === "infinite") {
      // * if its open that means its gonna close now.
      if (prev) {
        anaEvent("challenge_hide", {
          name: challenge.name,
          status: challenge.status,
          type: challenge.type,
          points: challenge.points,
          points_daily: challenge.points_daily,
        });
      } else {
        // * Its gonna open.
        anaEvent("challenge_show", {
          name: challenge.name,
          status: challenge.status,
          type: challenge.type,
          points: challenge.points,
          points_daily: challenge.points_daily,
        });
      }
    }
    setIsOpen(!prev);
  };
  useEffect(() => {
    // * For Collapsed state: Challenges are like 1 completed and 1 pending.
    // * -----------------------------------------------------------
    // * First Step / element of Challenges. (Completed)
    let first = challenge.steps?.find((item) => item.status === "complete");
    // * Array of Challenges that are pending / not completed.
    let second = challenge.steps?.filter((item) => item.status === "active");
    // * set first challenge
    setFirstItem(first);
    // setInitialHeight(contentRef.current.scrollHeight);

    // * if we find some completed challenge, infinite challenges are also tracked in this condition.
    if (first) setNextItem(second ? second[0] : null);
    else {
      // * if there is no completed item then search in array of in-complete challenges.
      // * And set first item from array of in complete challenges
      if (second && second.length > 0) setFirstItem(second[0]);
      // * Set second item in challenge
      if (second && second?.length > 1) setNextItem(second[1]);
    }
    // setInitialHeight(contentRef.current.scrollHeight);
  }, [firstItem, nextItem, challenge, completedPercentage]);

  // * For Challenge Click to track tap event
  function handleChallengeClick(step: Step) {
    if (challenge.type === "infinite") {
      anaEvent("challenge_step_tap", {
        name: challenge.name,
        status: challenge.status,
        type: challenge.type,
        points: challenge.points,
        points_daily: challenge.points_daily,
        step_name: step.name,
        step_status: step.status,
        step_type: step.type,
        step_points: step.points,
        step_points_daily: step.points_daily,
        step_progress: step.progress,
        step_progress_total: step.progress_total,
      });
    }
  }
  // useEffect(() => {
  //   setHeight(isOpen && contentRef.current ? contentRef.current.scrollHeight : `${initialHeight}px`);
  // }, [isOpen, initialHeight, completedPercentage]);

  return (
    <div className="w-full rounded-lg border-2 border-darkPurpleC2/80 bg-darkPurpleC2/10 px-3">
      <div className="flex cursor-pointer flex-col items-start justify-between rounded-md py-2 pr-4" onClick={handleIsOpen} data-testid="accordion-button">
        <div className="flex w-full items-center">
          <div className="w-full px-2 pt-2">
            <p className="md:text-md xs:text-xs flex items-center font-thin text-white sm:text-sm lg:text-lg">{title}</p>
          </div>
        </div>
        {challenge.type === "finite" ? <ProgressBar percentage={completedPercentage} /> : <InfiniteChallengeHeader points={challenge.points} dailyPoints={challenge.points_daily} />}
      </div>
      <div
        ref={contentRef}
        className="hide-scrollbar duration-400 overflow-auto transition-all ease-in-out"
        // style={{ height: isOpen ? `${height}px` : `${initialHeight}px` }}
      >
        <div className="px-2">
          <>
            {!isOpen && firstItem && (
              <ChallengeTask
                type={firstItem.type}
                isOpen={isOpen}
                title={firstItem.title}
                description={firstItem.description}
                completedDate={firstItem.completed_on}
                isChecked={firstItem.status === "complete"}
                points={firstItem.points_daily}
                moveTo={firstItem.go_to_anchor}
                // onScrollClick={onScrollClick}
                onClick={() => handleChallengeClick(firstItem)}
                pageType={pageType}
                icon={firstItem.icon}
              />
            )}
            {!isOpen && nextItem && (
              <ChallengeTask
                type={nextItem.type}
                isOpen={isOpen}
                onClick={() => handleChallengeClick(nextItem)}
                points={nextItem.points_daily}
                title={nextItem.title}
                description={nextItem.description}
                completedDate={nextItem.completed_on}
                isChecked={nextItem.status === "complete"}
                moveTo={nextItem.go_to_anchor}
                // onScrollClick={onScrollClick}
                pageType={pageType}
                icon={nextItem.icon}
              />
            )}
            {isOpen &&
              challenge &&
              challenge.steps.map((item, index) => (
                <ChallengeTask
                  type={item.type}
                  isOpen={isOpen}
                  key={index}
                  points={item.points_daily}
                  title={item.title}
                  onClick={() => handleChallengeClick(item)}
                  description={item.description}
                  completedDate={item.completed_on}
                  isChecked={item.status === "complete"}
                  moveTo={item.go_to_anchor}
                  // onScrollClick={onScrollClick}
                  pageType={pageType}
                  icon={item.icon}
                />
              ))}
          </>
        </div>
      </div>
      <div className="flex cursor-pointer flex-col items-center p-2" onClick={handleIsOpen}>
        <p className="text-xs font-medium uppercase text-purpleC1">{isOpen ? "SHOW LESS" : "Show All"}</p>
      </div>
    </div>
  );
};

// function ChallengesContainer(props: IChallengeAccordionProps): any {
//   if (props.challenge.type === "finite") return <ChallengeAccordion {...props} />;
// }
export default ChallengeAccordion;
