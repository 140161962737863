import { useRef, useEffect } from "react";

const useScrollToBottom = (...dependency: any) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [dependency]);

  return messagesEndRef;
};

export default useScrollToBottom;
