import React, { ReactElement, ReactNode } from "react";

interface CardButtonProps {
  Icon: ReactElement;
  handleClick: () => void;
  text: ReactNode;
}

const CardButton: React.FC<CardButtonProps> = ({ Icon, handleClick, text }) => {
  return (
    <div className="bg-darkGray2 p-4 rounded-lg shadow mb-2 max-w-[530px] w-full">
      <div className="flex items-center">
        {React.cloneElement(Icon, {
          className: "cursor-pointer text-2xl",
          onClick: handleClick,
        })}
        <p className="ml-3 cursor-pointer" onClick={handleClick}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default CardButton;
