import { MORE_INFO } from "@assets/staticData";
import { anaScreen } from "@backend/ana";
import SectionHeading from "@components/SectionHeading";
import { useSleepHistory } from "backend/hooks/useUser";
import CaptionTitle from "components/CaptionTitle";
import Container from "components/Container";
import GobackButton from "components/GobackButton";

import SleepHistoryChartCard from "components/SleepToday/SleepHistory/SleepHistoryChartCard";
import SleepHistoryEditLogs from "components/SleepToday/SleepHistory/SleepHistoryEditLogs";
import { LoadingPage } from "components/Utils/Loading";
import { format, subDays } from "date-fns";
import { keyBy } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface SleepHistoryPageProps {
  collapsed?: boolean;
}

const SleepHistoryPage: React.FC<SleepHistoryPageProps> = ({ collapsed }) => {
  const { isLoading, data } = useSleepHistory();

  const location = useLocation();

  const fromSidebar = location?.state?.fromSidebar;

  const navigate = useNavigate();

  const logs = data?.body?.logs;

  const sleepScore = data?.body?.score;
  const wellBeing = data?.body?.wellbeing;
  const timeAsleep = data?.body?.time_asleep;

  const processData = (data: any) => {
    if (!data) {
      return [];
    }

    const today = new Date();
    const dateKeyBy = keyBy(data, "day");

    return Array.from({ length: 30 }, (_, i) => {
      const day = format(subDays(today, i), "yyyy-MM-dd");
      return dateKeyBy[day] || { day };
    });
  };

  useEffect(() => {
    anaScreen("sleep_history", null);
  }, []);

  const processedData = useMemo(() => processData(logs), [logs]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const handleMoreInfo = () => {
    navigate(MORE_INFO.SLEEP_TRACKER.path, {
      state: { url: MORE_INFO.SLEEP_TRACKER.url, from: "Sleep History" },
    });
  };

  return (
    <Container collapsed={collapsed}>
      {!fromSidebar && <GobackButton />}

      <SectionHeading
        heading="Sleep Tracker"
        contentUrl={MORE_INFO.SLEEP_TRACKER}
        from="Sleep History"
      />
      <p className="text-base sm:text-xl mt-2 mb-2">
        Chorus works great with wearable watches and rings. Tap{" "}
        <span className="underline cursor-pointer" onClick={handleMoreInfo}>
          here
        </span>{" "}
        to learn more.
      </p>
      <SleepHistoryChartCard
        title1="Sleep Score"
        data={sleepScore}
        yAxisUnit="%"
      />
      <SleepHistoryChartCard
        title1="Wellbeing"
        title2="Based on your Evening & Nap Journal"
        data={wellBeing}
        yAxisUnit="%"
      />
      <SleepHistoryChartCard
        title1="Time Asleep"
        data={timeAsleep}
        graphType="hourGraph"
        min={0}
        max={16}
        yAxisUnit="h"
      />

      <div className="flex justify-center mb-3">
        <CaptionTitle
          className="text-opacity-70 font-light text-white text-center"
          title="You may edit the last 30 days of journals below"
        />
      </div>

      {processedData.map((item: any, index: number) => {
        return <SleepHistoryEditLogs data={item} key={index} />;
      })}
    </Container>
  );
};

export default SleepHistoryPage;
