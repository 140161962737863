import React, { FC, ReactElement } from "react";
import {
  BsFillEmojiDizzyFill,
  BsFillEmojiFrownFill,
  BsFillEmojiLaughingFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiSmileFill,
} from "react-icons/bs";
import { GoQuestion } from "react-icons/go";

interface IconList {
  [index: string]: ReactElement;
}

const iconList: IconList = {
  dontKnow: <GoQuestion className="text-3xl " />,
  veryPoor: <BsFillEmojiDizzyFill className="text-3xl " />,
  poor: <BsFillEmojiFrownFill className="text-3xl " />,
  fair: <BsFillEmojiNeutralFill className="text-3xl " />,
  good: <BsFillEmojiSmileFill className="text-3xl " />,
  veryGood: <BsFillEmojiLaughingFill className="text-3xl " />,
};

interface IJournalEmoji {
  icon: keyof typeof iconList;
  title: string;
  selected: string;
  onClick: (props: object) => void;
  apiValue: string;
}

const JournalEmoji: FC<IJournalEmoji> = (props) => {
  const { icon, title, selected, onClick, apiValue } = props;

  const baseIcon = iconList[icon];

  // Clone the icon with additional tailwind classes depending on whether the emoji is selected.
  const StyledIcon = React.cloneElement(baseIcon, {
    className: `${baseIcon.props.className} ${selected === title ? "text-journal-orange" : "text-lightGray1"}`,
  });
  return (
    <div
      className="flex-1 flex flex-col items-center justify-center cursor-pointer"
      onClick={() => {
        onClick(props);
      }}
      data-testid={apiValue}
    >
      {StyledIcon}

      <p
        className={`text-xs text-center mt-1 h-4 ${selected === title ? "text-journal-orange" : "text-lightGray1"}  `}
      >
        {selected === title && title}
      </p>
    </div>
  );
};

export default JournalEmoji;
