import React, { FC, Fragment } from "react";

interface IDataField {
  label: string;
  value: string;
}

interface IReadOnlyFieldsCard {
  fieldsArr: Array<IDataField>;
  label: string;
  color: string;
  icon: JSX.Element;
}

const ReadOnlyFieldsCard: FC<IReadOnlyFieldsCard> = ({
  fieldsArr,
  label,
  color,
  icon,
}) => {
  return (
    <>
      <Fragment>
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div className={`flex items-center`}>
              {icon}
              <p className={`text-base sm:text-xl ml-2 ${color}`}>{label}</p>
            </div>
          </div>
          <div className="bg-darkGray2 p-4 rounded-md mt-2 ">
            {fieldsArr.map((field) => (
              <>
                <div className="flex justify-between items-center">
                  <div>
                    <p className="mt-3">{field.label}</p>
                    <p className="text-base sm:text-2xl font-bold">
                      {field.value}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ReadOnlyFieldsCard;
