import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React, { FC } from "react";

interface CustomToastProps {
  title: string;
  handleClick: () => void;
}

const CustomToast: FC<CustomToastProps> = ({ title, handleClick }) => {
  return (
    <div
      className={`text-white text-opacity-70 min-w-[140px]  font-light text-xs sm:text-sm px-3 sm:px-4 py-2 rounded-xl border shadow-2xl bg-lightGray9 mb-8 flex items-center justify-between border border-red-400`}
    >
      <div className="flex items-center gap-5">
        <ExclamationCircleOutlined style={{ color: "red", fontSize: "16px" }} />
        <span>
          <b>Error: </b>
          {title}
        </span>
      </div>
      <CloseOutlined onClick={handleClick} />
    </div>
  );
};

export default CustomToast;
