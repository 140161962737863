import { Emoji } from "components/SleepToday/Journal/JournalEmojiCard";

export const morningEmojiList: Emoji[] = [
  {
    icon: "veryPoor",
    title: "Very Poor",
    apiValue: "very_poor",
  },
  {
    icon: "poor",
    title: "Poor",
    apiValue: "poor",
  },
  {
    icon: "fair",
    title: "Fair",
    apiValue: "fair",
  },
  {
    icon: "good",
    title: "Good",
    apiValue: "good",
  },
  {
    icon: "veryGood",
    title: "Very Good",
    apiValue: "very_good",
  },
];
