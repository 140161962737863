/* eslint-disable react-hooks/exhaustive-deps */
import { BiBed } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import {
  convertTo24HourFormat,
  findEmoji,
  formatDate,
  formatTime,
} from "components/Utils/Functions";
import {
  useModifyUserLogs,
  useSleepHistory,
  useToday,
  useUserLogs,
} from "@backend/hooks/useUser";

import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton from "@components/CustomButton";

import CaptionTitle from "components/CaptionTitle";
import Container from "components/Container";
import GobackButton from "components/GobackButton";

import { morningEmojiList } from "components/SleepToday/Journal/MorningJournalConstant";
import JournalEmojiCard from "@components/SleepToday/Journal/JournalEmojiCard";
import JournalTextInput from "@components/SleepToday/Journal/JournalTextInput";
import { LoadingPage } from "@components/Utils/Loading";

import JournalEntry from "components/SleepToday/Journal/JournalEntry";
import CustomToast from "@components/Toast";
import { anaScreen } from "@backend/ana";

interface MorningJournalPageProps {
  collapsed?: boolean;
}

const MorningJournalPage: React.FC<MorningJournalPageProps> = ({
  collapsed,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const stateDate = location.state?.journalDate;

  const apiDate = stateDate
    ? format(stateDate, "yyyy-MM-dd")
    : format(new Date(), "yyyy-MM-dd");

  const { data, isLoading, isError, refetch } = useUserLogs(apiDate);

  const { refetch: refetchUseToday } = useToday();

  const { refetch: historyRefetch } = useSleepHistory();

  const [bedTime, setBedTime] = useState("");
  const [sleepTime, setSleepTime] = useState("");
  const [wakeUpTime, setWakeUpTime] = useState("");
  const [outOfBedTime, setOutOfBedTime] = useState("");
  const [timeAwake, setTimeAwake] = useState<number | string>(0);
  const [numberOfAwaking, setNumberOfAwaking] = useState<number | string>(0);
  const [error, setError] = useState<string>("");

  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  const [selectedEmoji, setSelectedEmoji] = useState<string>("");

  const [journalText, setJournalText] = useState<string>("");

  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const handelJournalTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setJournalText(e.target.value);
  };

  useEffect(() => {
    anaScreen("morning_journal", null);
  }, []);

  const userModifyLogMutation = useModifyUserLogs(refetch);

  const setDataToState = (data: any) => {
    setFormattedDate(formatDate(data.body.day));
    setBedTime(formatTime(data.body.in_bed_hour, data.body.in_bed_min));

    setSleepTime(formatTime(data.body.asleep_hour, data.body.asleep_min));
    setWakeUpTime(formatTime(data.body.wake_up_hour, data.body.wake_up_min));
    setOutOfBedTime(
      formatTime(data.body.out_of_bed_hour, data.body.out_of_bed_min),
    );

    setTimeAwake(data.body.wake_up_duration);
    setNumberOfAwaking(data.body.wake_up_times);
    setJournalText(data.body.journal_sleep);

    let emojiExist = findEmoji(
      morningEmojiList,
      data.body.sleep_quality_choice,
    );
    if (emojiExist) {
      setSelectedEmoji(emojiExist);
    }
  };

  useEffect(() => {
    if (data?.body) {
      setDataToState(data);
    }
  }, [data]);

  const handleClick = () => {
    setError("");
  };

  const handleSetError = () => {
    setError(userModifyLogMutation.data.error.title);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handelSave = () => {
    handleClick();

    const { hours: in_bed_hour, minutes: in_bed_min } =
      convertTo24HourFormat(bedTime);
    const { hours: asleep_hour, minutes: asleep_min } =
      convertTo24HourFormat(sleepTime);
    const { hours: wake_up_hour, minutes: wake_up_min } =
      convertTo24HourFormat(wakeUpTime);
    const { hours: out_of_bed_hour, minutes: out_of_bed_min } =
      convertTo24HourFormat(outOfBedTime);

    let emojiExist = morningEmojiList.find(
      (item) => item.title === selectedEmoji,
    );

    let dataObj = {
      day: data.body.day,
      in_bed_hour,
      in_bed_min,
      asleep_hour,
      asleep_min,
      wake_up_hour,
      wake_up_min,
      out_of_bed_hour,
      out_of_bed_min,
      wake_up_times: numberOfAwaking,
      wake_up_duration: timeAwake,
      sleep_quality_choice: emojiExist ? emojiExist.apiValue : "",
      journal_sleep: journalText,
    };
    userModifyLogMutation.mutate(dataObj);

    setIsSaveClicked(true);
  };

  useEffect(() => {
    if (
      !userModifyLogMutation.isLoading &&
      userModifyLogMutation.isError === false &&
      isSaveClicked &&
      !userModifyLogMutation.data?.error
    ) {
      refetchUseToday();
      historyRefetch();
      navigate(-1);
    }
  }, [
    userModifyLogMutation.isLoading,
    userModifyLogMutation.isError,
    navigate,
    isSaveClicked,
  ]);

  useEffect(() => {
    if (userModifyLogMutation.data?.error?.title) {
      handleSetError();
    }
  }, [userModifyLogMutation.data]);

  if (isLoading || userModifyLogMutation.isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return (
      <div className="flex w-full justify-center">
        <div className="mt-5 flex w-full justify-center sm:w-full md:w-[640px] lg:w-[768px] ">
          <p className="mt-8 text-2xl text-white">Invalid Day...</p>
        </div>
      </div>
    );
  }

  return (
    <Container collapsed={collapsed}>
      <GobackButton />

      {error && <CustomToast title={error} handleClick={handleClick} />}
      <JournalEntry
        cardTitle="In Bed"
        firstRowButtonTitle="Edit Time In Bed"
        secondRowButtonTitle="Edit Time Asleep"
        value1={bedTime}
        value2={sleepTime}
        defaultValue1="11:00 PM"
        defaultValue2="11:15 PM"
        setValue1={(value: string) => {
          setBedTime(value);
        }}
        setValue2={(value: string) => {
          setSleepTime(value);
        }}
        firstTimerId="bedTime"
        secondTimerId="sleepTime"
        textColor="text-journal-orange"
        bgColor="bg-darkGray"
        firstRowTitle="In Bed"
        secondRowTitle="Time Asleep"
        date={formattedDate}
        icon={<BiBed className="text-2xl" />}
      />
      <JournalEntry
        cardTitle="Awake"
        firstRowTitle="Number of Awakenings"
        secondRowTitle="Time Awake (minutes)"
        numberOfAwaking={numberOfAwaking}
        timeAwake={timeAwake}
        bgColor="bg-darkGray"
        textColor="text-journal-red"
        handelNumberOfAwaking={(value) => setNumberOfAwaking(value)}
        handelTimeAwake={(value) => setTimeAwake(value)}
        icon={<BsEyeFill className="text-2xl" />}
      />

      <JournalEntry
        cardTitle="Wake Up"
        firstRowButtonTitle="Edit Wake Up"
        secondRowButtonTitle="Edit Out Of Bed"
        value1={wakeUpTime}
        value2={outOfBedTime}
        defaultValue1="07:00 AM"
        defaultValue2="07:15 AM"
        setValue1={(value: string) => {
          setWakeUpTime(value);
        }}
        setValue2={(value: string) => {
          setOutOfBedTime(value);
        }}
        firstTimerId="wakeUpTime"
        secondTimerId="outOfBedTime"
        textColor="text-journal-green"
        bgColor="bg-darkGray"
        firstRowTitle="Wake Up Time"
        secondRowTitle="Got Out Of Bed"
        icon={<BiBed className="text-2xl" />}
      />

      <div className="mb-1">
        <CaptionTitle title="Overall, how would you estimate your sleep quality was last night?" />
      </div>
      <JournalEmojiCard
        emojiList={morningEmojiList}
        selectedEmoji={selectedEmoji}
        setSelectedEmoji={setSelectedEmoji}
      />
      <JournalTextInput
        onChange={handelJournalTextChange}
        value={journalText}
      />
      <div className="flex justify-center">
        <CustomButton className="w-[200px]" onClick={handelSave}>
          Save Changes
        </CustomButton>
      </div>
    </Container>
  );
};

export default MorningJournalPage;
