import { localGet } from "backend/storage";
import CaptionTitle from "components/CaptionTitle";
import Container from "components/Container";
import GobackButton from "components/GobackButton";
import ReadOnlyFieldsCard from "components/ReadOnlyFieldsCard";
import {
  journalDetailCardsData,
  progressEmojiList,
  sleepPlayEmojiList,
  sleepProgressEmojiList,
} from "components/SleepToday/Journal/EveningJournalConstant";
import { findEmoji } from "components/Utils/Functions";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import JournalEmojiCard from "@components/SleepToday/Journal/JournalEmojiCard";

interface JournalViewPageProps {
  collapsed?: boolean;
}

const JournalViewPage: FC<JournalViewPageProps> = ({ collapsed }) => {
  const [selectedProgressEmoji, setSelectedProgressEmoji] =
    useState<string>("");
  const [selectedSleepProgressEmoji, setSelectedSleepProgressEmoji] =
    useState<string>("");
  const [selectedPlayEmoji, setSelectedPlayEmoji] = useState<string>("");
  const location = useLocation();

  const stateObj = location?.state?.journalObj || localGet("JournalData");

  const formatJournalObj = (data: any) => {
    return {
      progressEmoji: findEmoji(progressEmojiList, data.progress_choice),
      sleepQualityEmoji: findEmoji(
        sleepProgressEmojiList,
        data.sleep_quality_choice,
      ),
      playEmoji: findEmoji(sleepPlayEmojiList, data.irritable_choice),
    };
  };

  useEffect(() => {
    if (stateObj) {
      const formattedData = formatJournalObj(stateObj);
      setSelectedSleepProgressEmoji(formattedData.sleepQualityEmoji || "");
      setSelectedProgressEmoji(formattedData.progressEmoji || "");
      setSelectedPlayEmoji(formattedData.playEmoji || "");
    }
  }, [stateObj]);

  const journalDetailCardsArr = journalDetailCardsData(stateObj);

  return (
    <Container collapsed={collapsed}>
      <GobackButton />
      {journalDetailCardsArr.map((cardObj) => (
        <ReadOnlyFieldsCard {...cardObj} />
      ))}
      <div className="mt-9 mb-2">
        <CaptionTitle title="Overall, How would you estimate your sleep quality was last night?" />
      </div>
      <JournalEmojiCard
        readonly={true}
        minHeight="min-h-[115px]"
        emojiList={sleepProgressEmojiList}
        selectedEmoji={selectedSleepProgressEmoji}
        setSelectedEmoji={() => {}}
      />

      <p className="text-2xl mt-9 mb-2">
        How well did you progress through your day?
      </p>
      <JournalEmojiCard
        readonly={true}
        minHeight="min-h-[115px]"
        emojiList={progressEmojiList}
        selectedEmoji={selectedProgressEmoji}
        setSelectedEmoji={() => {}}
      />

      <p className="text-2xl mt-9">How irritable did you feel today?</p>
      <JournalEmojiCard
        readonly={true}
        minHeight="min-h-[115px]"
        emojiList={sleepPlayEmojiList}
        selectedEmoji={selectedPlayEmoji}
        setSelectedEmoji={() => {}}
      />
    </Container>
  );
};

export default JournalViewPage;
