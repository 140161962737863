import { MORE_INFO } from "@assets/staticData";
import SectionHeading from "@components/SectionHeading";
import { TOAST_TYPE } from "@components/Utils/constants/enum";
import { SLIDER_CONSTANT } from "@components/Utils/constants/ScrollConstant";
import Toast from "@components/Utils/Toast";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";
import { has } from "lodash";
import { FC, Fragment, useEffect, useState } from "react";
import SleepSoundCard from "../SleepSoundCard";
import Slider from "../Slider";

interface ICurrentSleepSessionSlider extends ISleepSessionSlider {
  currentId: number;
  type?:any
}

export interface ISleepSessionSlider {
  background_image: string;
  content_link?: string;
  title: string;
  description?: string;
  id: number;
  testId?: string;
  author_id?: string;
  setCurrentSound: (sound: ICurrentSleepSessionSlider | null) => void;
  data: any;
  isPlaying: boolean;
}

interface ISleepSessionSliderProps {
  data?: ISleepSessionSlider[];
  collapsed?: boolean;
  setMusic?: (action: IMusicAction) => void;
  musicState: any;
  targetID?: string;
}

const SleepSessionSlider: FC<ISleepSessionSliderProps> = ({
  data,
  setMusic,
  musicState,
  targetID = "",
}) => {
  const [isPlaying, setIsPlaying] = useState<{ [key: number]: boolean }>({});
  const [toastMsg, setToastMsg] = useState("");

  const handelShowSmallModal = (data: ICurrentSleepSessionSlider | null) => {
    if (!data) {
      setMusic &&
        setMusic({
          type: MUSIC_STATES.IS_PLAYING,
          payload: { isPlaying: false },
        });
    } else {
      setIsPlaying({});
      setMusic &&
        setMusic({
          type: MUSIC_STATES.SET_MUSIC,
          payload: {
            currentSound: data,
            showPlayer: true,
            musicType: data?.type ? data.type : '' ,//|| "sleepSession",
            isPlaying: true,
          },
        });
    }
  };

  useEffect(() => {
    if (
      musicState.currentSound === null ||
      !musicState.isPlaying ||
      !has(musicState.currentSound, "currentId")
    ) {
      setIsPlaying({});
    } else {
      setIsPlaying((prev) => ({
        ...prev,
        [musicState.currentSound.currentId]: true,
      }));
    }
  }, [musicState]);

  return (
    <Fragment>
      {toastMsg && (
        <Toast
          message={toastMsg}
          close={() => {
            setToastMsg("");
            setIsPlaying({});
          }}
          toastType={TOAST_TYPE.ERROR}
        />
      )}
      <SectionHeading
        heading="Sleep"
        contentUrl={MORE_INFO.SLEEP_URL}
        from="Sleep Home"
        id="sleep"
      />
      {/*
       * Added id="session" to whole div instead of heading.
       * The whole div contains: the heading and the slider content so that it makes sessions.
       */}
      <div className="flex flex-col mt-4" id="sessions">
        <h3
          className="text-base sm:text-lg text-lightYellowC1_2"
          id="sleep-sessions"
        >
          Relaxing Sleep Sessions
        </h3>

        <div
          className={`${targetID === "sessions" && "border-2 rounded-md border-themeBlue p-1 pb-3"}`}
        >
          <Slider
            scrollKey={SLIDER_CONSTANT.SLEEP_SESSION_SLIDER}
            dataTestid="sleepSessionSlider"
          >
            {data &&
              data.map((item: ISleepSessionSlider, index: number) => {
                const { background_image, title, description } = item;
                return (
                  <SleepSoundCard
                    id={index}
                    key={index}
                    background_image={background_image}
                    title={title}
                    description={description}
                    testId="sleep-session-slider-card"
                    data={item}
                    setCurrentSound={(
                      data: ICurrentSleepSessionSlider | null,
                    ) => handelShowSmallModal(data)}
                    isPlaying={
                      musicState.currentSound?.id === String(item.id) &&
                      musicState.isPlaying
                    }
                    // isPlaying={isPlaying[index] || false}
                  />
                );
              })}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};

export default SleepSessionSlider;
