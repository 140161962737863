import React, { FC } from "react";

interface IQuizOption {
  selectedChoice: string | null;
  question: string;
  options: { key: string; value: any }[];
  correctChoice: string;
  handleCorrectAnswer: (selectedOption: string, answer: string) => void;
}

const QuizItem: FC<IQuizOption> = ({
  selectedChoice,
  question,
  options,
  correctChoice,
  handleCorrectAnswer,
}) => {
  const buttonClass = `flex cursor-pointer m-3 justify-center items-center border sm:min-w-[280px] sm:max-w-[280px] min-w-[200px] max-w-[200px] w-full min-h-[50px] p-2 rounded-lg`;

  return (
    <div className="flex flex-col items-center">
      <h1 className="mb-4 w-9/12 text-center text-base font-medium text-white sm:text-2xl">
        {question}
      </h1>
      {options.map((option) => {
        return (
          option.value && (
            <div
              className={`${buttonClass} ${selectedChoice === option.value ? "bg-darkPurpleC2" : "bg-inactiveMenu"}`}
              key={option.key}
              onClick={() => handleCorrectAnswer(option.value, correctChoice)}
            >
              <p
                className={`text-center text-sm font-light sm:text-base ${selectedChoice === option.value ? "text-opacity-100" : "text-opacity-70"}`}
              >
                {option.value}
              </p>
            </div>
          )
        );
      })}
    </div>
  );
};

export default QuizItem;
