import { useToday } from '@backend/hooks/useUser';
import { convertToHoursAndMinutesOnlyhm } from '@components/Utils/Functions';
import React, { FC } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import JournalButton from './Journal/JournalButton';

export interface ISleepEventCard {
  title: string;
  description: string;
  type: string;
  borderID?: string;
  targetID?: string;
  id?: string;
}
interface JournalMap {
  [key: string]: string;
  morning: string;
  nap: string;
}

interface JournalBorder {
  [key: string]: string;
  morningJournal: string;
  eveningJournal: string;
}

const SleepEventCard: FC<ISleepEventCard> = ({ title, description, type, borderID = '', id = '', targetID }) => {
  const {
    data: { body: { log } } = {
      body: { chart_data: [], sounds: [], essentials: [], sessions: [] },
    },
  } = useToday();
  const navigate = useNavigate();

  const journalMap: JournalMap = {
    morning: '/morning-journal',
    nap: '/evening-journal',
  };

  const journalBorder: JournalBorder = {
    morningJournal: 'morning-journal',
    eveningJournal: 'evening-journal',
  };

  type EventTypes = React.MouseEvent<HTMLInputElement> | React.MouseEvent<SVGSVGElement> | React.MouseEvent<HTMLButtonElement>;
  const isEveningTaskDone = () => type === 'nap' && (log?.progress_choice !== '' || log?.irritable_choice !== '');

  const handleEditJournal = (e: EventTypes) => {
    e.stopPropagation();
    navigate(journalMap[type], { state: { from: 'Sleep Home' } });
  };

  const handlePastJournal = (e: EventTypes) => {
    e.stopPropagation();
    navigate('/sleep-history');
  };

  const handleClickOnView = () => {
    const shouldNavigate = type;

    if (shouldNavigate) {
      navigate(journalMap[type], { state: { from: 'Sleep Home' } });
    }
  };

  const isMorningTaskDone = () => log?.sleep_log_confirmed && type === 'morning';

  const showStatusIcon = () => {
    if (isEveningTaskDone() || isMorningTaskDone()) {
      return <AiFillCheckCircle className="h-6 w-6 text-journal-green md:self-center" data-testid="complete-icon" />;
    }
    return <FiEdit className="h-6 w-6 cursor-pointer text-yellowC1" data-testid="edit-icon" onClick={handleEditJournal as React.MouseEventHandler<SVGElement>} />;
  };

  // * REMOVED HARDCODED ID of morning / evening to content based ID that I changed into : staticDate.ts.
  // * Adding id above so that the whole div will be highlighted instead of heading.
  return (
    <article
      className={`mb-2 flex cursor-pointer justify-between rounded-lg bg-darkGray2 p-4 py-7 shadow ${journalBorder[borderID] === targetID ? 'rounded-md border-2 border-themeBlue p-2' : ''}`}
      data-testid="sleep-event-card"
      onClick={handleClickOnView}
      id={id}
    >
      <section className="flex w-6/12 flex-col justify-between">
        {type === 'morning' && (
          <h2
            className="text-base font-bold sm:text-lg"
            data-testid="sleep-event-card-title"
            // id="morning-journal"
          >
            {title}
          </h2>
        )}
        {type === 'nap' && (
          <h2
            className="text-base font-bold sm:text-lg"
            data-testid="sleep-event-card-title"
            // id="evening-journal"
          >
            {title}
          </h2>
        )}
        {type === 'morning' && (
          <p className="text-xs font-light text-white text-opacity-70 sm:text-sm" data-testid="sleep-event-card-description">
            {log?.sleep_log_confirmed ? "You're done for the day" : description}
          </p>
        )}
        {type === 'nap' && (
          <p className="text-xs font-light text-white text-opacity-70 sm:text-sm" data-testid="sleep-event-card-description">
            {isEveningTaskDone() ? "You're done for the day" : description}
          </p>
        )}
        {(isEveningTaskDone() || isMorningTaskDone()) && (
          <div className="mt-4 flex flex-col gap-2 sm:w-4/6 sm:items-center lg:w-full lg:flex-row">
            <JournalButton className="max-h-[40px] max-w-full bg-lightGray9 sm:w-full" onClick={handleEditJournal}>
              Edit
            </JournalButton>
            <JournalButton onClick={handlePastJournal} className="inline-block max-h-[40px] w-full max-w-full whitespace-nowrap bg-lightGray9">
              View Past Journals
            </JournalButton>
          </div>
        )}
      </section>
      <section className="flex sm:items-start md:items-center">
        {isEveningTaskDone() && (
          <div className="mr-2 flex flex-col items-center sm:mr-4">
            <h3 className="text-base font-bold sm:text-xl">{log?.day_score}</h3>
            <p className="text-center text-[10px] sm:text-sm">Wellbeing Score</p>
          </div>
        )}
        {isMorningTaskDone() && (
          <div>
            <div className="mr-2 flex flex-col items-center sm:mr-4">
              <h3 className="text-base font-bold sm:text-xl">{log?.sleep_score}</h3>
              <p className="text-[10px] sm:text-sm">Sleep Score</p>
            </div>
            <div className="mr-2 mt-3 flex flex-col items-center sm:mr-4">
              <h3 className="text-sm font-bold sm:text-xl">{convertToHoursAndMinutesOnlyhm(log?.asleep_duration)}</h3>
              <p className="text-[10px] sm:text-sm">Time Asleep</p>
            </div>
          </div>
        )}
        {showStatusIcon()}
      </section>
    </article>
  );
};

export default SleepEventCard;
