import { MusicType } from "@components/SleepToday/MusicPlayer";

export interface IMusicReducer {
  showPlayer: boolean;
  musicType: MusicType;
  currentSound?: any;
  isPlaying: boolean;
  isMiniPlayer: boolean;
}

export const MUSIC_STATES = {
  SET_MUSIC: "SET_MUSIC",
  CLEAR_MUSIC: "CLEAR_MUSIC",
  IS_PLAYING: "IS_PLAYING",
  IS_MINI_PLAYER: "IS_MINI_PLAYER",
} as const;

export type IMusicAction =
  | {
      type: typeof MUSIC_STATES.SET_MUSIC;
      payload: {
        showPlayer: boolean;
        musicType: MusicType;
        currentSound?: any;
        isPlaying?: boolean;
        isMiniPlayer?: boolean;
      };
    }
  | { type: typeof MUSIC_STATES.CLEAR_MUSIC }
  | { type: typeof MUSIC_STATES.IS_PLAYING; payload: { isPlaying: boolean } }
  | {
      type: typeof MUSIC_STATES.IS_MINI_PLAYER;
      payload: { isMiniPlayer: boolean };
    };

export function musicReducer(
  state: IMusicReducer,
  action: IMusicAction,
): IMusicReducer {
  switch (action.type) {
    case MUSIC_STATES.SET_MUSIC:
      const newState =
        action.payload.musicType !== state.musicType
          ? {
              showPlayer: false,
              musicType: "",
              currentSound: null,
              isPlaying: false,
              isMiniPlayer: true,
            }
          : state;

      return {
        ...newState,
        showPlayer: action.payload.showPlayer ?? newState.showPlayer,
        musicType: action.payload.musicType ?? newState.musicType,
        currentSound: action.payload.currentSound ?? newState.currentSound,
        isPlaying: action.payload.isPlaying ?? newState.isPlaying,
        isMiniPlayer: action.payload.isMiniPlayer ?? newState.isMiniPlayer,
      };
    case MUSIC_STATES.CLEAR_MUSIC:
      return {
        showPlayer: false,
        musicType: "",
        currentSound: null,
        isPlaying: false,
        isMiniPlayer: true,
      };
    case MUSIC_STATES.IS_PLAYING:
      return {
        ...state,
        isPlaying: action.payload.isPlaying,
      };
    case MUSIC_STATES.IS_MINI_PLAYER: // Added this case
      return {
        ...state,
        isMiniPlayer: action.payload.isMiniPlayer,
      };
    default:
      throw new Error();
  }
}
