import { anaScreen } from "@backend/ana";
import { useToday } from "@backend/hooks/useUser";
import Container from "@components/Container";
import DayCardSlider from "@components/Daytime/CardSlider/DayCardSlider";
import DaytimeSessions from "@components/Daytime/DaytimeSessions";
import GobackButton from "@components/GobackButton";
import { SLIDER_CONSTANT } from "@components/Utils/constants/ScrollConstant";
import { IMusicAction } from "@reducers/musicReducer";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface IDaytimePage {
  collapsed?: boolean;
  setMusic?: (action: IMusicAction) => void;
  musicState?: any;
}

export const DEFAULT_BODY = {
  chart_data: [],
  sounds: [],
  essentials: [],
  sessions: [],
  current_lessons: [],
};

const DaytimePage: FC<IDaytimePage> = ({ collapsed, setMusic, musicState }) => {
  const location = useLocation();
  const fromSidebar = location?.state?.fromSidebar;

  const { data } = useToday();
  const body = data?.body || DEFAULT_BODY;

  useEffect(() => {
    anaScreen("daytime", null);
  }, []);

  return (
    <Container collapsed={collapsed}>
      {fromSidebar === false && <GobackButton />}
      <h2 className="mb-3 text-2xl font-bold text-charcoalC1 sm:text-3xl" id="daytime-support">
        Daytime Support
      </h2>
      <span className="md:text-md xs:text-xs my-3 flex items-center text-sm font-light text-charcoalC1 sm:text-sm lg:text-lg">
        Enjoy Chorus Daytime Support, which will help you relax and de-stress during your day.
      </span>

      <div id="daytime-essentials" className="mt-2">
        <DayCardSlider data={body.day_essentials} testId="dive-deeper-slider" title="" subTitle="Daytime Essentials" cardType="day_essentials" scrollKey={SLIDER_CONSTANT.WEBVIEW_SLIDER} />
      </div>
      <div id="daytime-sessions">
        <DaytimeSessions data={body.day_sessions} setMusic={setMusic} collapsed={collapsed} musicState={musicState} />
      </div>
    </Container>
  );
};

export default DaytimePage;
