import { handleOpenTimePicker } from "@components/Utils/TimePicker";
import JournalButton from "components/SleepToday/Journal/JournalButton";
import JournalCard from "components/SleepToday/Journal/JournalCard";
import JournalCounter from "components/SleepToday/Journal/JournalCounter";
import React, { ReactNode } from "react";
import { TimePicker } from "react-ios-time-picker";

interface JournalEntryProps {
  value1?: string;
  value2?: string;
  defaultValue1?: string;
  defaultValue2?: string;
  textColor: string;
  bgColor: string;
  icon?: ReactNode;
  setValue1?: (type: string) => void;
  setValue2?: (type: string) => void;
  firstRowTitle: string;
  secondRowTitle: string;
  firstRowButtonTitle?: string;
  secondRowButtonTitle?: string;
  cardTitle: string;
  date?: string | undefined | null;
  numberOfAwaking?: number | string;
  timeAwake?: number | string;
  handelNumberOfAwaking?: (value: number | string) => void;
  handelTimeAwake?: (value: number | string) => void;
  firstTimerId?: string;
  secondTimerId?: string;
}

const JournalEntry: React.FC<JournalEntryProps> = ({
  value1,
  value2,
  defaultValue1,
  defaultValue2,
  setValue1,
  setValue2,
  firstTimerId,
  secondTimerId,
  bgColor,
  textColor,
  cardTitle,
  icon,
  firstRowTitle,
  secondRowTitle,
  firstRowButtonTitle,
  secondRowButtonTitle,
  date,
  numberOfAwaking,
  timeAwake,
  handelNumberOfAwaking,
  handelTimeAwake,
}) => {
  const handleOpenTimer = (timerId: string) => {
    const inputElement = document.getElementById(timerId) as HTMLElement;
    inputElement?.click();
  
  };

  const onChangeBedTime = (timeValue: string) => {
    setValue1 && setValue1(timeValue);
  };

  const onChangeAsleepTime = (timeValue: string) => {
    setValue2 && setValue2(timeValue);
  };

  const handleFirstTimerClickOpen = () => {
    handleOpenTimer(firstTimerId || "");
    handleOpenTimePicker();
  };

  const handleSecondTimerClickOpen = () => {
    handleOpenTimer(secondTimerId || "");
    handleOpenTimePicker();
  };

  return (
    <JournalCard
      icon={icon}
      outerTitle={cardTitle}
      iconClass={textColor}
      dateClass={textColor}
      date={date}
    >
      <p className="text-sm font-semibold ">{firstRowTitle}</p>
      <div className="mt-2 flex w-full items-center justify-between">
        {firstRowButtonTitle ? (
          <>
            <TimePicker
              onOpen={handleOpenTimePicker}
              key={value1}
              value={value1 || defaultValue1}
              onChange={onChangeBedTime}
              use12Hours
              inputClassName="text-sm border-none pl-0 w-[100px]"
              id={firstTimerId}
              name={firstTimerId}
            />
            <JournalButton
              className={`border ${textColor} bg-lightGray9`}
              onClick={handleFirstTimerClickOpen}
            >
              {firstRowButtonTitle}
            </JournalButton>
          </>
        ) : (
          <JournalCounter
            value={numberOfAwaking}
            onValueChange={handelNumberOfAwaking || (() => {})}
          />
        )}
      </div>
      <div className="mt-5 border opacity-25"></div>
      <p className="mt-4 text-sm font-semibold">{secondRowTitle}</p>
      <div className="mt-2 flex w-full items-center justify-between">
        {secondRowButtonTitle ? (
          <>
            <TimePicker
              onOpen={handleOpenTimePicker}
              key={value2}
              value={value2 || defaultValue2}
              onChange={onChangeAsleepTime}
              use12Hours
              inputClassName="text-sm border-none pl-0 w-[100px]"
              id={secondTimerId}
              name={secondTimerId}
            />
            <JournalButton
              className={`${bgColor}`}
              onClick={handleSecondTimerClickOpen}
            >
              {secondRowButtonTitle}
            </JournalButton>
          </>
        ) : (
          <JournalCounter
            value={timeAwake}
            jumpValue={5}
            onValueChange={handelTimeAwake || (() => {})}
          />
        )}
      </div>
    </JournalCard>
  );
};

export default JournalEntry;
