// index.tsx
import ErrorBoundary from '@components/ErrorBoundary';
import App from 'App';
import { AppPageWrap } from 'backend/auth_wrapper';
import 'index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <Router>
      <AppPageWrap>
        <App />
      </AppPageWrap>
    </Router>
  </ErrorBoundary>
);

reportWebVitals();
