/* eslint-disable react-hooks/rules-of-hooks */
import LoginRegPage from "pages/LoginRegPage";
import { ISwitchReducer } from "reducers/switchReducer";
import { leaderShipRoutes, sleepRoutes } from "./routesObject";
import SettingsPage from "pages/Sleep/SettingsPage";
import { localPut, localSessionsPut } from "backend/storage";
import FullscreenPlayerPage from "@pages/Sleep/FullscreenPlayerPage";
import ChallengePage from "pages/Sleep/ChallengePage";
import { GiProgression } from "react-icons/gi";
import {
  isUserAdmin,
  isB2BGroup,
  isStaff,
  isDashboardEnabled,
} from "@components/Utils/Functions";
import SleepCoachPage from "@pages/Sleep/SleepCoachPage";
import LessonDetail from "@pages/Sleep/LessonDetail";
import PastLessonPage from "@pages/Sleep/PastLessonPage";
import MoreInfoPage from "@pages/Sleep/MoreInfoPage";
import FullplayerPage from "@pages/Sleep/FullplayerPage";
import AllLessonPage from "@pages/Sleep/AllLessonPage";
import DaytimePage from "@pages/DaytimePage";
import { getAT } from "@backend/api/service";
// import ConfirmLogin from "@pages/ConfirmLogin";

export interface RouteItem {
  path: string;
  component: React.ComponentType<any>;
  label?: string;
  icon?: React.ComponentType;
  active?: boolean;
  props?: any;
  isNotification?: boolean;
}

interface IRenderRoutes extends ISwitchReducer {
  user?: any;
  location?: any;
}

type RouteConfig = RouteItem[];

const consumerAccessPageList = [
  "/settings",
  "/past-lessons",
  "/sleep-coach",
  "/lesson-detail/",
];

const getSelectedRoutes = (state: IRenderRoutes) => {
  const { user, switchToSleepToday } = state;

  const userIsAdmin = isUserAdmin(user.group.admin_emails, user.user.email);

  const isB2B = isB2BGroup(user?.group);

  if (isStaff(user.user)) {
    return switchToSleepToday ? sleepRoutes : leaderShipRoutes;
  }

  if (userIsAdmin && isB2B) {
    if (isDashboardEnabled(user?.group)) {
      return switchToSleepToday ? sleepRoutes : leaderShipRoutes;
    } else {
      return sleepRoutes;
    }
  }
  if (!userIsAdmin && isB2B) {
    return sleepRoutes;
  }

  return sleepRoutes.filter((route) =>
    consumerAccessPageList.includes(route.path),
  );
};

const WHITELISTED_PATHS = [
  "/settings/",
  "/player/:sessionId",
  "/sleep-coach/",
  "/past-lessons/",
  "/content/",
  "/help-support/",
];

export const isPathWhitelisted = (pathname: string): boolean => {
  return WHITELISTED_PATHS.some((whitelistedPath) =>
    pathname.includes(whitelistedPath),
  );
};

export const extractAndStoreToken = (search: string) => {
  const token = new URLSearchParams(search).get("at");
  // debugger;
  if (token) {
    localPut("at", token);
    // *DANGER* forces hide=true forever
    localSessionsPut("view", "web");
  }
  return token;
};

const renderRoutes = (state: IRenderRoutes): RouteConfig => {
  let routesList = [
    {
      path: "/",
      component: LoginRegPage,
      props: {
        refetch: () => {
          throw new Error("Function not implemented.");
        },
      },
    },
    {
      path: "/player/:sessionId",
      component: FullplayerPage,
      props: {},
    },
    {
      path: "/settings",
      component: SettingsPage,
      props: {},
    },
    {
      path: "/sleep-coach",
      component: SleepCoachPage,
      props: {},
    },
    {
      path: "/lesson-detail/:id",
      component: LessonDetail,
      props: {},
    },
    // {
    //   path: "/more-info/:urlId1/:urlId2/:urlId3",
    //   component: MoreInfoPage,
    //   props: {},
    // },
    // {
    //   path: "/more-info/:infoId",
    //   component: MoreInfoPage,
    //   props: {},
    // },
    // {
    //   path: "/more-info/:url1/:url2",
    //   component: MoreInfoPage,
    //   props: {},
    // },
    {
      path: "/content/",
      component: MoreInfoPage,
      props: { key:"content" },
    },
    {
      path: "/past-lessons",
      component: PastLessonPage,
      props: {},
    },
    {
      path: "/help/",
      // path: "/help/:hlp1/:hlp2",
      component: MoreInfoPage,
      props: { key:"help-page" },
    },
    {
      path: "/all-lessons",
      component: AllLessonPage,
      props: {},
    },
    // ! DAYTIME PAGE SHOULD NOT BE SHOWN IN LEADERSHIP.
    // ! DAYTIME PAGE WAS DECLARED ON BOTH SLEEP ROUTES AND HERE
    // {
    //   path: "/daytime-support",
    //   component: DaytimePage,
    //   props: {},
    // },
    ...getSelectedRoutes(state),
  ];

  const { pathname, search } = state.location;

  // We're going to need to keep this for now since in some webview the android app doesnt send hide=true
  // or even if it does we don't have a good way to track page movements within the android app's webview
  if (isPathWhitelisted(pathname) && search.includes("?at")) {
    extractAndStoreToken(search);
  }
  // Hide the challenge page for now since it only really makes sense as part of the today page
  // if (state.user.challenge && state.switchToSleepToday) {
  //   routesList.push({
  //     path: "/challenge",
  //     component: ChallengePage,
  //     props: {},
  //     label: "Challenge",
  //     icon: GiProgression,
  //   });
  // }

  return routesList;
};

export default renderRoutes;
