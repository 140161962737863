import { LeftOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

const GobackSleepCoach: FC = () => {
  let navigate = useNavigate();

  // * Previously they didn't check if the user is new to page like he clicked a link so there won't be any back for this except the browser homepage so we need to place a check on history if history is >2 means one is the link you opened and one will be the browser / tab home page so it must be greater than 2 to have a back state.
  const handleBack = () => {
    if (window.history.length > 2) navigate(-1);
    else {
      navigate("/");
    }
  };

  return (
    <div
      className={`bg-darkGray2 p-4 rounded-lg shadow  mb-3 w-full`}
      data-testid="gobackSleepCoach"
    >
      <div className=" flex items-center mb-4">
        <LeftOutlined className="cursor-pointer" onClick={handleBack} />
        <div className="flex w-full justify-center">
          <h1 className="text-xl sm:text-4xl">My Sleep Coach</h1>
        </div>
      </div>

      <div>
        <p className="text-xs text-center sm:text-base">
          Your dedicated sleep coach is here to answer any questions, talk
          through challenges, and help reinforce healthy sleep habits.
        </p>
      </div>
    </div>
  );
};

export default GobackSleepCoach;
