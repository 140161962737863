export const USER_QUERY = "user";
export const USER_TODAY = "userToday";
export const USER_SESSON = "userSesson";
export const STATS_QUERY = "stats";
export const COACH_QUERY = "coach";
export const LESSON_QUERY = "lesson";
export const PAST_LESSON_QUERY = "past";
export const LESSONS_QUERY = "lessons";
export const LESSON_QUERY_WITH_ID = "lessonWithId";
export const USER_SLEEP_HISTORY = "sleepHistory";
export const USER_LOGS = "userLogs";
export const USER_META = "userMeta";
export const USER_LOGIN_LT = "login-lt";
