import { SleepSoundCard } from "@components/SleepToday";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";
import { has } from "lodash";
import { FC, useEffect, useState } from "react";
import DaytimeSessionCard from "./DaytimeSoundCard";

interface ICurrentDaytimeSessionSlider extends IDaytimeSessionSlider {
  currentId: number;
  type?:any
}

export interface IDaytimeSessionSlider {
  background_image: string;
  content_link?: string;
  title: string;
  description?: string;
  id: number;
  testId?: string;
  author_id?: string;
  setCurrentSound: (sound: ICurrentDaytimeSessionSlider | null) => void;
  data: any;
  isPlaying: boolean;
}

interface IDaytimeSessionSliderProps {
  data?: IDaytimeSessionSlider[];
  collapsed?: boolean;
  setMusic?: (action: IMusicAction) => void;
  musicState: any;
  targetID?: string;
}

const DaytimeSessions: FC<IDaytimeSessionSliderProps> = ({
  data,
  setMusic,
  musicState,
  targetID = "",
}) => {
  const [isPlaying, setIsPlaying] = useState<{ [key: number]: boolean }>({});
  const handelShowSmallModal = (data: ICurrentDaytimeSessionSlider | null) => {
    if (!data) {
      setMusic &&
        setMusic({
          type: MUSIC_STATES.IS_PLAYING,
          payload: { isPlaying: false },
        });
    } else {
      setIsPlaying({});
      setMusic &&
        setMusic({
          type: MUSIC_STATES.SET_MUSIC,
          payload: {
            currentSound: data,
            showPlayer: true,
            musicType: data.type || '',
            isPlaying: true,
          },
        });
    }
  };

  useEffect(() => {
    if (
      musicState.currentSound === null ||
      !musicState.isPlaying ||
      !has(musicState.currentSound, "currentId")
    ) {
      setIsPlaying({});
    } else {
      setIsPlaying((prev) => ({
        ...prev,
        [musicState.currentSound.currentId]: true,
      }));
    }
  }, [musicState]);
  return (
    <div className="mx-auto">
      {
        <h3 className="mt-4 text-base font-semibold sm:text-1xl text-charcoalC1" id="daytime-sessions">
          Relaxing Daytime Sessions
        </h3>
      }
      <div className="grid grid-cols-2 gap-2 mx-auto max-lg:grid-cols-1">
        {data &&
          data.map((item: IDaytimeSessionSlider, index: number) => {
            const { background_image, title, description } = item;
            return (
              <DaytimeSessionCard
                id={index}
                key={index}
                background_image={background_image}
                title={title}
                description={description}
                testId="sleep-session-slider-card"
                data={item}
                setCurrentSound={(data: ICurrentDaytimeSessionSlider | null) =>
                  handelShowSmallModal(data)
                }
                isPlaying={
                  musicState.currentSound?.id === String(item.id) &&
                  musicState.isPlaying
                }
                // isPlaying={isPlaying[index] || false}
              />
            );
          })}
      </div>
    </div>
  );
};

export default DaytimeSessions;
