import { useEffect } from "react";

const useScrollPosition = (key: string) => {
  useEffect(() => {
    const scrollPos = localStorage.getItem(key);
    if (scrollPos) {
      window.scrollTo(0, parseInt(scrollPos));
    }
    const updateScrollPos = () => {
      const number = window.pageYOffset - 520;
      localStorage.setItem(key, number.toString());
    };

    window.addEventListener("scroll", updateScrollPos);

    return () => window.removeEventListener("scroll", updateScrollPos);
  }, [key]);
};

export default useScrollPosition;
