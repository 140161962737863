import React, { ReactElement, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface CloseButtonProps {
  Icon: ReactElement;
  text: ReactNode;
  backPressed?: () => void;
  className?: string;
  backLink?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({
  Icon,
  text,
  backPressed,
  className,
  backLink,
}) => {
  const navigate = useNavigate();

  // * Previously they didn't check if the user is new to page like he clicked a link so there won't be any back for this except the browser homepage so we need to place a check on history if history is >2 means one is the link you opened and one will be the browser / tab home page so it must be greater than 2 to have a back state.
  const handleClick = () => {
    if (window.history.length > 2) navigate(-1);
    else {
      if (backLink) {
        navigate(backLink);
      } else navigate("/");
    }
    backPressed && backPressed();
  };

  return (
    <div
      className={`bg-darkGray2 p-4 rounded-lg shadow mb-2 max-w-[530px] w-full ${className}`}
      data-testid="closebuttoncontainr"
    >
      <div
        className="flex items-center w-fit cursor-pointer"
        onClick={handleClick}
      >
        {React.cloneElement(Icon, { className: "text-2xl" })}
        <p className="ml-3" data-testid="closebuttontitle">
          {text}
        </p>
      </div>
    </div>
  );
};

export default CloseButton;
