import { format, parse, parseISO } from "date-fns";
import { isEmpty, split } from "lodash";

interface EmojiItem {
  apiValue: string;
  title: string;
}

export const convertTo24HourFormat = (time: string) => {
  if (time) {
    const formattedTime = format(parse(time, "hh:mm aa", new Date()), "HH:mm");
    const [hours, minutes] = formattedTime
      .split(":")
      .map((time) => parseInt(time));
    return { hours, minutes };
  }
  return { hours: 0, minutes: 0 };
};

export const formatDate = (day: string): string => {
  const date = parseISO(day);
  return format(date, "EEE, MMM dd");
};

export const formatTime = (hour: number, min: number): string => {
  if (hour !== undefined && min !== undefined) {
    const in24HourDate = parse(`${hour}:${min}`, "HH:mm", new Date());
    return format(in24HourDate, "hh:mm a");
  }
  return "";
};

export const extractTimeComponents = (
  timeString: string,
): [string, string, string] => {
  // Split the string into two parts: the time and the period
  const [time, period] = timeString.split(" ");

  // Split the time into hour and minute
  const [hour, minute] = time.split(":");

  return [hour, minute, period];
};

export const findEmoji = (
  emojiList: EmojiItem[],
  apiValue: string,
): string | null => {
  const emojiExist = emojiList.find((item) => item.apiValue === apiValue);
  return emojiExist ? emojiExist.title : null;
};

export const convertToMinutes = (timeStr: string) => {
  if (!isEmpty(timeStr)) {
    const parts = timeStr.split(" ");

    const hours = Number(parts[0]);
    const minutes = Number(parts[2]);

    const totalMinutes = hours * 60 + minutes;

    return totalMinutes;
  }

  return 0;
};

export const convertToHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours} hr ${minutes} min`;
};

export const convertToHoursAndMinutesOnlyhm = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h${minutes}m`;
};

export const reloadWindow = () => window.location.reload();

export const teacherURL = (tid?: string) => {
  const base: string =
    "https://storage.googleapis.com/chorus-public/img/teacher-transparent/";
  const defaultURL: string =
    "https://www.chorussleep.com/static/404-3d1a76716461d5e5f2c46b563f512040.svg";

  const teacherImgUrls: { [id: string]: string } = {
    bri: "bri-square.png",
    ellie: "ellie-square.png",
    kevin: "kevin-square.png",
    liam: "liam-square.png",
    luigi: "luigi-square.png",
    maddy: "maddy-square.png",
    miya: "miya062502.png",
    mk: "mk-square.png",
    dana:"dana-square.png"
  };

  return teacherImgUrls[tid ?? ""]
    ? base + teacherImgUrls[tid ?? ""]
    : defaultURL;
};

export const secondsToMinSec = (seconds: number) => {
  if (isNaN(seconds)) {
    return "00:00";
  }
  const min = Math.floor(seconds / 60);
  const sec = Math.floor(seconds % 60);
  return `${min.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
};

interface TimeResult {
  hours: number;
  minutes: number;
}

export const convertTime = (timeString: string): TimeResult => {
  const parsedDate = parse(timeString, "hh:mm a", new Date());
  const hours = parsedDate.getHours();
  const minutes = parsedDate.getMinutes();

  return { hours, minutes };
};

// Auth Functions

export const isUserAdmin = (
  adminList: string[],
  userEmail: string,
): boolean => {
  return adminList?.includes(userEmail);
};

export const isB2BGroup = (group: any) => group?.is_b2b === true;

export const isStaff = (user: any) => user?.staff_status === "staff";

export const isDashboardEnabled = (group: any) => group?.dash_enabled === true;

export const getUrlWithoutParams = (url: string): string => {
  let parts: string[] = split(url, ":");
  return parts[0];
};

export const matchesPath = (itemPath: string, path: string): boolean => {
  const pathNameParts = path.split("/");
  const itemPathParts = itemPath.split("/");

  if (pathNameParts.length !== itemPathParts.length) {
    return false;
  }

  return pathNameParts.every(
    (segment, index) =>
      itemPathParts[index].startsWith(":") || segment === itemPathParts[index],
  );
};
