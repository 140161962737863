import React, { FC, useMemo } from "react";
import ChallengeAccordion from "./ChallengeAccordion";
// import { ChallengeData } from "types/challengeType";
import { ChallengesType } from "@pages/Sleep/SleepTodayPage";
import InfoButton from "@components/InfoButton";

interface IChallengeScreen {
  className?: string;
  title?: string;
  // challenge: ChallengeData;
  challenge: ChallengesType;
  onScrollClick?: (target: string) => void;
  pageType?: string;
}
/**
 *
 * @param { string } url URL for blog post.
 * @returns  final URL for blog post MoreInfo page.
 * @description This fn() checks if the url has domain name in it or not, if there is a domain name then we will remove the domain name and return the link for post slug, if there is no domain name then check if it starts with / if yes remove / and return else return the original url string.
 */
function parseBlogUrl(url: string): string {
  // * Example of full URL: https://chorussleep.com/posts/tips/get-sunlight
  // * Example of slug URL: /posts/tips/get-sunlight.
  // * Implementation of MoreInfoPage that fetches the blog from gatsby does not require '/' or domain so we will remove.

  // * Check if string contains domain or not either a full link or the slug link.
  if (url.includes("http")) {
    // * Create a new URL object.
    let temp = new URL(url);
    // * temp.pathname will return /posts/tips/get-sunlight, so we will remove '/' from first index.
    return temp.pathname.slice(1);
  } else {
    // * URL does not contain domain.

    // * if it contains '/' at first index? remove it.
    if (url.charAt(0) === "/") {
      // * Example: /posts/tips/get-sunlight => posts/tips/get-sunlight. so that the full url will be /content?link=posts/tips/get-sunlight.
      return url.slice(1);
    } else {
      // *  No '/' at first index? return as it is.
      // * Example: posts/tips/get-sunlight, so that the full url will be /content?link=posts/tips/get-sunlight.
      return url;
    }
  }
}

const ChallengeScreen: FC<IChallengeScreen> = ({ className = "", title, onScrollClick, challenge, pageType = "sleep-today" }) => {
  // * Memoized for performance and reduced re-renders. Fixed 200% issue with Progress
  const { completedTasksCount, completedPercentage } = useMemo(() => {
    if (challenge.steps) {
      let completedTaskCount = challenge?.steps?.filter((step) => step.status === "complete").length;
      let completedPercent = Math.round((completedTaskCount / challenge.steps?.length) * 100);
      return { completedTasksCount: completedTaskCount, completedPercentage: completedPercent };
    } else return { completedTasksCount: 0, completedPercentage: 0 };
  }, [challenge.steps]);
  // const completedPercentage = Math.round((completedTasksCount?.length / challenge.steps?.length) * 100);
  // * Return title based on challenges Type.
  const description = useMemo(() => {
    return challenge?.status === "active" ? challenge?.description : challenge.completed_text;
  }, [challenge]);
  return (
    <div className={className}>
      {title && (
        <h2 className={`relative mb-3 text-base font-bold text-white sm:text-3xl`} id="get-started">
          {title}
          {challenge.read_more_link ? (
            <InfoButton
              contentUrl={{
                path: "/content?link=",
                url: parseBlogUrl(challenge.read_more_link),
              }}
              from="Sleep Home"
              className="absolute ml-2"
            />
          ) : null}
        </h2>
      )}

      <ChallengeAccordion title={description} completedPercentage={completedPercentage} challenge={challenge} onScrollClick={onScrollClick} pageType={pageType} />
    </div>
  );
};

export default ChallengeScreen;
