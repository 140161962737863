import React, { FC, ReactNode } from "react";

interface IJournalButtonProps {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  className?: string;
}

const JournalButton: FC<IJournalButtonProps> = (props) => {
  const { onClick, children, disabled = false, className = "" } = props;

  return (
    <button
      disabled={disabled}
      className={`text-white text-opacity-70 min-w-[80px] w-full max-w-[150px] font-light text-[10px] sm:text-sm px-2 sm:px-4 py-2 rounded-xl border shadow-2xl ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default JournalButton;
