import { ChangeEvent } from "react";
import React, { FC } from "react";
interface ISleepTextBox {
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const SleepTextBox: FC<ISleepTextBox> = ({ onChange, value }) => {
  const inputClass = `flex cursor-pointer justify-center items-center border w-full min-w-[150px] max-w-[280px] sm:min-w-[200px] rounded-lg overflow-hidden`;

  return (
    <div className={inputClass}>
      <textarea
        placeholder="Other (write in)"
        className={`border-2 border-gray-200 w-full focus:border-transparent border-transparent focus:outline-none focus:ring-0 focus:bg-transparent font-light text-md text-center px-2 ${value ? "bg-darkPurpleC2" : "bg-transparent"}`}
        onChange={onChange && onChange}
        value={value || ""}
        rows={3}
      />
    </div>
  );
};

export default SleepTextBox;
