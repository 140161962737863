import { anaScreen } from "@backend/ana";
import { useUser } from "@backend/hooks/useUser";
import Container from "@components/Container";
import GrayBox from "@components/Utils/GrayBox";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { FC, useEffect } from "react";

interface CompanyAccountProps {
  collapsed?: boolean;
}

const CompanyAccount: FC<CompanyAccountProps> = ({ collapsed }) => {
  const { data } = useUser();

  const admin_emails: string[] = data.body.group.admin_emails;

  useEffect(() => {
    anaScreen("leadership_account", null);
  }, []);

  return (
    <Container data-testid="company-account-page" collapsed={collapsed}>
      <h1 className="text-3xl font-medium py-6 pl-6 mb-8">Company Account</h1>
      <GrayBox>
        <h3 className="text-xl mb-6 ">Admin Users</h3>
        <p className="opacity-80 font-light text-sm mb-6">
          To add or remove administrator accounts, please email
          techsupport@chorussleep.com
        </p>

        <ul className="list-none space-y-2">
          {admin_emails.map((item, index) => (
            <li className="text-white flex items-center" key={index}>
              <EnvelopeIcon className="h-5 w-5 text-themeBlue mr-2" />
              <span className="opacity-80 font-light">{item}</span>
            </li>
          ))}
        </ul>
      </GrayBox>
    </Container>
  );
};

export default CompanyAccount;
