import {
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  MoonIcon,
} from "@heroicons/react/24/solid";
import React, { FC } from "react";
import { teacherURL } from "@components/Utils/Functions";
import { ISleepSessionSlider } from "./SessionSlider/SleepSessionSlider";
import { capitalize } from "lodash";
import { localDel } from "@backend/storage";

const SleepSoundCard: FC<ISleepSessionSlider> = (props) => {
  const {
    id,
    testId = "sleep-sound-card",
    setCurrentSound,
    data,
    isPlaying,
  } = props;

  const { background_image, title, author_id, duration } = data;

  const handlePlay = () => {
    localDel("currentTime");
    setCurrentSound(isPlaying ? null : { ...data, currentId: id });
  };
  return (
    <div className="mx-1 mt-2 bg-darkPurpleC2 rounded-2xl">
      <div
        style={{ backgroundImage: `url('${background_image}')` }}
        className="relative flex h-80 min-w-[400px] max-w-[400px] cursor-pointer items-end justify-center rounded-2xl bg-cover bg-center bg-no-repeat p-3"
        data-testid={`${testId}-${id}`}
        onClick={handlePlay}
      >
        <PlayPauseButton isPlaying={isPlaying} onClick={handlePlay} />

        <div className="h-[68px] relative m-4 flex w-full items-center rounded-lg bg-gray-300 p-3">
          {data?.author_id && (
            <img
              className="absolute bottom-0"
              src={teacherURL(data.author_id)}
              alt="teacher"
              width="100"
              height="100"
            />
          )}
          <div className="w-1/3" />
          <div className="w-2/3">
            <p
              className="font-bold leading-5 text-black text-1xl truncate-2-lines"
              data-testid={`sleep-sound-card-description-${id}`}
            >
              {title}
            </p>
            <p
              className="text-sm font-light text-black"
              data-testid={`sleep-sound-card-title-${id}`}
            >
              {capitalize(author_id)}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between px-5 py-2">
        <div className="flex items-center gap-1">
          <SpeakerWaveIcon className="w-6" />
          <p className="text-sm font-semibold">{`${duration} mins`}</p>
        </div>
        <div className="flex flex-col items-center gap-1">
          <MoonIcon className="w-6 rotate-[24deg]" />
          <p className="text-xs">Sleep</p>
        </div>
      </div>
    </div>
  );
};

export default SleepSoundCard;

const PlayPauseButton: FC<{ isPlaying: boolean; onClick: () => void }> = ({
  isPlaying,
  onClick,
}) => (
  <div
    onClick={onClick}
    className="absolute w-10 h-10 text-white transform -translate-x-1/2 -translate-y-1/2 cursor-pointer left-1/2 top-1/2"
    role="button"
    aria-label={isPlaying ? "Pause" : "Play"}
  >
    {isPlaying ? (
      <PauseIcon data-testid="play-icon" />
    ) : (
      <PlayIcon data-testid="play-icon" />
    )}
  </div>
);
