import { SVG } from "types/svg";

export const ArrowDownIcon = (props: SVG) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1L6 6L11 1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
