import React, { FC } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import ProgressChart from "./ProgressChart";

// * Added id into staticData.ts so passing here as props.
interface ILessonCard {
  title: string;
  day: number;
  duration: number;
  progress: any;
  totalItems?: number;
  id?: string;
}

const LessonCard: FC<ILessonCard> = (props) => {
  const { title, day, duration, progress, id, totalItems } = props;
  return (
    <div
      className="bg-darkGray2 p-4 rounded-lg shadow mb-2 justify-between flex items-center mt-4"
      id={"lessons-" + id}
    >
      <div className="flex flex-col">
        <div className="text-base sm:text-lg font-bold">{title}</div>
        <div className="mt-3 text-sm font-light">Day {day}</div>
      </div>

      {!progress ? (
        <ProgressChart
          total={progress?.len || totalItems}
          value={0}
          time={duration}
        />
      ) : progress.completed ? (
        <AiFillCheckCircle
          className="h-8 w-8 text-journal-green"
          data-testid="complete-icon"
        />
      ) : (
        <ProgressChart
          total={progress.len}
          value={progress.position}
          time={duration}
        />
      )}
    </div>
  );
};

export default LessonCard;
