/* eslint-disable react-hooks/exhaustive-deps */
import { useUser } from "@backend/hooks/useUser";
import {
  isUserAdmin,
  isB2BGroup,
  isDashboardEnabled,
  isStaff,
} from "@components/Utils/Functions";
import { localGet } from "backend/storage";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const NotFound: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const switchValue = localGet("sleep-portal");
  const { data } = useUser();

  const user = data?.body?.user;
  const [searchParams] = useSearchParams();

  const navigationPathsLeaderShip = [
    "/dashboard",
    "/resources",
    "/company-account",
  ];
  const navigationPathsSleepToday = [
    "/sleep-today",
    "/sleep-history",
    "/morning-journal",
    "/evening-journal",
    "/sleep-history/morning-journal",
    "/sleep-history/evening-journal",
    "/content/",
    "/sleep-history/journal-view",
    "/lesson-detail/",
    "/past-lessons",
    "/team-challenge",
    "/redeem-gift",
    "/settings",
    "/challenge",
    "/player",
  ];

  const notB2BAccessPath = [
    "/settings/",
    "/sleep-coach/",
    "/past-lessons/",
    "/lesson-detail/",
  ];

  useEffect(() => {
    const sleepTodayInvalid = !navigationPathsSleepToday.some((path) =>
      location.pathname.startsWith(path),
    );
    const leadershipInvalid = !navigationPathsLeaderShip.some((path) =>
      location.pathname.startsWith(path),
    );

    const isAdmin = isUserAdmin(data?.group?.admin_emails, user.email);

    const isB2B = isB2BGroup(data?.body?.group);

    const searchValue = searchParams.toString();

    if (isStaff(data.body.user)) {
      navigate(switchValue ? "/sleep-today" : "/dashboard");
      return;
    }
    if (!isB2B) {
      searchParams.set("hide", "true");
      const newPath = notB2BAccessPath.some((path) =>
        location.pathname.includes(path),
      )
        ? location.pathname
        : "/settings";
      navigate({ pathname: newPath, search: searchValue });
      return;
    }
    if (!isAdmin && isB2B) {
      navigate("/sleep-today");
      return;
    }

    if (isAdmin && isB2B) {
      if (isDashboardEnabled(data?.body?.group)) {
        navigate(switchValue ? "/sleep-today" : "/dashboard");
        return;
      } else {
        navigate("/sleep-today");
        return;
      }
    }

    // Conditions related to switch value and navigation path checks.
    if (switchValue && sleepTodayInvalid) {
      navigate("/sleep-today");
    } else if (!switchValue && leadershipInvalid) {
      navigate("/dashboard");
    }
  }, [
    location,
    navigate,
    navigationPathsLeaderShip,
    navigationPathsSleepToday,
    switchValue,
    user,
  ]);

  return null;
};

export default NotFound;
