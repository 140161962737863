import React, { FC } from "react";

interface IInfoBlockProps {
  title: string;
  value: string | number;
}

const InfoBlock: FC<IInfoBlockProps> = ({ title, value }) => (
  <div
    className="flex flex-col items-center justify-center"
    data-testid="infoblockcontainer"
  >
    <p className="text-[11px] font-light text-white text-opacity-70 sm:text-base md:text-lg">
      {title}
    </p>
    <p className="text-sm font-bold sm:text-lg md:text-2xl">{value}</p>
  </div>
);

export default InfoBlock;
