/**
 *
 * @param {HTMLElement} element HTML ELEMENT to scroll to.
 * @param {boolean} padding Flag to add padding when showing border.
 * @description This function will take element that we want to scroll to and add border to it.
 */
export function scrollIntoView(element?: HTMLElement | null, padding = true, isDaytimePage: boolean = false) {
  if (element) {
    // * If border has been applied to any previous element first find that one and remove the border from that one.
    removePreviousScrollBorder();
    // * Element is found now we will add border class for highlighting purposes.
    element.classList.add(isDaytimePage ? 'highlighted-border-daytime' : 'highlighted-border', 'scroll-mt-20');
    if (padding) {
      if (!element.id.includes('-')) {
        element.classList.add('px-1');
      }
    }
    // * Just a check to remove border on click -> if click inside the div remove animation / border.
    element.addEventListener('click', () => {
      if (element) element.classList.remove(isDaytimePage ? 'highlighted-border-daytime' : 'highlighted-border', 'scroll-mt-20');
      if (padding) {
        if (!element.id.includes('-')) {
          element.classList.remove('px-1');
        }
      }
    });
    // setTimeout(() => {
    //   document.addEventListener('click', (event) => {
    //     const isOutsideClick = !element.contains(event.target as any);
    //     if (isOutsideClick) {
    //       element.classList.remove(isDaytimePage ? 'highlighted-border-daytime' : 'highlighted-border', 'scroll-mt-20');
    //       if (padding) {
    //         if (!element.id.includes('-')) {
    //           element.classList.remove('px-1');
    //         }
    //       }
    //     }
    //   });
    // }, 1000);

    // const header = document.getElementsByTagName("header")[0];
    // const elementPosition = element.getBoundingClientRect().top
    // * Scroll to element and keep it ---in Center--- to Top and scroll by navbar height .
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    // window.scrollTo({ behavior:"smooth",top: elementPosition - header.offsetHeight   })
    // * After 3 seconds remove the animation and border from tag.
    // ! Update: We don't need to use this now as we are using static border now. it wont fade away.
    // setTimeout(() => {
    //   if (element)
    //     element.classList.remove(
    //       isDaytimePage ? "highlighted-border-daytime" : "highlighted-border",
    //     );
    //   if (padding) {
    //     if (!element.id.includes("-")) {
    //       element.classList.remove("px-1");
    //     }
    //   }
    // }, 3000);
  }
}

export function removePreviousScrollBorder() {
  let previousElement = document.getElementsByClassName('highlighted-border');
  if (previousElement.length > 0) {
    previousElement[0].classList.remove('highlighted-border');
  }
  previousElement = document.getElementsByClassName('highlighted-border-daytime');
  if (previousElement.length > 0) {
    previousElement[0].classList.remove('highlighted-border-daytime');
  }
}
