import React, { useState } from "react";

interface JournalTimeInputProps {
  onTimeChange: (hour: string, minute: string) => void;
  showModal: boolean;
  handelClose: () => void;
}

const JournalDurationInput: React.FC<JournalTimeInputProps> = ({
  onTimeChange,
  showModal,
  handelClose,
}) => {
  const [hour, setHour] = useState<string>("");
  const [minute, setMinute] = useState<string>("");

  const [error, setError] = useState<boolean>(false);

  const handleHourChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    setHour(newValue);
    setError(false);
  };

  const handleBackspaceHour = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && hour.length === 1) {
      setHour("");
    }
  };

  const handleMinuteChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    setMinute(newValue);
    setError(false);
  };

  const handleBackspaceMinute = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && minute.length === 1) {
      setMinute("");
    }
  };

  const handleOk = () => {
    if (hour === "" || minute === "") {
      setError(true);
    } else {
      onTimeChange(hour, minute);
      handelClose();
    }
  };

  const handleCancel = () => {
    handelClose();
  };

  return (
    <>
      {showModal && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-20"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="w-[230px] bg-lightGray1 px-4 py-3 rounded-md">
            <p className="text-lg text-white text-opacity-70 mb-4">
              Enter Duration
            </p>
            <div className="flex mt-2">
              <div className="flex flex-col mr-2">
                <div
                  className={`bg-transparent w-20 h-16 text-white text-opacity-70 rounded-md border-[2px] ${error && hour === "" ? "border-red-500" : "border-white border-opacity-70"}`}
                >
                  <input
                    type="number"
                    className="w-full bg-transparent rounded-md h-full text-center text-5xl appearance-none focus:outline-none"
                    value={hour}
                    onChange={handleHourChange}
                    aria-label="Hour input"
                    onKeyDown={handleBackspaceHour}
                  />
                </div>
                <p className=" ml-1 text-white text-opacity-70">Hours</p>
              </div>

              <div className="mr-2 mt-1">
                <p className="text-white text-opacity-70 text-5xl">:</p>
              </div>

              <div className="flex flex-col ">
                <div
                  className={`bg-transparent w-20 h-16 text-white rounded-md border-[2px] ${error && minute === "" ? "border-red-500" : "border-white border-opacity-70"}`}
                >
                  <input
                    type="number"
                    className="w-full text-white text-opacity-70 rounded-md h-full text-center text-5xl bg-transparent appearance-none focus:outline-none"
                    value={minute}
                    onChange={handleMinuteChange}
                    aria-label="Minute input"
                    onKeyDown={handleBackspaceMinute}
                  />
                </div>
                <p className="text-white text-opacity-70 ml-1 ">Minutes</p>
              </div>
            </div>
            <div className="flex justify-end mr-2 mt-5">
              <button
                className="text-white text-opacity-70 text-xs border px-2 py-1 rounded-lg"
                onClick={handleCancel}
              >
                CANCEL
              </button>
              <button
                className="ml-5 text-white text-opacity-70 text-xs border px-2 py-1 rounded-lg"
                onClick={handleOk}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JournalDurationInput;
