import { max, maxBy, min, minBy } from "lodash";
import {
  ChartXAxis,
  ProcessedData,
  RawData,
  RawDataItem,
} from "./ICustomChart";

export const processData = (
  rawData: RawData,
  processChartType: any,
  seriesTitle: Array<string> | undefined,
  minY: number | undefined,
  maxY: number | undefined,
): {
  data: ProcessedData[];
  xaxis: ChartXAxis[];
  minValue: number;
  maxValue: number;
} => {
  let data: ProcessedData[] = [];
  let xaxis: ChartXAxis[] = [];

  const handleArray = (
    array: RawDataItem[],
    key: string,
    chartType: string,
    seriesTitle: string | undefined,
  ): void => {
    let labels: string[] = [];
    let values: number[] = [];
    array.forEach(({ label, value }) => {
      labels.push(label);
      values.push(value);
    });

    data.push({
      name: seriesTitle ? seriesTitle : formatString(key),
      type: chartType,
      data: values,
    });

    xaxis.push({
      type: "category",
      categories: labels,
    });
  };
  let index = 0;
  if (Array.isArray(rawData)) {
    handleArray(
      rawData,
      "data",
      processChartType ? processChartType[index] : "line",
      undefined,
    );
  } else {
    for (let key in rawData) {
      handleArray(
        rawData[key],
        key,
        processChartType ? processChartType[index] : "line",
        seriesTitle ? seriesTitle[index] : undefined,
      );
      index++;
    }
  }
  let minValue = minY;
  let maxValue = maxY;

  // If minY not comming by default then it calculate from lodash function minBy
  // Subtract -1 from min value to show space from bottom of chart

  if (minValue === undefined) {
    const minObject = minBy(data, (obj) => min(obj.data));
    minValue = minObject ? (min(minObject.data) as number) : 0;
    if (minValue > 1) minValue -= 1;
  }

  // If maxY not comming by default then it calculate from lodash function maxBy
  // Add +1 in max value to show space from top of chart

  if (maxValue === undefined) {
    const maxObject = maxBy(data, (obj) => max(obj.data));
    maxValue = (maxObject ? (max(maxObject.data) as number) : 0) + 1;
  }
  return { data, xaxis, minValue, maxValue };
};

const formatString = (inputString: string): string => {
  return inputString
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const getSeriesDataOpacity = (series: any) => {
  return series.map((s: { type: string }) => (s.type === "area" ? 0.35 : 1));
};

export const isValidChartData = (data: any): data is RawData => {
  if (Array.isArray(data)) {
    return data.every(
      (item) =>
        typeof item.label === "string" && typeof item.value === "number",
    );
  }

  if (typeof data === "object" && data !== null) {
    return Object.values(data).every(
      (value) =>
        Array.isArray(value) &&
        value.every(
          (item) =>
            typeof item.label === "string" && typeof item.value === "number",
        ),
    );
  }

  return false;
};

export const isNumberArray = (arr: any): arr is number[] => {
  if (!Array.isArray(arr)) {
    return false;
  }
  return arr.every((item) => typeof item === "number");
};
