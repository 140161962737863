import { FC } from "react";

interface IProgressBar {
  percentage: number;
}

const ProgressBar: FC<IProgressBar> = ({ percentage }) => {
  return (
    <div className="w-full my-2 px-[1rem] text-lg">
      {percentage}%
      <div className="h-1 w-full rounded-md overflow-hidden mt-1.5 bg-lightGray1">
        <div
          className={`h-1 rounded-md ${percentage === 100 ? "bg-green" : "bg-themeBlue"}`}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
