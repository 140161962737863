import Loader from "@components/Loader";
import { join, slice } from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IFullData {
  premium?: boolean;
  url_path?: string;
  id?: number;
}

interface ISleepSoundsCard {
  id?: number;
  width?: number;
  height?: number;
  title: string;
  cardType?: string;
  logo?: string;
  bgImage?: string;
  content?: string;
  onClick?: (content?: object) => void;
  testId: string;
  fullData?: IFullData;
}

const SleepCard: FC<ISleepSoundsCard> = ({
  width = 200,
  height = 200,
  title = "Sleep Sounds",
  cardType = "sound",
  logo,
  bgImage,
  onClick,
  content,
  id,
  testId,
  fullData = {},
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleCardClick = useCallback(() => {
    if (onClick) {
      if (cardType === "sound") {
        onClick({ content, title, fullData });
      } else if (cardType === "sleep") {
        const urlPath = join(slice(fullData.url_path, 1, -1), "");
        navigate(`/content/?link=${urlPath}`, {
          state: { from: "Sleep Home", itemId: fullData?.id },
        });
      }
    }
  }, [fullData, onClick, cardType, title, content, navigate]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      onClick={handleCardClick}
      data-testid={`${testId}-${id}`}
      className={`mx-1 bg-darkGray2 p-4 relative cursor-pointer rounded-lg shadow mb-2 mt-2 min-w-[${width}px] min-h-[${height}px] max-w-[300px] max-h-[300px] flex justify-around items-center flex-col p-4`}
    >
      {/*
       Commented For the time being
       {fullData.premium && <FaLock className="absolute top-3 right-3 text-lightGray1" />} 
       */}

      <div className="max-h-20 min-h-12" style={{ minHeight: "60px" }}>
        {isLoading && <Loader />}
        <img
          src={cardType === "sound" ? logo : bgImage}
          alt={title}
          onLoad={handleImageLoad}
          style={{
            width: "80px",
            height: "auto",
            marginBottom: "10px",
            display: isLoading ? "none" : "block",
          }}
          className={`object-contain ${cardType === "sound" ? "custom-white mb-10 " : ""}`}
        />
      </div>
      <p className="text-sm text-center sm:text-base">{title}</p>
    </div>
  );
};

export default SleepCard;
