/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FC, useEffect } from "react";
import AccordionContainer from "./AccordionContainer";
import VerticalTabs, { ITab } from "./VerticalTabs";
import { getQuestions } from "@pages/Sleep/SettingsPage";
import { useUser, useUpdateUserSettings } from "@backend/hooks/useUser";

export interface IQuestionData {
  choices: ITab[];
  id: string;
  text: string;
  type: string;
}

interface IAgeSelectionProps {
  data: IQuestionData;
}

const AgeSelection: FC<IAgeSelectionProps> = ({ data }) => {
  const { refetch, data: { body: { settings } } = { body: { settings: {} } } } =
    useUser();

  const mutateUserSettings = useUpdateUserSettings(refetch);

  const tabs = data.choices;
  // const selectedRange = tabs.find((item) => item.id === settings?.age_range)

  const [selectedTab, setSelectedTab] = useState(settings?.age_range || "");

  let questions = getQuestions(data, 2);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const updateUserAge = async () => {
    const apiData = { ...settings, age_range: selectedTab };

    await mutateUserSettings.mutate(apiData);
  };

  useEffect(() => {
    if (selectedTab !== settings.age_range) {
      updateUserAge();
    }
  }, [selectedTab]);

  return (
    <AccordionContainer>
      {questions && Array.isArray(questions) && questions.length >= 2 && (
        <>
          <p
            className="small-white-text text-base sm:text-xl"
            data-testid="quesiton1"
          >
            {questions[1]}
          </p>
          <p className="small-white-text text-sm" data-testid="quesiton2">
            {questions[0] + "."}
          </p>
        </>
      )}
      <div className="flex flex-col items-center">
        <VerticalTabs
          tabs={tabs}
          selected={selectedTab}
          onChange={handleTabChange}
        />
      </div>
    </AccordionContainer>
  );
};

export default AgeSelection;
