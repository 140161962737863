import React from "react";

const icons: { [key: string]: JSX.Element } = {
  zzz: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56">
      <path d="M10.492 26.875h14.906c1.125 0 1.758-.61 1.758-1.64c0-1.032-.633-1.618-1.758-1.618H12.883v-.094l13.125-15.82c.75-.89.937-1.43.937-2.133c0-1.289-.89-2.086-2.437-2.086H9.93c-1.125 0-1.758.586-1.758 1.618c0 1.054.633 1.64 1.758 1.64h12.492v.094L8.992 23.055c-.539.633-.68 1.101-.68 1.78c0 1.22.868 2.04 2.18 2.04m23.813 12.61h11.812c1.102 0 1.711-.563 1.711-1.594c0-.961-.61-1.547-1.71-1.547h-9.61v-.094l10.031-12.094c.797-.96 1.055-1.5 1.055-2.156c0-1.266-.844-2.039-2.344-2.039H33.742c-1.078 0-1.71.586-1.71 1.57c0 1.008.632 1.57 1.71 1.57h9.586v.094l-10.312 12.47c-.61.726-.82 1.194-.82 1.874c0 1.148.82 1.945 2.109 1.945m-16.922 13.03h9.469c1.007 0 1.593-.562 1.593-1.476c0-.937-.586-1.453-1.593-1.453h-7.407v-.094l7.782-9.469c.75-.914.984-1.476.984-2.156c0-1.125-.773-1.758-2.04-1.758h-9.374c-1.008 0-1.57.54-1.57 1.453c0 .938.562 1.477 1.57 1.477h7.523v.07l-8.156 9.844c-.562.703-.75 1.102-.75 1.758c0 1.055.774 1.805 1.969 1.805" />
    </svg>
  ),
  sound: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M19 11.975q0-2.075-1.1-3.787t-2.95-2.563q-.375-.175-.55-.537t-.05-.738q.15-.4.538-.575t.787 0Q18.1 4.85 19.55 7.063T21 11.974q0 2.7-1.45 4.913t-3.875 3.287q-.4.175-.788 0t-.537-.575q-.125-.375.05-.737t.55-.538q1.85-.85 2.95-2.562t1.1-3.788M7 15H4q-.425 0-.712-.288T3 14v-4q0-.425.288-.712T4 9h3l3.3-3.3q.475-.475 1.088-.213t.612.938v11.15q0 .675-.612.938T10.3 18.3zm9.5-3q0 1.05-.475 1.988t-1.25 1.537q-.25.15-.513.013T14 15.1V8.85q0-.3.263-.437t.512.012q.775.625 1.25 1.575t.475 2"></path>
    </svg>
  ),
  person: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 12q-1.65 0-2.825-1.175T8 8q0-1.65 1.175-2.825T12 4q1.65 0 2.825 1.175T16 8q0 1.65-1.175 2.825T12 12m-8 8v-2.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20z"></path>
    </svg>
  ),
  graph: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="m3.5 18.5l6-6l4 4L22 6.92L20.59 5.5l-7.09 8l-4-4L2 17z"></path>
    </svg>
  ),
  lightbulb: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 6a6 6 0 0 1 6 6c0 2.22-1.21 4.16-3 5.2V19a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-1.8c-1.79-1.04-3-2.98-3-5.2a6 6 0 0 1 6-6m2 15v1a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1zm6-10h3v2h-3zM1 11h3v2H1zM13 1v3h-2V1zM4.92 3.5l2.13 2.14l-1.42 1.41L3.5 4.93zm12.03 2.13l2.12-2.13l1.43 1.43l-2.13 2.12z"></path>
    </svg>
  ),
  clipboard: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M21.04 12.13c.14 0 .27.06.38.17l1.28 1.28c.22.21.22.56 0 .77l-1 1l-2.05-2.05l1-1c.11-.11.25-.17.39-.17m-1.97 1.75l2.05 2.05L15.06 22H13v-2.06zM19 3c1.1 0 2 .9 2 2v4L11 19v2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2h4.18C9.6 1.84 10.7 1 12 1c1.3 0 2.4.84 2.82 2zm-7 0c-.55 0-1 .45-1 1s.45 1 1 1s1-.45 1-1s-.45-1-1-1" />
    </svg>
  ),
  wavy: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M17 16.99c-1.35 0-2.2.42-2.95.8c-.65.33-1.18.6-2.05.6c-.9 0-1.4-.25-2.05-.6c-.75-.38-1.57-.8-2.95-.8s-2.2.42-2.95.8c-.65.33-1.17.6-2.05.6v1.95c1.35 0 2.2-.42 2.95-.8c.65-.33 1.17-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.42 2.95-.8c.65-.33 1.18-.6 2.05-.6c.9 0 1.4.25 2.05.6c.75.38 1.58.8 2.95.8v-1.95c-.9 0-1.4-.25-2.05-.6c-.75-.38-1.6-.8-2.95-.8m0-4.45c-1.35 0-2.2.43-2.95.8c-.65.32-1.18.6-2.05.6c-.9 0-1.4-.25-2.05-.6c-.75-.38-1.57-.8-2.95-.8s-2.2.43-2.95.8c-.65.32-1.17.6-2.05.6v1.95c1.35 0 2.2-.43 2.95-.8c.65-.35 1.15-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.43 2.95-.8c.65-.35 1.15-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.58.8 2.95.8v-1.95c-.9 0-1.4-.25-2.05-.6c-.75-.38-1.6-.8-2.95-.8m2.95-8.08c-.75-.38-1.58-.8-2.95-.8s-2.2.42-2.95.8c-.65.32-1.18.6-2.05.6c-.9 0-1.4-.25-2.05-.6c-.75-.37-1.57-.8-2.95-.8s-2.2.42-2.95.8c-.65.33-1.17.6-2.05.6v1.93c1.35 0 2.2-.43 2.95-.8c.65-.33 1.17-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.43 2.95-.8c.65-.32 1.18-.6 2.05-.6c.9 0 1.4.25 2.05.6c.75.38 1.58.8 2.95.8V5.04c-.9 0-1.4-.25-2.05-.58M17 8.09c-1.35 0-2.2.43-2.95.8c-.65.35-1.15.6-2.05.6s-1.4-.25-2.05-.6c-.75-.38-1.57-.8-2.95-.8s-2.2.43-2.95.8c-.65.35-1.15.6-2.05.6v1.95c1.35 0 2.2-.43 2.95-.8c.65-.32 1.18-.6 2.05-.6s1.4.25 2.05.6c.75.38 1.57.8 2.95.8s2.2-.43 2.95-.8c.65-.32 1.18-.6 2.05-.6c.9 0 1.4.25 2.05.6c.75.38 1.58.8 2.95.8V9.49c-.9 0-1.4-.25-2.05-.6c-.75-.38-1.6-.8-2.95-.8"></path>
    </svg>
  ),
  book: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="m19 2l-5 4.5v11l5-4.5zM6.5 5C4.55 5 2.45 5.4 1 6.5v14.66c0 .25.25.5.5.5c.1 0 .15-.07.25-.07c1.35-.65 3.3-1.09 4.75-1.09c1.95 0 4.05.4 5.5 1.5c1.35-.85 3.8-1.5 5.5-1.5c1.65 0 3.35.31 4.75 1.06c.1.05.15.03.25.03c.25 0 .5-.25.5-.5V6.5c-.6-.45-1.25-.75-2-1V19c-1.1-.35-2.3-.5-3.5-.5c-1.7 0-4.15.65-5.5 1.5V6.5C10.55 5.4 8.45 5 6.5 5"></path>
    </svg>
  ),
  "lightbulb-points": (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <path d="M176 232a8 8 0 0 1-8 8H88a8 8 0 0 1 0-16h80a8 8 0 0 1 8 8m40-128a87.55 87.55 0 0 1-33.64 69.21A16.24 16.24 0 0 0 176 186v6a16 16 0 0 1-16 16H96a16 16 0 0 1-16-16v-6a16 16 0 0 0-6.23-12.66A87.59 87.59 0 0 1 40 104.49C39.74 56.83 78.26 17.14 125.88 16A88 88 0 0 1 216 104m-32.11-9.34a57.6 57.6 0 0 0-46.56-46.55a8 8 0 0 0-2.66 15.78c16.57 2.79 30.63 16.85 33.44 33.45A8 8 0 0 0 176 104a9 9 0 0 0 1.35-.11a8 8 0 0 0 6.54-9.23"></path>
    </svg>
  ),
  "lightbulb-flower": (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M189.228,166.95h-16.696v16.696c0,27.62,22.468,50.088,50.088,50.088h16.696v-16.696
     C239.316,189.419,216.848,166.95,189.228,166.95z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M373.536,42.508C266.754-46.472,99.182,11.12,75.128,151.303c-11.522,67.208,13.001,132.665,65.588,175.096
     c20.218,16.315,31.816,41.365,31.816,68.729v33.392c0,27.62,22.468,50.088,50.088,50.088h16.696v16.696
     c0,9.223,7.478,16.696,16.696,16.696c9.217,0,16.696-7.473,16.696-16.696v-16.696h16.696c27.62,0,50.088-22.468,50.088-50.088
     v-33.392v-0.006c0-0.002,0-0.003,0-0.003c0-27.296,11.685-52.416,32.055-68.916c43.294-35.038,68.121-87.001,68.121-142.557
     C439.668,128.983,415.559,77.541,373.536,42.508z M306.1,428.52c0,9.206-7.49,16.696-16.696,16.696H222.62
     c-9.206,0-16.696-7.49-16.696-16.696v-16.696H306.1V428.52z M372.884,183.646c0,46.033-37.447,83.48-83.48,83.48h-16.696v111.306
     h-33.392V267.126H222.62c-46.034,0-83.48-37.447-83.48-83.48v-33.392c0-9.223,7.479-16.696,16.696-16.696h33.392
     c27.323,0,51.546,13.252,66.784,33.602c15.238-20.351,39.46-33.602,66.784-33.602h33.392c9.217,0,16.696,7.473,16.696,16.696
     V183.646z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M322.796,166.95c-27.62,0-50.088,22.468-50.088,50.088v16.696h16.696c27.62,0,50.088-22.468,50.088-50.088V166.95H322.796
     z"
          />
        </g>
      </g>
    </svg>
  ),
};

// * A functional component to return icon svg based on icon {name} in infinite challenges.
const ChallengeIcons = (props: React.SVGProps<SVGSVGElement> & { iconName: string | undefined }) => {
  // * Take out icon name and other props like width, height, classNames... etc
  const { iconName, ...others } = props;
  // * Define Custom Icon's against key's that are coming inside Today API.
  // * zzz, book, wave, sound, person, graph, lightbulb, clipboard

  // * If there is no icon name then return nothing / no icon.
  if (!iconName) return <></>;
  // * If there is icon name but not defined here return nothing / no icon instead of some error.
  if (!Object.keys(icons).includes(iconName)) return <></>;
  else {
    // * There is icon in defined icons and there is icon name in API data.
    // * Create a new Element and assign children and props to it and return.
    const Icon = React.createElement("svg", { height: "10px", width: "10px", color: "white", fill: "white", ...icons[iconName].props, ...others });
    return Icon;
  }
};

export default React.memo(ChallengeIcons);
