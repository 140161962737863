import React, { FC, useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { logout } from "@backend/auth_funcs";
import { useUser } from "@backend/hooks/useUser";
import { localGet, localPut } from "@backend/storage";
import { getLatestVersion } from "@backend/versions";
import {
  ISwitchAction,
  ISwitchReducer,
  SWITCH_STATES,
} from "@reducers/switchReducer";
import { clearLocalStorageLocationKeys } from "@components/Utils/CustomHooks/clearLocalStorageLocations";
import { isUserAdmin } from "@components/Utils/Functions";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";

interface TopRightUserMenuProps {
  dispatch: React.Dispatch<ISwitchAction>;
  dispatchMucicState: React.Dispatch<IMusicAction>;
  state: ISwitchReducer;
}

const TopRightUserMenu: FC<TopRightUserMenuProps> = ({
  dispatch,
  dispatchMucicState,
  state,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const email = localGet("user").email || "";
  const container = useRef<HTMLDivElement>(null);
  const { data } = useUser();

  const userStatus = data?.body?.user?.staff_status;

  const group = data?.body?.group ?? null;
  const menuRef = useRef<HTMLDivElement>(null);

  const handleDropdown = () => setIsOpen(prev=>!prev);

  const handleLogout = async () => {
    try {
      await logout(queryClient);
      window.history.replaceState(null, "", "/");
      clearLocalStorageLocationKeys();
      window.location.reload();
    } catch (err) {
      console.error("Logout Error", err);
    }
  };

  const handleSwitchPortal = () => {
    const switchType = state.switchToSleepToday
      ? SWITCH_STATES.SWITCH_TO_LEADERSHIP
      : SWITCH_STATES.SWITCH_TO_SLEEP;
    const navigateTo = state.switchToSleepToday ? "/dashboard" : "/sleep-today";
    const switchTo = !state.switchToSleepToday;

    dispatch({ type: switchType });
    dispatchMucicState({
      type: MUSIC_STATES.IS_MINI_PLAYER,
      payload: { isMiniPlayer: true },
    });
    navigate(navigateTo);
    localPut("sleep-portal", switchTo);
    setIsOpen(false);
    clearLocalStorageLocationKeys();
  };

  const isAdmin = isUserAdmin(
    data?.body?.group?.admin_emails,
    data?.body?.user?.email,
  );

  const showSwitchOption = () => {
    if (
      userStatus === "staff" ||
      (userStatus !== "staff" && group.dash_enabled && isAdmin)
    ) {
      return (
        <div
          onClick={handleSwitchPortal}
          className="px-4 py-2 cursor-pointer hover:bg-themeBlue"
          data-testid="switch-sleep"
        >
          {state.switchToSleepToday
            ? "Switch To Leadership"
            : "Switch To Chorus Sleep"}
        </div>
      );
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !(container.current as HTMLDivElement).contains(event.target as Node)) {
      setIsOpen(prev=> prev===true ? false : prev);
    }
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div className="relative z-10" data-testid="user-menu" ref={container}>
      <button
        onClick={handleDropdown}
        className="p-2 rounded-full bg-themeBlue focus:outline-none"
        data-testid="user-menu-button"
        aria-label="top-right-user-menu"
      >
        <AiOutlineUser className="text-white" />
      </button>
      {isOpen && (
        <div
          className="absolute right-0 w-56 mt-2 border border-white border-opacity-5 bg-darkGray2"
          data-testid="dropdown"
          ref={menuRef}
        >
          <div className="px-4 py-2">
            Logged in as: <br />
            <span className="pl-2 text-xs italic">{email}</span>
          </div>

          {showSwitchOption()}

          <div
            onClick={getLatestVersion}
            className="px-4 py-2 cursor-pointer hover:bg-themeBlue"
          >
            Get Latest Version
          </div>
          <div
            onClick={handleLogout}
            className="px-4 py-2 cursor-pointer hover:bg-themeBlue"
            data-testid="logout-button"
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default TopRightUserMenu;
