/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import SleepHistoryChart from "./SleepHistoryChart";
import SleepHistoryTab from "./SleepHistoryTab";
import { ISleepHistoryChartCard, ChartData } from "./SleepHistoryInterfaces";

const SleepHistoryChartCard: FC<ISleepHistoryChartCard> = (props) => {
  const { title1 = "", title2, data, graphType, min, max, yAxisUnit } = props;
  const [activeTab, setActiveTab] = useState("Daily");

  const [chartData, setChartData] = useState<ChartData | null>(null);

  const formatChartData = () => {
    const currentActiveChart = data[activeTab.toLocaleLowerCase()];
    let series: number[] = [];
    let labels: string[] = [];
    let reversedArr = [...currentActiveChart].reverse().slice(-7);

    reversedArr.forEach((element) => {
      labels.push(element.label);
      if (graphType === "hourGraph") {
        series.push(Number((element.point / 60).toFixed(2)));
      } else {
        series.push(element.point);
      }
    });

    let formattedChartData = {
      labels,
      seriesData: [{ name: title1, data: series }],
    };
    setChartData(formattedChartData);
  };

  useEffect(() => {
    formatChartData();
  }, [activeTab]);

  return (
    <div className="mb-5">
      <p className="text-base sm:text-xl text-yellowC1">{title1}</p>
      <p className="text-base sm:text-xl mt-1 text-yellowC1">{title2}</p>
      <SleepHistoryChart
        data={chartData}
        min={min}
        max={max}
        yAxisUnit={yAxisUnit}
      />
      <SleepHistoryTab
        onChange={(tab: string) => setActiveTab(tab)}
        activeTab={activeTab}
      />
    </div>
  );
};

export default SleepHistoryChartCard;
