import React, { FC } from "react";

export interface ITab {
  id: string;
  text: string;
  type?: string;
}

interface IVerticalTabsProps {
  tabs: ITab[];
  selected: string;
  onChange: (tab: string) => void;
}

const VerticalTabs: FC<IVerticalTabsProps> = ({ tabs, selected, onChange }) => {
  return (
    <div>
      {tabs.map((tab: any, index: number) => (
        <TabButton
          key={index}
          id={tab.id}
          name={tab.text}
          selected={tab.id === selected}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

export default VerticalTabs;

interface ITabButtonProps {
  id: string;
  name: string;
  selected: boolean;
  onChange: (name: string) => void;
}

export const TabButton: FC<ITabButtonProps> = ({
  id,
  name,
  selected,
  onChange,
}) => {
  const buttonClass = `flex cursor-pointer my-3 justify-center items-center border w-full min-h-[50px] min-w-[150px] sm:min-w-[200px] max-w-[280px] p-2 rounded-lg ${selected ? "bg-darkPurpleC2" : "bg-inactiveMenu"}`;
  return (
    <div className={buttonClass} onClick={() => onChange(id)} data-testid={id}>
      <p
        className={`sm:text-base text-sm font-light text-center ${selected ? "text-opacity-100" : "text-opacity-70"}`}
      >
        {name}
      </p>
    </div>
  );
};
