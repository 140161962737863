import React, { ComponentType, useEffect } from 'react';
import { useUser } from '@backend/hooks/useUser';
import DashboardComponents from '@components/Dashboard/DashboardComponents';
import KeyInsights from '@components/Dashboard/KeyInsights';
import Container from '@components/Container';
import { anaScreen } from '@backend/ana';
import useStats from '@backend/hooks/useStats';
import { Loading } from '@components/Utils/Loading';
import { CloseOutlined } from '@ant-design/icons';

interface DashboardProps {
  collapsed?: boolean;
}

const Dashboard: React.FC<DashboardProps> = ({ collapsed }) => {
  useEffect(() => {
    anaScreen('leadership_dashboard', null);
  }, []);
  const [filters, setFilters] = React.useState<{ [key: string]: string }>({});
  const [dataFilters, setDataFilters] = React.useState<null | Array<object>>(null);
  const {
    data: {
      body: {
        group: { dash_charts_enabled, insights },
      },
    },
  } = useUser();

  /**
   *
   * @param key the key of the filter to be cleared.
   */
  function clearFilter(key: string) {
    // * Creating a deep copy so that a new reference is created for this temp object.
    let temp = JSON.parse(JSON.stringify(filters));
    // * Deleting the key + value from filters;
    delete temp[key];
    // * Updating the filters
    setFilters(temp);
  }
  // * useStats taking filters object so that it will change based on filter state change
  const { data: statsData, isLoading } = useStats(filters);

  // * This useEffect will track if filters from API have been changed to stop loader. Basically we are storing the filters at first API response then checking if the filters have been changed in API response then adding new filters.
  useEffect(() => {
    if (
      //dataFilters === null &&
      statsData &&
      JSON.stringify(statsData.body.filter) !== JSON.stringify(dataFilters)
    ) {
      setDataFilters(statsData.body.filters);
    }
  }, [statsData]);

  return (
    <Container collapsed={collapsed}>
      <div id="dashboardPage">
        <h1 className="py-6 text-3xl font-medium ">Dashboard</h1>
        <>
          <div className="mb-5">
            <h1 className="mb-4 text-xl">Key Insights</h1>
            {insights.map((item: any, index: React.Key | null | undefined) => {
              return <KeyInsights data={item} key={index} />;
            })}
          </div>
          {dataFilters ? (
            <>
              <h2 className="my-2 mt-4 text-xl">Filters</h2>
              <div className="relative mb-4 flex w-full flex-col gap-5 py-3 md:items-center lg:flex-row lg:gap-12">
                {dataFilters?.map((filter: any, index: number) => {
                  const { label, id, values } = filter;
                  return (
                    <div className="mt-1 grid w-full grid-cols-1 items-center gap-3 lg:grid-cols-4" key={`filter-${index}`}>
                      <div className="col-span-1 flex w-full items-center justify-between sm:col-span-4 md:col-span-4">
                        <label htmlFor={id}>{label}</label>
                        {filters[id] ? (
                          <button className="flex items-center gap-2 rounded bg-gray-700 px-3 py-1 text-sm" onClick={() => clearFilter(id)}>
                            <CloseOutlined />
                            Clear
                          </button>
                        ) : null}
                      </div>
                      <select
                        name={id}
                        id={id}
                        className="col-span-1 w-full rounded-lg border border-gray-600 bg-gray-800 p-3 indent-0.5 text-sm text-white placeholder-gray-400 transition-all placeholder:font-medium  placeholder:leading-normal focus:border-2 focus:border-blue-500 sm:col-span-4 md:col-span-4 md:text-base"
                        // className="col-span-1 w-full rounded-md border bg-transparent px-4 py-2 sm:col-span-4 md:col-span-2"
                        value={filters[id] || 'default'}
                        onChange={(e) => {
                          setFilters((prev) => {
                            return { ...prev, [id]: e.target.value };
                          });
                        }}
                      >
                        <option disabled key={'default'} value="default">
                          Select an Option
                        </option>
                        {values.map((option: any, index: number) => {
                          return (
                            <option key={index} value={option} className="p-2">
                              {option}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
          {isLoading ? (
            <div className="grid h-[10vh] place-items-center">
              <Loading />
            </div>
          ) : (
            <Boxes dashChartsEnabled={dash_charts_enabled} statsData={statsData} />
          )}
        </>
      </div>
    </Container>
  );
};

interface BoxProps {
  Component: ComponentType<any>;
  props?: any;
  space: string;
  additionalInformation?: string;
  xAxisTitle?: string;
}

interface BoxesProps {
  dashChartsEnabled: any;
  statsData: any;
}

/**
 *
 * @returns Graph's based on stats data for Leadership Dashboard.
 */
const Boxes: React.FC<BoxesProps> = ({ statsData, dashChartsEnabled }) => {
  const components: BoxProps[] = DashboardComponents({ statsData });
  const filteredComponents = dashChartsEnabled ? components.slice(0, 20) : components.slice(0, 2);

  return (
    <div className={`grid grid-cols-1 gap-5 lg:grid-cols-2`}>
      {filteredComponents.map((box, index) => (
        <div className={box.space} key={index}>
          <div className={`flex h-full w-full min-w-[200px] flex-col rounded-md bg-darkGray2 ${index === 0 && 'justify-center'} items-center pb-5`}>
            <box.Component {...box.props} />
            {box.xAxisTitle && <p className="mt-5 text-center text-sm">{box.xAxisTitle}</p>}
            {box.additionalInformation && <p className="mt-5 text-center text-sm">{box.additionalInformation}</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
