import { FC, Fragment, useState } from "react";
import { BiBed } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import JournalDurationInput from "./JournalDurationInput";
import { TimePicker } from "react-ios-time-picker";
import { handleOpenTimePicker as handleOpenReactIosTimePicker } from "@components/Utils/TimePicker";

interface INapTimeCard {
  napTime: string;
  asleepDuration: string;
  setNapTime: (value: string) => void;
  setAsleepDuration: (value: string) => void;
}

const NapTimeCard: FC<INapTimeCard> = ({
  napTime,
  asleepDuration,
  setNapTime,
  setAsleepDuration,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const [showDurationModel, setShowDurationModel] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const onChangeNapTime = (time: string) => {
    setNapTime(time);
  };

  const handelDurationChange = (hour: string, minutes: string) => {
    setAsleepDuration(`${hour} hr ${minutes} min`);
  };

  const handleOpenTimePicker = () => {
    const inputElement = document.getElementById("napTime") as HTMLElement;
    inputElement?.click();
    handleOpenReactIosTimePicker();
  };

  return (
    <Fragment>
      <JournalDurationInput
        onTimeChange={handelDurationChange}
        showModal={showDurationModel}
        handelClose={() => setShowDurationModel(false)}
      />

      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div className={`flex items-center`}>
            <BiBed className="text-base sm:text-2xl" />
            <p className={`ml-2 text-base sm:text-lg md:text-xl`}>Nap Time</p>
          </div>

          <label className="relative inline-flex cursor-pointer items-center">
            <input
              type="checkbox"
              value=""
              className="peer sr-only"
              checked={isChecked}
              onChange={handleToggle}
            />
            <div
              className={`peer h-6 w-11  rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800 ${
                isChecked ? "peer-checked:bg-blue-600" : ""
              }`}
            ></div>
          </label>
        </div>
        {isChecked && (
          <div className={`mt-2 rounded-md bg-darkGray2 p-4 `}>
            <div className="flex items-center justify-between">
              <div>
                <p>Nap Time</p>
                <TimePicker
                  onOpen={handleOpenReactIosTimePicker}
                  key={napTime}
                  value={napTime || "12:00 AM"}
                  onChange={onChangeNapTime}
                  use12Hours
                  inputClassName="text-base sm:text-2xl font-bold border-none pl-0"
                  id={"napTime"}
                  name={"napTime"}
                />
              </div>
              <FiEdit2
                className="cursor-pointer text-lg sm:text-xl"
                onClick={handleOpenTimePicker}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <p className="mt-3">Asleep Duration</p>
                <p className="text-base font-bold sm:text-lg md:text-2xl">
                  {asleepDuration}
                </p>
              </div>
              <FiEdit2
                className="cursor-pointer text-lg sm:text-xl"
                onClick={() => setShowDurationModel(true)}
              />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default NapTimeCard;
