/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import supportLogo from "@assets/logos/cbimage.png";
import ChorusLogo from "@assets/logos/chorus-sleep-logo.svg";
import { ISwitchReducer } from "@reducers/switchReducer";
import { RouteItem } from "@routes/routes";
import CustomButton from "@components/CustomButton";
import { useToday, useUser } from "@backend/hooks/useUser";
import { DEFAULT_BODY } from "@pages/Sleep/SleepTodayPage";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";
import { getUrlWithoutParams, matchesPath } from "@components/Utils/Functions";

interface LeftMenuProps {
  collapsed: boolean;
  items: RouteItem[];
  state: ISwitchReducer;
  isMobile?: boolean;
  toggleCollapse: () => void;
  setMusicPlaying?: (action: IMusicAction) => void;
}

/**
 *
 * @description
 * This is the sidebar component to display links in the sidebar.
 */
const LeftMenu: FC<LeftMenuProps> = ({
  collapsed,
  items,
  state,
  isMobile,
  toggleCollapse,
  setMusicPlaying,
}) => {
  const location = useLocation();
  const pathName = location.pathname;

  const [prevActivePath, setPrevActivePath] = useState<string>("");
  const [leftMenuItems, setLeftMenuItems] = useState<RouteItem[]>([]);

  const { data: userData } = useUser();

  let giftEnabled = userData?.body?.group?.gifts_enabled;
  let userStaffStatus = userData?.body?.user?.staff_status;
  let daytimeEnabled = userData?.body?.group?.daytime_enabled;

  const [searchParams] = useSearchParams();
  const hiddenParam = searchParams.get("hide");

  const findActivePath = (item: RouteItem): boolean => {
    const anyMatchCurrentPath = items.some((item: RouteItem) =>
      matchesPath(item.path, pathName),
    );

    if (anyMatchCurrentPath) {
      return matchesPath(item.path, pathName);
    } else {
      return matchesPath(item.path, prevActivePath);
    }
  };

  const { data } = useToday();
  const body = data?.body || DEFAULT_BODY;

  useEffect(() => {
    const isActivePath = items.some(
      (item: RouteItem) => item.path === pathName,
    );

    if (isActivePath) {
      setPrevActivePath(pathName);
    }
  }, [pathName, items]);

  const getStyle = () => {
    if (collapsed) {
      if (isMobile) {
        return "hidden";
      } else {
        return "block w-16 min-w-16";
      }
    } else {
      return "w-60 max-w-64";
    }
  };
  useEffect(() => {
    getStyle();
  }, [collapsed, isMobile]);

  const handleToggleCollapse = () => {
    if (isMobile) {
      toggleCollapse();
    }
  };

  useEffect(() => {
    const newItemsList = giftEnabled
      ? items
      : items.filter((obj: any) => obj.label !== "Claim Your Gift");

    let itemsList = daytimeEnabled
      ? newItemsList
      : newItemsList.filter((obj: any) => obj.label !== "Daytime Support");
    itemsList =
      userStaffStatus === "staff"
        ? itemsList
        : itemsList.filter((obj: any) => obj.label !== "All Lessons");
        // itemsList=itemsList.filter((a)=>{
        //   if(!daytime_status){
        //     return a.path!=='/daytime-support'
        //   }
        //   else{
        //     return a
        //   }
        // })
    setLeftMenuItems(itemsList);
  }, [items, giftEnabled, userStaffStatus]);

  return (
    <div
      data-testid="left-menu"
      className={`${hiddenParam === "true" ? "hidden" : "fixed"} z-[3] h-[100%] border-r border-white border-opacity-5 bg-darkGray2 transition-all duration-200 ${getStyle()} overflow-auto`}
    >
      <div
        className={`flex  ${collapsed ? "justify-center pt-[60px]" : "justify-between p-4"} items-center text-white`}
      >
        <div className={`font-bold  ${collapsed ? "" : "pl-3"}`}>
          {!collapsed && (
            <img src={ChorusLogo} className="" alt="Chorus Sleep" />
          )}
        </div>
      </div>
      {!collapsed && (
        <div
          className={`ml-4 ${collapsed ? "mt-12" : "mt-4"} pl-3 text-xs opacity-50`}
        >
          {state.switchToSleepToday ? (
            <>
              DEEPER, MORE <br /> RESTFUL SLEEP
            </>
          ) : (
            "Leadership Portal"
          )}
        </div>
      )}

      <ul className={`pt-4 text-white `}>
        {leftMenuItems &&
          leftMenuItems.map((item: RouteItem) => {
            return (
              <MenuItem
                setMusicPlaying={setMusicPlaying}
                onClick={handleToggleCollapse}
                active={findActivePath(item)}
                icon={item.icon}
                isNotification={item?.isNotification}
                label={item.label}
                collapsed={collapsed}
                link={item.path}
                key={item.path}
                props={item.props}
                unReadMessages={body?.coach_unread}
              />
            );
          })}
      </ul>
      {!collapsed && <SupportCard />}
    </div>
  );
};

interface MenuItemProps {
  icon: any;
  label?: string;
  collapsed: boolean;
  link: string;
  active: boolean;
  onClick: () => void;
  props: any;
  isNotification?: boolean;
  unReadMessages: number;
  setMusicPlaying?: (action: IMusicAction) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  setMusicPlaying,
  icon: Icon,
  label,
  collapsed,
  link,
  active,
  onClick,
  props,
  isNotification,
  unReadMessages,
}) => {
  const handleClick = () => {
    setMusicPlaying &&
      setMusicPlaying({
        type: MUSIC_STATES.IS_MINI_PLAYER,
        payload: { isMiniPlayer: true },
      });

    // if (props.newPage) {
    //   mixpanel.track("[ana] screen: side_menu", { props: link.toString() });
    // } else {
    //   mixpanel.track("[ana] screen: side_menu", { props: "nil" });
    // }
  };
  const shouldShowNotification = isNotification && unReadMessages > 0;
  const { url, ...restOfProps } = props;
  const state = { ...restOfProps };

  return (
    <Link
      to={props.url ? getUrlWithoutParams(link) + props.url : link}
      state={state}
      target={props.newPage ? "_blank" : ""}
      onClick={handleClick}
    >
      <li
        className={`p-3  ${collapsed ? "pl-6" : "pl-7"} relative flex items-center text-sm font-light text-white text-opacity-70  ${active ? "bg-activeMenu" : ""}`}
        onClick={onClick}
      >
        <Icon className={`mr-2 h-4 w-4 ${active ? "text-themeBlue" : ""}`} />
        {!collapsed && <span className="">{label}</span>}
        {shouldShowNotification && (
          <div className="absolute right-5">
            <span className="h-full px-2 py-1 text-xs font-bold text-white rounded-full bg-journal-red">
              {unReadMessages}
            </span>
          </div>
        )}
        {active && (
          <div className="absolute right-0 top-0 h-full w-[3px] bg-themeBlue"></div>
        )}
      </li>
    </Link>
  );
};

const SupportCard = () => {
  const openMail = () => {
    window.open("mailto:support@chorussleep.com", "_blank");
  };

  return (
    <div className="flex justify-center">
      <div className="inline-block p-4 mt-20 text-center rounded-md bg-lightGray8 ">
        <img
          src={supportLogo}
          alt="Chorus Sleep support"
          className="mx-auto h-28"
        />
        <h2 className="mt-4 text-lg font-medium">Need Assistance?</h2>
        <p className="text-sm font-light">We're always happy to help.</p>
        <CustomButton onClick={openMail}>Contact Support</CustomButton>
      </div>
    </div>
  );
};

export default LeftMenu;
