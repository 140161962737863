import Loader from "@components/Loader";
import { join, slice } from "lodash";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IFullData {
  premium?: boolean;
  url_path?: string;
  id?: string;
  text_color?: string;
  text_position?: string;
}

interface IDayCard {
  id?: string;
  width?: number;
  height?: number;
  title: string;
  cardType?: string;
  logo?: string;
  bgImage?: string;
  content?: string;
  textColor?: string;
  textPosition?: string;
  onClick?: (content?: object) => void;
  testId: string;
  fullData?: IFullData;
}

const DayCard: FC<IDayCard> = ({
  title = "Day Sounds",
  cardType = "sound",
  logo,
  bgImage,
  onClick,
  content,
  id,
  testId,
  fullData = {},
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const handleCardClick = useCallback(() => {
    if (onClick) {
      if (cardType === "sound") {
        onClick({ content, title, fullData });
      } else if (cardType === "day_essentials") {
        const urlPath = join(slice(fullData.url_path, 1, -1), "");
        navigate(`/content/?link=${urlPath}`, {
          state: { from: "Daytime Support", itemId: fullData?.id },
        });
      }
    }
  }, [fullData, onClick, cardType, title, content, navigate]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div
      onClick={handleCardClick}
      data-testid={`${testId}-${id}`}
      className={`mx-1 rounded-md min-w-[200px] min-h-[200px] cursor-pointer`}
    >
      {isLoading && <Loader />}
      <div className={`flex rounded-md overflow-y-hidden items-center`}>
        <img
          src={cardType === "sound" ? logo : bgImage}
          alt={title}
          onLoad={handleImageLoad}
          style={{
            width: "200px",
            height: "200px",
            display: isLoading ? "none" : "block",
          }}
          className={` max-h-full w-full h-full object-cover rounded-md ${cardType === "sound" ? "custom-white mb-10 " : ""}`}
        />
        {!isLoading && (
          <span
            className="m-2 flex items-center text-sm font-semibold w-[190px] text-center"
            style={{
              position: "absolute",
              top: fullData.text_position === "top" ? "0px" : "150px",
              color: fullData.text_color === "dark" ? "black" : "white",
            }}
          >
            {" "}
            {title}
          </span>
        )}
      </div>
    </div>
  );
};

export default DayCard;
