import React, { FC } from "react";

interface CustomLinkProps {
  label: string;
  handleClick: React.MouseEventHandler;
  className?: string;
}
const CustomLink: FC<CustomLinkProps> = (props) => {
  const { label, handleClick, className = "" } = props;
  return (
    <span
      className={`text-white underline underline-offset-1 cursor-pointer ${className}`}
      onClick={handleClick}
    >
      {label}
    </span>
  );
};

export default CustomLink;
