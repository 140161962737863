import mixpanelLib from 'mixpanel-browser';
const isTesting = process.env.REACT_APP_TESTING;
// Initialize the analytics library (mixpanel)
export const anaInit = (): void => {
  const key = process.env.REACT_APP_MIXPANEL_KEY;
  // console log ana init with the first 4 characters of the key
  console.log(`***[ana.init] ${key?.slice(0, 4)} `);
  // if key is undefined or empty, we need to crash hard because this indicates an improper env setup!
  if (!key) {
    throw new Error('No mixpanel key found in env');
  }
  if (!isTesting) {
    mixpanelLib.init(process.env.REACT_APP_MIXPANEL_KEY);
  }
};

// Standard events such as button clicks
export const anaEvent = (name: string, props: any): void => {
  console.log(`***[ana.event] ${name} ${JSON.stringify(props, null, 2)} `);
  if (!isTesting) mixpanelLib.track(name, props);
};

export const anaScreen = (name: string, props: any): void => {
  console.log(`***[ana.screen] ${name} ${JSON.stringify(props, null, 2)}`);
  if (!isTesting) mixpanelLib.track('Loaded a Screen', { name, ...props });
};

export const anaIdentify = (id: string, email: string): void => {
  console.log(`***[ana.identify] ${email} ${id} `);
  if (!isTesting) mixpanelLib.identify(id, { email });
};

export const anaLogout = (): void => {
  console.log(`***[ana.logout] `);
  if (!isTesting) {
    anaEvent('logout', null);
    mixpanelLib.reset();
  }
};
