import { sleepEvent } from "@assets/staticData";
import { anaScreen } from "@backend/ana";
import { useToday, useUser } from "@backend/hooks/useUser";
import { localPut } from "@backend/storage";
import ChallengeScreen from "@components/Challenge/ChallengeScreen";
import Container from "@components/Container";
import { PastLesson, SleepCardSlider, SleepCoachCard, SleepEventCard, SleepScoreChart } from "@components/SleepToday";
import DownloadAppBanner from "@components/SleepToday/DownloadAppBanner";
import SleepSessionSlider from "@components/SleepToday/SessionSlider/SleepSessionSlider";
import { SLIDER_CONSTANT } from "@components/Utils/constants/ScrollConstant";
import useScrollPosition from "@components/Utils/CustomHooks/useScrollPosition";
import { Loading, LoadingPage } from "@components/Utils/Loading";
import { IMusicAction } from "@reducers/musicReducer";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { ChallengeData } from "types/challengeType";

// Generated by https://quicktype.io

export interface ChallengesType {
  completed_on: string;
  completed_text: string;
  created: Date;
  description: string;
  duration: string;
  name: string;
  points: number;
  points_daily: number;
  read_more_link: string;
  session_id: string;
  status: string;
  status_events: StatusEvent[];
  steps: Step[];
  title: string;
  type: "finite" | "infinite";
  user_id: string;
  updated: Date;
}

export interface StatusEvent {
  created: string;
  status: string;
  message: string;
}

export interface Step {
  action_type: string;
  can_mark_complete: boolean;
  parent_id: string;
  completed_on: string;
  created: string;
  description: string;
  duration: string;
  go_to_anchor: string;
  icon: string;
  id: string;
  name: string;
  order: number;
  points: number;
  points_daily: number;
  progress: number;
  progress_total: number;
  read_more_link: string;
  session_id: string;
  status: string;
  title: string;
  type: "infinite" | "finite";
  updated: string;
  user_id: string;
}

interface SleepTodayPageProps {
  collapsed?: boolean;
  setMusic?: (action: IMusicAction) => void;
  musicState?: any;
}

export const DEFAULT_BODY = {
  chart_data: [],
  sounds: [],
  essentials: [],
  sessions: [],
  current_lessons: [],
};

export const DEFAULT_USER = {
  body: {
    user: {
      staff_status: "",
    },
  },
};

const SleepTodayPage: React.FC<SleepTodayPageProps> = ({ collapsed, setMusic, musicState }) => {
  const { isLoading, data, isError, error } = useToday();
  const body = data?.body || DEFAULT_BODY;

  localPut("sleep-portal", true);

  const { data: dataUser = DEFAULT_USER, isError: userIsError, error: userError } = useUser();
  const [challenge, setChallenge] = useState<ChallengesType | undefined>();

  useScrollPosition(SLIDER_CONSTANT.SLEEP_TODAY_PAGE_POSITION);

  const { state } = useLocation();

  const [targetID, setTargetID] = useState("");

  // const targetRef = useRef<HTMLDivElement>(null);

  const scrollToTarget = useCallback((target?: string) => {
    target && setTargetID(target);
    const targetElement = target && document.getElementById(target);
    targetElement && targetElement?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  useEffect(() => {
    setChallenge(body?.challenge);
  }, [body]);

  useEffect(() => {
    anaScreen("today", null);
  }, []);

  useEffect(() => {
    if (state) {
      const { scrollToTargetID } = state;

      setTimeout(() => {
        scrollToTarget(scrollToTargetID);
      }, 1000);

      window.history.replaceState({}, scrollToTargetID);
    }
    //eslint-disable-next-line
  }, [state]);

  if (userError || userIsError || isError || error) {
    return (
      <div>
        <h1>Something went wrong or server error.</h1>
        {/* <p>{error?.message}</p> Display the error message */}
        {/* <button onClick={() => refetch()}>Retry</button> */}
      </div>
    );
  }

  if (isLoading) {
    return <LoadingPage />;
  }
  // console.log(challenge);
  return (
    <Container dataTestId="sleep-today-page" collapsed={collapsed}>
      {!isLoading ? (
        <>
          <DownloadAppBanner />
          {challenge && <ChallengeScreen className="mb-5" title={challenge.title} onScrollClick={scrollToTarget} challenge={challenge} />}

          <SleepScoreChart data={body.chart_data} />
          <div id="journals">
            <div className="mb-2 mt-2 flex items-center justify-between">
              <h3 className="text-base text-lightYellowC1_2 sm:text-lg" id="journals">
                Journals
              </h3>
              {/* <p> </p> */}
            </div>

            {sleepEvent.map((item, index) => (
              <div
                // ref={targetRef}
                key={index}
              >
                <SleepEventCard targetID={targetID} {...item} />
              </div>
            ))}
          </div>
          {/*
           * Changed Sleep container to contain Sleep Related things for border like sleep-sounds, sleep-sessions and essentials.
           */}
          <div
            id="sleep"
            // ref={targetRef}
          >
            <SleepSessionSlider musicState={musicState} setMusic={setMusic} data={body.sessions} collapsed={collapsed} targetID={targetID} />

            <SleepCardSlider setMusic={setMusic} data={body.sounds} subTitle="Sleep Sounds" cardType="sound" testId="sleep-sound-slider" height={300} scrollKey={SLIDER_CONSTANT.SLEEP_SOUND_SLIDER} />

            <div
            // ref={targetRef}
            >
              <SleepCardSlider
                data={body.essentials}
                testId="dive-deeper-slider"
                // title="Dive Deeper & Learn"
                title="Discover & Dive Deeper"
                subTitle="Sleep Essentials"
                cardType="sleep"
                scrollKey={SLIDER_CONSTANT.WEBVIEW_SLIDER}
                targetID={targetID}
              />
            </div>
          </div>

          <PastLesson lessons={body.current_lessons} />

          <SleepCoachCard />
        </>
      ) : (
        <Loading />
      )}
    </Container>
  );
};

export default SleepTodayPage;
