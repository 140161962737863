import { InfoCircleOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface IInfoButton {
  className?: string;
  contentUrl: any;
  from?: string;
}

const InfoButton: FC<IInfoButton> = ({ className, contentUrl, from }) => {
  const navigate = useNavigate();
  const handleMoreInfo = () => {
    navigate(contentUrl.path + contentUrl.url, { state: { from } });
  };
  return <InfoCircleOutlined onClick={handleMoreInfo} className={`cursor-pointer text-themeBlue ${className}`} data-testid="info-button-icon" />;
};

export default InfoButton;
