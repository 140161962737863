import { getAT, sendRQApiJson } from "./service";

export const apiGetCoachMessages = async (): Promise<any> => {
  return await sendRQApiJson("GET", "/coach/messages", getAT(), null);
};

export const apiUserSendCoachMessage = async (
  message: string,
): Promise<any> => {
  return await sendRQApiJson("POST", "/coach/messages", getAT(), { message });
};
