import { SendOutlined } from "@ant-design/icons";

import React, { FC } from "react";

const NoMessagePrompt: FC = () => {
  return (
    <div
      className={`w-full flex h-full items-center justify-center flex-col opacity-50`}
      data-testid="noMessagePrompt"
    >
      <SendOutlined
        style={{ fontSize: "40px", marginBottom: "20px", opacity: "50%" }}
      />
      <p className="text-center opacity-50">
        No Messages yet. <br />
        <span>Compose below to get started.</span>
      </p>
    </div>
  );
};

export default NoMessagePrompt;
