import React, { ReactNode } from "react";

interface GrayBoxProps {
  children: ReactNode;
}

const GrayBox: React.FC<GrayBoxProps> = ({ children }) => {
  return (
    <div className="bg-[#1E1E1E] border border-opacity-5 border-white rounded min-w-[100px] min-h-[100px] p-4">
      {children}
    </div>
  );
};

export default GrayBox;
