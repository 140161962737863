import { FC } from "react";

interface ISleepHistoryTab {
  activeTab: string;
  onChange: (value: string) => void;
}

const SleepHistoryTab: FC<ISleepHistoryTab> = ({ onChange, activeTab }) => {
  const classes =
    "px-2 py-1 rounded-lg min-w-[80px] w-full max-w-[100px] flex justify-center align-center cursor-pointer text-xs sm:text-base";

  const tabs = ["Daily", "Weekly", "Monthly"];

  return (
    <div className="w-full flex justify-center">
      <div className="min-w-[250px] w-full max-w-[310px] bg-darkNavy flex p-1 rounded-lg justify-between">
        {tabs.map((item: string, index: number) => {
          return (
            <div
              className={` ${activeTab === item ? "bg-darkPurpleC2" : ""}    ${classes}`}
              onClick={() => onChange(item)}
              key={index}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SleepHistoryTab;
