import { useMutation, useQuery } from "react-query";
import {
  LESSONS_QUERY,
  LESSON_QUERY,
  LESSON_QUERY_WITH_ID,
  PAST_LESSON_QUERY,
} from "./hooksConstant";
import {
  apiCompleteLessonProgress,
  apiGetAllLessons,
  apiGetLesson,
  apiGetLessons,
  apiGetPastLessons,
  apiStartProgress,
  apiUpdateProgress,
} from "@backend/api/content";

export const usePastLessons = (key: string) => {
  return useQuery(
    [LESSON_QUERY, PAST_LESSON_QUERY],
    () => apiGetPastLessons(),
    {
      staleTime: key ? 0 : 5 * 60 * 1000,
      cacheTime: key ? 0 : 10 * 60 * 1000,
    },
  );
};

export const useLessons = () => {
  return useQuery([LESSONS_QUERY], () => apiGetLessons(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useAllLessons = (key: string) => {
  return useQuery([LESSONS_QUERY], () => apiGetAllLessons(), {
    staleTime: key ? 0 : 1 * 1000,
    cacheTime: key ? 0 : 10 * 60 * 1000,
  });
};

export const useLesson = (id: string | undefined) => {
  return useQuery([LESSON_QUERY_WITH_ID, id], () => apiGetLesson(id), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useStartLessonProgress = () => {
  const mutation = useMutation((data: any) => apiStartProgress(data), {
    onSettled: (data, error) => {
      if (error) {
        console.log("Mutation encountered an error:", error);
      }
      return data;
    },
  });

  const mutateAsyncWithStartProgress = async (data: any) => {
    try {
      await mutation.mutateAsync(data);
    } catch (error) {
      console.error("Error in mutation:", error);
    }
  };

  return mutateAsyncWithStartProgress;
};

export const useUpdateLessonProgress = () => {
  const mutation = useMutation((data: any) => apiUpdateProgress(data), {
    onSuccess: () => {},
    onError: (error) => {
      console.log("Mutation encountered an error:", error);
    },
  });

  const mutateAsyncWithFetchLessons = async (data: any) => {
    try {
      await mutation.mutateAsync(data);
    } catch (error) {
      console.error("Error in mutation:", error);
    }
  };

  return mutateAsyncWithFetchLessons;
};

export const useCompleteLessonProgress = () => {
  const mutation = useMutation((data: any) => apiCompleteLessonProgress(data), {
    onSuccess: () => {},
    onError: (error) => {
      console.log("Mutation encountered an error:", error);
    },
  });

  const mutateAsyncWithFetchLessons = async (data: any) => {
    try {
      await mutation.mutateAsync(data);
    } catch (error) {
      console.error("Error in mutation:", error);
    }
  };

  return mutateAsyncWithFetchLessons;
};
