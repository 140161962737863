import React, { FC, ReactNode } from "react";
import { SmartLoader } from "./Loader";

interface ICustomButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  loading?: boolean;
}

const CustomButton: FC<ICustomButtonProps> = (props) => {
  const {
    disabled = false,
    onClick,
    children,
    loading = false,
    className = "",
  } = props;

  return (
    <button
      disabled={disabled}
      className={`relative px-4 py-2 font-light text-white ${className} mt-4 rounded ${disabled ? `bg-lightGray9 cursor-not-allowed` : `bg-themeBlue cursor-pointer`} ${
        !disabled && "transition-colors duration-200"
        // hover:bg-blue-600'
      } mb-3`}
      onClick={onClick}
      data-testid="custom-button"
    >
      <div className="flex items-center justify-center">
        {loading && <SmartLoader />}
        {children}
      </div>
    </button>
  );
};

export default CustomButton;
