/* eslint-disable react-hooks/exhaustive-deps */
import { useState, FC, useEffect } from "react";
import AccordionContainer from "./AccordionContainer";
import VerticalTabs from "./VerticalTabs";
import { IQuestionData } from "./AgeSelection";
import { useUpdateUserSettings, useUser } from "backend/hooks/useUser";
import { getQuestions } from "pages/Sleep/SettingsPage";

interface ITroubleSleepingProps {
  data: IQuestionData;
}

const TroubleSleeping: FC<ITroubleSleepingProps> = ({ data }) => {
  const { refetch, data: { body: { settings } } = { body: { settings: {} } } } =
    useUser();

  const tabs = data.choices;

  const [selectedTab, setSelectedTab] = useState(
    settings?.nights_per_week || "",
  );

  let questions = getQuestions(data, 2);

  const mutateUserSettings = useUpdateUserSettings(refetch);

  const updateUserSettings = () => {
    const apiData = { ...settings, nights_per_week: selectedTab };

    mutateUserSettings.mutate(apiData);
  };

  useEffect(() => {
    if (selectedTab !== settings.nights_per_week) {
      updateUserSettings();
    }
  }, [selectedTab]);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <AccordionContainer>
      <p className="sm:text-lg text-base small-white-text">{questions[0]}</p>
      <div className="flex flex-col items-center">
        <VerticalTabs
          tabs={tabs}
          selected={selectedTab}
          onChange={handleTabChange}
        />
      </div>
    </AccordionContainer>
  );
};

export default TroubleSleeping;
