import { localGet } from "@backend/storage";
import { getAT, sendRQApiJson } from "./service";

export const apiGetLesson = async (id: string | undefined): Promise<any> => {
  return await sendRQApiJson("GET", `/content/lessons/${id}`, getAT(), null);
};

export const apiGetLessons = async (): Promise<any> => {
  return await sendRQApiJson("GET", `/content/lessons/meta`, getAT(), null);
};

export const apiGetAllLessons = async (): Promise<any> => {
  const user = localGet("user");

  if (user?.staff_status === "staff") {
    return await sendRQApiJson("GET", `/content/lessons/meta`, getAT(), null);
  }
};

export const apiGetPastLessons = async (): Promise<any> => {
  return await sendRQApiJson("GET", `/content/lessons/past`, getAT(), null);
};

export const apiStartProgress = async (data: any): Promise<any> => {
  return await sendRQApiJson("POST", "/users/progress/start", getAT(), data);
};

export const apiUpdateProgress = async (data: any): Promise<any> => {
  return sendRQApiJson("POST", "/users/progress/update", getAT(), data);
};

export const apiCompleteLessonProgress = async (data: any): Promise<any> => {
  return await sendRQApiJson("POST", "/users/progress/complete", getAT(), data);
};
