import Container from "@components/Container";
import React, { useEffect, FC } from "react";
import { useParams } from "react-router-dom";
import { useSessions } from "@backend/hooks/useUser";
import { LoadingPage } from "@components/Utils/Loading";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";
import { useQueryClient } from "react-query";
import { USER_SESSON } from "@backend/hooks/hooksConstant";
import { anaScreen } from "@backend/ana";

interface IFullscreenPlayerPage {
  collapsed: () => void;
  musicState: any;
  setMusic?: (action: IMusicAction) => void;
}

const FullplayerPage: FC<IFullscreenPlayerPage> = ({
  musicState,
  setMusic,
}) => {
  const { sessionId } = useParams();

  const { currentSound } = musicState;

  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useSessions(
    sessionId || "",
    currentSound,
  );

  useEffect(() => {
    anaScreen("ms_full_screen", null);
  }, []);

  useEffect(() => {
    // when user access the page directly or reload then we need to run this function else not
    // music type is empty when user access the page directly or reload
    if (data !== undefined && musicState.musicType === "") {
      setMusic &&
        setMusic({
          type: MUSIC_STATES.SET_MUSIC,
          payload: {
            currentSound: data.body.media,
            isPlaying: false,
            showPlayer: true,
            musicType: data.body.media.type ? data.body.media.type :'' ,// "sleepSession",
            isMiniPlayer: false,
          },
        });
      queryClient.removeQueries([USER_SESSON]);
    }
  }, [data]);

  useEffect(() => {
    setMusic &&
      setMusic({
        type: MUSIC_STATES.IS_MINI_PLAYER,
        payload: {
          isMiniPlayer: false,
        },
      });
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return <MusicNotFound />;
  }

  return (
    <Container>
      <></>
    </Container>
  );
};

export default FullplayerPage;

const MusicNotFound: FC = () => {
  return (
    <div className="flex items-center justify-center w-full full-height">
      <h1 className="text-3xl">Music not found</h1>
    </div>
  );
};
