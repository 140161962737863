import React, { useState } from "react";
import JournalCard from "./JournalCard";
import JournalEmoji from "./JournalEmoji";

export interface Emoji {
  icon: string;
  title: string;
  phrase?: string;
  apiValue: string;
}

interface JournalEmojiCardProps {
  emojiList: Emoji[];
  selectedEmoji: string;
  setSelectedEmoji: (emoji: string) => void;
  minHeight?: string;
  readonly?: boolean;
}

interface EmojiSelection {
  icon: string;
  title: string;
}

const JournalEmojiCard: React.FC<JournalEmojiCardProps> = ({
  emojiList,
  selectedEmoji,
  setSelectedEmoji,
  minHeight,
  readonly,
}) => {
  const [phraseDescription, setPhraseDescription] = useState<string>("");

  const handleSelectedEmoji = (value: EmojiSelection) => {
    if (!readonly) {
      setSelectedEmoji(value.title);
      let exist = emojiList.find((item) => item.title === value.title);
      if (exist && "phrase" in exist && exist.phrase !== undefined) {
        setPhraseDescription(exist.phrase);
      } else {
        setPhraseDescription("");
      }
    }
  };

  return (
    <JournalCard minHeight={minHeight}>
      <div className="flex justify-center mb-8 sm:mb-3 h-4">
        <p className="text-sm font-light text-journal-orange text-center">
          {phraseDescription}
        </p>
      </div>
      <div className="flex w-full">
        {emojiList.map((item, index) => {
          return (
            <JournalEmoji
              key={index}
              icon={item.icon}
              title={item.title}
              selected={selectedEmoji}
              onClick={(value) => handleSelectedEmoji(value as EmojiSelection)}
              apiValue={item.apiValue}
            />
          );
        })}
      </div>
    </JournalCard>
  );
};

export default JournalEmojiCard;
