// Footer.js
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";
import { useUser } from "@backend/hooks/useUser";

interface IFooter {
  abs?: boolean;
  collapsed?: boolean;
  isMobile?: boolean;
}

const Footer: FC<IFooter> = ({ abs = false, collapsed, isMobile }) => {
  const { data } = useUser();

  const getFooterStyle = () => {
    if (data !== "No auth token present" && collapsed) {
      return "sm:ml-16 sm:header-width-collapsed";
    } else if (data !== "No auth token present" && isMobile && !collapsed) {
      return "";
    } else if (abs) {
      return "ml-0 header-width-full";
    } else if (data !== "No auth token present" && !collapsed) {
      return "ml-64 header-width-full";
    } else {
      return "sm:header-width-full";
    }
  };
  let footerClassNames = `py-2 text-white text-opacity-50 text-sm flex flex-col items-center justify-center px-3 ${!data && "w-full ml-0 sm:ml-0"} ${getFooterStyle()}`;
  if (abs) {
    footerClassNames +=
      " absolute bottom-0 left-1/2 transform -translate-x-1/2 ml-0";
  } else {
    footerClassNames += "";
  }

  return (
    <footer className={footerClassNames}>
      <div className="mb-1">© 2023 Chorus Wellness, Inc.</div>
      <div className="mb-1">
        <a
          href="https://www.chorussleep.com"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="chorus-link"
        >
          <span className="border-b border-white border-opacity-25 text-white text-opacity-50">
            chorussleep.com
          </span>

          <ArrowTopRightOnSquareIcon className="ml-2 inline-block h-4 w-4" />
        </a>
      </div>
      <div className="flex flex-wrap justify-center space-x-2 sm:justify-start sm:space-x-3 md:flex-row md:space-x-4 ">
        <a
          href="https://www.chorussleep.com/terms-conditions/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="tos"
        >
          Terms of Service
        </a>
        <a
          href="https://www.chorussleep.com/privacy-policy//"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="privacy-policy"
        >
          Privacy Policy
        </a>
        <a
          href="https://www.chorussleep.com/disclaimer/"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="disclaimer"
        >
          Disclaimer
        </a>
      </div>
    </footer>
  );
};

export default Footer;
