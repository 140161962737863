import React, { FC } from "react";

interface CaptionTitleProps {
  title: string;
  className?: string;
}

const CaptionTitle: FC<CaptionTitleProps> = ({ title, className }) => {
  return (
    <h5
      className={`text-base sm:text-lg md:text-xl mb-0 ${className}`}
      data-testid="caption-title"
    >
      {title}
    </h5>
  );
};

export default CaptionTitle;
