import { SendOutlined } from "@ant-design/icons";
import { anaScreen } from "@backend/ana";
import { useUser } from "@backend/hooks/useUser";
import { localSessionGet } from "@backend/storage";
import Container from "@components/Container";
import GobackSleepCoach from "@components/GobackSleepCoach";
import NoMessagePrompt from "@components/NoMessagesPrompt";
import { isB2BGroup, isStaff } from "@components/Utils/Functions";
import { useCoach, useSendCoachMessage } from "backend/hooks/useCoach";
import ChatItem from "components/SleepToday/SleepCoach/ChatItem";
import useScrollToBottom from "components/Utils/CustomHooks/useScrollToBottom";
import { isEmpty } from "lodash";
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

interface IMessage {
  id: string;
  from_user: boolean;
  message: string;
  coach_id: string;
  created: string;
}

interface SleepCoachPageProps {
  collapsed?: boolean;
}

const SleepCoachPage: FC<SleepCoachPageProps> = ({ collapsed }) => {
  let messagesEndRef = useRef<HTMLDivElement | null>(null);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [message, setMessage] = useState<string>("");
  const { data } = useCoach();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const mutation = useSendCoachMessage();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const view = localSessionGet("view");

  // useEffect(() => {
  //   if(view && view==="web"){
  //     searchParams.set('hide', 'true');
  //     setTimeout(() => {
  //       navigate({ pathname: "/sleep-coach", search: searchParams.toString() });
  //     }, 0);
  //   }
  // }, [ view])

  useEffect(() => {
    anaScreen("coach_list", null);
  }, []);

  const hiddenParam = searchParams.get("hide");

  const fromSidebar = location?.state?.fromSidebar;

  const sendMessage = () => {
    if (!isEmpty(message.trim())) {
      mutation.mutate(message);
      setMessage("");
      if (textAreaRef.current) {
        textAreaRef.current.style.height = "40px";
      }
    }
  };

  const handleSendMessageOnEnter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      sendMessage();
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (data?.body?.messages) {
      setMessages(data.body.messages);
      if (mutation?.data?.body?.messages) {
        //To make sure the data in mutation is fresh before sending a new message
        mutation.data.body.messages = data.body.messages;
      }
    }
  }, [data]);

  useEffect(() => {
    if (mutation?.data?.body?.messages) {
      setMessages(mutation.data.body.messages);
    }
  }, [mutation]);

  // useScrollToBottom(mutation.data);
  messagesEndRef = useScrollToBottom(mutation.data);

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const maxRows = 4;
    const lineHeight = 24;
    const minHeight = lineHeight;

    e.target.style.height = `${minHeight}px`;
    e.target.style.height = `${Math.min(e.target.scrollHeight, maxRows * lineHeight)}px`;

    setMessage(e.target.value);
  };

  return (
    <Container collapsed={collapsed}>
      <div
        className={`flex flex-col m-auto ${hiddenParam === "true" ? "full-height" : "py-1 mt-5"}    px-5 bg-darkGray2 w-full sm:w-full md:w-[400px] lg:w-[550px] chat-screen-height shadow-custom`}
      >
        {hiddenParam !== "true" && <GobackSleepCoach />}

        {/* <div className="header-chat-bot">
          <ChatCoachHead title="Chat with sleep coach" />
        </div> */}
        <div
          className={`flex-grow overflow-auto ${hiddenParam === "true" && "mb-5"}   coach-chat-section`}
        >
          {messages.length > 0 ? (
            messages
              .map((item: IMessage) => <ChatItem item={item} key={item.id} />)
              .reverse()
          ) : (
            <NoMessagePrompt />
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="flex items-center p-2 coach-chat-section">
          <textarea
            className="flex coach-chat-section h-[40px] flex-grow p-2 pl-5 pr-5 mr-2 bg-lightGray1 rounded-[8px] resize-none text-chatTextColor focus:outline focus:outline-1 focus:outline-white placeholder:text-sm placeholder:pt-0.5"
            value={message}
            onChange={handleInputChange}
            placeholder="Type a message..."
            onKeyDown={handleSendMessageOnEnter}
            ref={textAreaRef}
          />
          <button
            type="button"
            className="bg-blueC1 disabled:bg-lightGray1 rounded-full w-[40px] flex justify-center items-center h-[40px]"
            onClick={sendMessage}
            disabled={!message}
          >
            <SendOutlined />
          </button>
        </div>
      </div>
    </Container>
  );
};

export default SleepCoachPage;
