import React, { FC } from "react";
import InfoButton from "./InfoButton";

interface ISectionHeading {
  heading: string;
  contentUrl: any;
  from?: string;
  className?: string;
  id?: string;
}

const SectionHeading: FC<ISectionHeading> = ({
  heading,
  contentUrl,
  from,
  className,
  id,
}) => {
  return (
    <div
      className={`flex items-center ${className}`}
      data-testid="section-heading-container"
    >
      {heading === "Lessons" ? (
        <h3
          className="mt-1 text-base font-bold text-white sm:text-3xl"
          id="past-lessons"
        >
          {heading}
        </h3>
      ) : (
        <h2 className="mt-1 text-base font-bold text-white sm:text-3xl" id={id}>
          {heading}
        </h2>
      )}
      <InfoButton
        className="ml-3 text-base sm:text-2xl"
        contentUrl={contentUrl}
        from={from}
      />
    </div>
  );
};

export default SectionHeading;
