import { SVG } from "types/svg";

export const CheckIcon = (props: SVG) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 36 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icon  4">
        <path
          id="Oval"
          opacity="1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 37.311C27.9411 37.311 36 29.2522 36 19.311C36 9.36991 27.9411 1.31104 18 1.31104C8.05887 1.31104 0 9.36991 0 19.311C0 29.2522 8.05887 37.311 18 37.311Z"
          fill="#187ddc"
        />
        <path
          id="Icon"
          d="M15.5976 25.0474L10.7051 20.1841C10.5684 20.0474 10.5 19.8716 10.5 19.6567C10.5 19.4419 10.5684 19.2661 10.7051 19.1294L11.7891 18.0747C11.9258 17.9185 12.0967 17.8404 12.3018 17.8404C12.5068 17.8404 12.6875 17.9185 12.8437 18.0747L16.125 21.356L23.1562 14.3247C23.3125 14.1685 23.4931 14.0904 23.6982 14.0904C23.9033 14.0904 24.0742 14.1685 24.2109 14.3247L25.2949 15.3794C25.4316 15.5161 25.5 15.6919 25.5 15.9068C25.5 16.1216 25.4316 16.2974 25.2949 16.4341L16.6523 25.0474C16.5156 25.2036 16.3398 25.2817 16.125 25.2817C15.9101 25.2817 15.7344 25.2036 15.5976 25.0474Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
