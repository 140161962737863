import { anaScreen } from "@backend/ana";
import { localGet, localSessionGet } from "@backend/storage";
import { usePastLessons } from "backend/hooks/useLessons";
import Container from "components/Container";
import GobackButton from "components/GobackButton";
import LessonCard from "components/SleepToday/Lessons/LessonCard";
import { LoadingPage } from "components/Utils/Loading";
import { FC, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

interface PastLessonPageProps {
  collapsed?: boolean;
}

const PastLessonPage: FC<PastLessonPageProps> = ({ collapsed }) => {
  const { isLoading, data: { body: { lessons } } = { body: { lessons: [] } } } =
    usePastLessons(localGet("viewPastLesson"));
  const location = useLocation();
  const navigate = useNavigate();

  const fromSidebar = location?.state?.fromSidebar;

  const [searchParams] = useSearchParams();

  const hiddenParam = searchParams.get("hide");

  const view = localSessionGet("view");

  // useEffect(() => {
  //   if(view && view==="web"){
  //     searchParams.set('hide', 'true');
  //     setTimeout(() => {
  //       navigate({ pathname: "/past-lessons", search: searchParams.toString() });
  //     }, 0);
  //   }
  // }, [ view])

  useEffect(() => {
    anaScreen("lessons_past", null);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Container dataTestId="sleep-past-lesson" collapsed={collapsed}>
      {fromSidebar === false && <GobackButton />}

      <p className="text-base sm:text-3xl font-bold text-white">Past Lessons</p>

      {lessons &&
        lessons.map((item: any) => {
          const { title, day, duration, id, progress, total_items } = item;
          return (
            <Link
              to={`/lesson-detail/${id} ${hiddenParam === "true" ? "?hide=true" : ""}`}
              key={id}
              state={{ from: "Sleep Home" }}
            >
              <LessonCard
                title={title}
                day={day}
                key={id}
                duration={duration}
                progress={progress}
                totalItems={total_items}
              />
            </Link>
          );
        })}
    </Container>
  );
};

export default PastLessonPage;
