/* eslint-disable no-self-assign */

import { isEmpty } from "lodash";
import ReactApexChart from "react-apexcharts";
import { ChartXAxis, IChartConfiguration, ICustomChartProps, ProcessedData } from "./ICustomChart";
import { getSeriesDataOpacity, isNumberArray, isValidChartData, processData } from "./ChartFunctions";

const CustomChart = (props: ICustomChartProps) => {
  const {
    labels,
    colors = ["#c091d7", "#51bfc0", "#bfc051", "#d7c091"],
    title,
    chartType,
    chartData,
    showEmoji,
    processChartType,
    minY,
    maxY,
    seriesTitle,
    yaxisLabel,
    addPaddingInGraph,
    showDataLabel = false,
    formatter,
    tickAmount,
  } = props;
  let seriesData: ProcessedData[] = [];
  let xaxis: ChartXAxis[] = [];
  let minValue = -Infinity;
  let maxValue = +Infinity;

  if (isValidChartData(chartData)) {
    let { data, xaxis: pXaxis, minValue: pMinValue, maxValue: pMaxValue } = processData(chartData, processChartType, seriesTitle, minY, maxY);
    seriesData = data;
    xaxis = pXaxis;
    minValue = pMinValue;
    maxValue = pMaxValue;

    // Specific for WCG - Average Time to Fall Asleep WCG - Wake up times to add space from left and right
    if (addPaddingInGraph) {
      xaxis[0].categories.push(" ");
      xaxis[0].categories.unshift(" ");

      seriesData[0].data.push(null);
      seriesData[0].data.unshift(null);
    }
  }

  const state: IChartConfiguration = {
    options: {
      ...(labels && { labels: [...labels] }),
      colors: colors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        labels: {
          colors: "#FFF",
        },
      },
      stroke: {
        show: true,
        colors: colors,
        curve: "smooth",
      },
      xaxis: {
        ...(xaxis.length > 0 && { ...xaxis[0] }),
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
      yaxis: {
        // @ts-ignore
        tickAmount,
        labels: {
          ...(showEmoji && {
            formatter: function (val: any) {
              val = Math.round(val);
              if (val < 20) {
                return "😢 " + val;
              } else if (val < 40) {
                return "😐 " + val;
              } else if (val < 60) {
                return "😊 " + val;
              } else {
                return "🥳 " + val;
              }
            },
          }),
          ...(!showEmoji && formatter && { formatter }),
          // offsetX: -10,
          style: {
            // @ts-ignore
            fontSize: formatter || showEmoji ? "18px" : "14px",
            colors: "#fff",
          },
        },
        min: minValue,
        max: maxValue,
        title: {
          text: yaxisLabel,
          style: {
            color: "#fff",
          },
        },
      },
      dataLabels: {
        enabled: showDataLabel,
      },
      grid: {
        borderColor: "#282828",
        padding: {
          left: 23,
        },
      },
      fill: {
        type: "solid",
        opacity: getSeriesDataOpacity(seriesData),
      },
    },
    series: labels && isNumberArray(chartData) ? chartData : seriesData,
  };
  return (
    <div className="relative block h-[300px] w-full p-4  md:h-[500px]">
      <h5 className="text-1xl font-medium">{title}</h5>
      {!isEmpty(chartData) ? (
        <ReactApexChart options={state.options} series={state.series} type={chartType} width="100%" height="100%" />
      ) : (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform p-2 text-center">No data for preview</div>
      )}
    </div>
  );
};

export default CustomChart;
