import React, { FC } from "react";
import { FiBookOpen } from "react-icons/fi";
import JournalCard from "./JournalCard";

interface IJournalTextInput {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Updated the type definition here
}

const JournalTextInput: FC<IJournalTextInput> = ({ onChange, value }) => {
  return (
    <JournalCard
      icon={
        <FiBookOpen className="text-base sm:text-2xl ml-2 text-white text-opacity-70" />
      }
      outerTitle={"Journal"}
    >
      <textarea
        className="w-full text-sm sm:text-base bg-transparent font-light border-none appearance-none focus:outline-none text-opacity-70 text-white"
        rows={5}
        onChange={onChange}
        value={value}
        data-testid="journal-input"
        placeholder="Getting thoughts out of your head and into a journal can help you stop worrying and improve your wellbeing."
      />
    </JournalCard>
  );
};

export default JournalTextInput;
