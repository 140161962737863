import { getUserFromStorage } from "@backend/api/service";
import { logout } from "@backend/auth_funcs";
import { useQueryClient } from "react-query";
import { Dispatch, SetStateAction, useEffect } from "react";
import { localPut } from "@backend/storage";

type PropType = {
  changeModalStatus: Dispatch<SetStateAction<boolean>>;
  className?: string;
};
function ConfirmLogin({ changeModalStatus, className }: PropType) {
  const user = getUserFromStorage();
  const queryClient = useQueryClient();
  function handleNavigateAndNeverShowAgain() {
    localPut("confirm-login", "true");
    changeModalStatus(false);
  }
  async function handleLogout() {
    try {
      await logout(queryClient);
      // * Added these into logout() fn
      // window.history.replaceState(null, "", "/");
      // clearLocalStorageLocationKeys();
      // window.location.reload();
    } catch (err) {
      console.error("Logout Error", err);
    }
  }
  useEffect(() => {
    // localPut("confirm-login", "false");
    if (localStorage.getItem("confirm-login") === "true") {
      localPut("confirm-login", "false");
      changeModalStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    // * OLD: MODAL BASED
    // <div className={"h-max rounded grid place-items-center " +className?className:''}>
    //   <div className="w-full max-w-lg flex flex-col gap-6 items-center bg-darkGray2 p-6 rounded text-center h-max">
    //     {/* <div className="flex items-center gap-2">
    //       <img
    //         src={chorusSleep}
    //         width={0}
    //         height={0}
    //         className="w-32 h-32"
    //         alt="Chorus Sleep"
    //       />
    //     </div> */}
    //     <h1 className="font-bold text-[30px]">Confirm your email</h1>
    //     <p className="text-[16px] px-5">
    //       We recognized your verified email <u>{user.email}</u> and logged you
    //       in. If that’s not correct you can log out below.
    //     </p>
    //     <button
    //       onClick={handleNavigateAndNeverShowAgain}
    //       className="text-[16px] font-semibold rounded-md bg-blueC1 p-2 px-3"
    //     >
    //       Looks Good! Continue
    //     </button>
    //     <button onClick={handleLogout} className="text-[16px] underline">
    //       Logout
    //     </button>
    //     {/* <p style={{ fontSize: "14px" }} className="px-5">
    //       By continuing to use Chorus, You agree to our Terms of Service,
    //       Privacy Policy, and Disclaimer.
    //     </p> */}
    //     <p style={{ fontSize: "12px" }}>
    //       Having any issues? Let us help <a target="_blank" href="mailto:support@chorussleep.com" className="underline">support@chorussleep.com</a>
    //     </p>
    //   </div>
    // </div>
    // * NEW: TOAST BASED
    <div className={"relative h-max max-w-xs" + className ? className : ""}>
      {/* Close button container */}
      <div className="absolute top-0 right-0 p-2">
        {/* Close button */}
        <button
          onClick={handleNavigateAndNeverShowAgain}
          className="text-white bg-transparent hover:bg-gray-200 hover:text-darkGray rounded-full text-sm p-1"
        >
          X
        </button>
      </div>
      <div className="flex flex-col gap-3 bg-darkGray p-3 items-start w-full max-w-xs sm:max-w-md">
        <h1 className="font-bold text-sm sm:text-lg">Welcome, {user.nickname}!</h1>
        <div className="flex flex-col sm:flex-row sm:items-center gap-2">
          <p className="text-xs  sm:text-sm break-words leading-[1.5] max-w-[18rem]">
            We recognized your verified email <em>{user.email}</em> and logged you
            in. If that’s not correct you can <span onClick={handleLogout} className="cursor-pointer underline">logout here</span>.
          </p>
          
        </div>
        <div>
          <button
              onClick={handleNavigateAndNeverShowAgain}
              className="text-xs font-semibold rounded-md bg-blueC1 px-3 py-2"
            >
              Looks Good! Continue
            </button>
            </div>
      </div>
    </div>
  );
}

export default ConfirmLogin;
