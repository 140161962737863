// CSS for spinner is in the index.html so this can be used for initial site loading
import React from "react";
export const Loading = () => {
  return <div className="spinner" data-testid="spinner1"></div>;
};

// For buttons and such
export const LoadingSmall = () => {
  return (
    <div className="flex items-center justify-center" data-testid="spinner2">
      <div className="spinner w-8 h-8"></div>
    </div>
  );
};

export const LoadingPage = () => {
  return (
    <div
      className="min-h-screen flex items-center justify-center"
      data-testid="spinner3"
    >
      <div className="max-w-2xl text-center space-y-4">
        <Loading />
      </div>
    </div>
  );
};
