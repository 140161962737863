import { useToday } from "@backend/hooks/useUser";
import React from "react";
import { BiSolidLockAlt } from "react-icons/bi";

const NextLessonCard = () => {
  const { data, isLoading } = useToday();

  return (
    <div className="mb-2 mt-4 flex items-center justify-between rounded-lg bg-darkGray2 p-4 shadow">
      <div className="flex flex-col">
        {data && !isLoading ? (
          <div>
            <div className="font-bold text-white text-opacity-50  sm:text-lg">Up next tomorrow:</div>
            <div className="mt-3 text-sm font-light text-white text-opacity-50">{data.body.next_lesson_title}</div>
          </div>
        ) : null}
      </div>

      <BiSolidLockAlt className="h-8 w-8 text-lightGray1" data-testid="complete-icon" />
    </div>
  );
};

export default NextLessonCard;
