import React, { FC } from "react";

interface GroupUserProps {
  label: string;
  total?: string;
}

const GroupUserItem: FC<GroupUserProps> = ({ label, total }) => {
  const displayTotal =
    total !== undefined && !Number.isNaN(total) && parseInt(total) !== 0
      ? total
      : "-";

  return (
    <div className="text-center">
      <p className="font-medium mb-2">{displayTotal}</p>
      <p className="font-extralight opacity-80 text-sm">{label}</p>
    </div>
  );
};

export default GroupUserItem;
