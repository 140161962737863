import React, { FC } from "react";
import ReactApexChart from "react-apexcharts";

interface IProgressChart {
  total: number;
  value: number;
  label?: number;
  time?: number;
}

const ProgressChart: FC<IProgressChart> = (props) => {
  const { total, value, label, time } = props;

  const state: any = {
    series: label ? label : [(value / total) * 100],
    options: {
      chart: {
        width: "100%",
        height: "auto",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "50%",
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 4,
              fontSize: "12px",
            },
            value: {
              show: false,
            },
          },
        },
      },
      labels: label ? label : [`${value}/${total}`],
    },
  };

  return (
    <div className="flex flex-col items-center">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        width="50"
        height="100"
      />
      <div className="text-sm font-light">{time} mins</div>
    </div>
  );
};

export default ProgressChart;
