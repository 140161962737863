import React from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

type CounterProps = {
  value: number | string | undefined;
  jumpValue?: number;
  onValueChange: (value: number | string) => void;
};

const JournalCounter: React.FC<CounterProps> = ({
  value,
  jumpValue = 1,
  onValueChange,
}) => {
  const increment = () => {
    typeof value === "number" && onValueChange(value + jumpValue);
  };

  const decrement = () => {
    if (typeof value === "number" && value > 0) {
      onValueChange(value - jumpValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      onValueChange(Number(event.target.value));
    } else {
      onValueChange(event.target.value);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
    ];
    if (!allowedKeys.includes(event.key) && isNaN(parseInt(event.key, 10))) {
      event.preventDefault();
    }
  };

  const handleInputBlur = () => {
    if (!value) {
      onValueChange(0);
    }
  };

  return (
    <div className="inline-flex items-center p-2 border border-white rounded-full bg-darkGray">
      <button
        className="text-gray-500 hover:text-gray-700 focus:outline-none"
        onClick={decrement}
        aria-label="decrement"
      >
        <AiOutlineMinus size={24} className="text-white text-opacity-70" />
      </button>
      <input
        className="w-16 text-center  font-bold text-white text-opacity-70 bg-transparent border-none focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        type="number"
        value={value}
        onChange={handleInputChange}
        min="0"
        onKeyDown={handleKeyPress}
        onBlur={handleInputBlur}
      />
      <button
        className="focus:outline-none"
        onClick={increment}
        aria-label="increment"
      >
        <AiOutlinePlus size={24} className="text-white text-opacity-70" />
      </button>
    </div>
  );
};

export default JournalCounter;
