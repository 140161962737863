import React, { FC } from "react";
import { AiFillCheckCircle, AiOutlineClose } from "react-icons/ai";

interface IQuizResult {
  isCorrectAnswer: boolean;
  correctChoice: string;
  description: string;
  handleContinue: () => void;
}

const QuizResult: FC<IQuizResult> = ({
  isCorrectAnswer,
  correctChoice,
  description,
  handleContinue,
}) => {
  return (
    <div className="mb-5 shadow-lg shadow-black">
      <div
        className={`${isCorrectAnswer ? "bg-sky-400" : "bg-red-500"} flex flex-col`}
        data-testid="quiz-result"
      >
        <div className="flex flex-col items-center p-4">
          {isCorrectAnswer ? (
            <AiFillCheckCircle className="text-7xl text-journal-yellow" />
          ) : (
            <AiOutlineClose className="text-7xl" />
          )}
          <h4 className="font-light">
            {isCorrectAnswer ? "Great Job!" : "Not quite!"}
          </h4>
        </div>
        <div
          className={`flex flex-col items-center p-4 ${isCorrectAnswer ? "bg-customPurple" : "bg-red-400"}`}
        >
          <p className="font-light">
            The correct answer is:{" "}
            <span className="font-semibold">{correctChoice}</span>
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center p-4 leading-7">
        <p>
          {description.split("\n").map((text: string, index: number) => (
            <React.Fragment key={index}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </p>
        <button
          className={`mb-3 mt-4 w-full rounded bg-lightGray9 px-4 py-2 font-light text-white transition-colors duration-200`}
          onClick={handleContinue}
          data-testid="quiz-result-btn"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default QuizResult;
