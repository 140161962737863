// In most cases, we just want to clear the auth token from the

import { localSessionsPut } from "./storage";

// query string but keep all other params

export const getATAndClear = (): string | null => {
  const at = getQueryStringParam("at");

  // Check if the "at" param exists, clear if so
  if (at !== null) {
    // Get the URL search params
    const searchParams = new URLSearchParams(window.location.search);

    // Remove the "at" param
    searchParams.delete("at");
    localSessionsPut("view", "web");

    // Update the browser's query string without the "at" param
    const newUrl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  }
  return at;
};

export const getQueryStringParam = (paramName: string): string | null => {
  // Check if we're in a browser environment
  if (!isBrowser()) {
    return null;
  }

  // Get the URL search params
  const searchParams = new URLSearchParams(window.location.search);

  // Return the parameter value or null if it does not exist
  return searchParams.has(paramName) ? searchParams.get(paramName) : null;
};

export const isBrowser = (): boolean => typeof window !== "undefined";
