import { anaScreen } from "@backend/ana";
import { useAllLessons } from "@backend/hooks/useLessons";
import { localGet } from "@backend/storage";
import Container from "@components/Container";
import GobackButton from "@components/GobackButton";
import LessonCard from "@components/SleepToday/Lessons/LessonCard";
import { LoadingPage } from "@components/Utils/Loading";
import { FC, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

interface AllLessonPageProps {
  collapsed?: boolean;
}

const AllLessonPage: FC<AllLessonPageProps> = ({ collapsed }) => {
  const { isLoading, data: { body: { lessons } } = { body: { lessons: [] } } } =
    useAllLessons(localGet("viewPastLesson"));

  const location = useLocation();
  const fromSidebar = location?.state?.fromSidebar;

  const [searchParams] = useSearchParams();

  const hiddenParam = searchParams.get("hide");

  useEffect(() => {
    anaScreen("lessons_all", null);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Container collapsed={collapsed}>
      {fromSidebar === false && <GobackButton />}
      <h1 className="text-base sm:text-3xl font-bold text-white">
        All Lessons
      </h1>

      {lessons &&
        lessons.map((item: any) => {
          const { title, day, duration, id, progress, total_items } = item;
          return (
            <Link
              to={`/lesson-detail/${id} ${hiddenParam === "true" ? "?hide=true" : ""}`}
              key={id}
            >
              <LessonCard
                title={title}
                day={day}
                key={id}
                duration={duration}
                progress={progress}
                totalItems={total_items}
              />
            </Link>
          );
        })}
    </Container>
  );
};

export default AllLessonPage;
