import coachImage from "@assets/logos/woman_crying.png";
import { MORE_INFO } from "@assets/staticData";
import { useToday } from "@backend/hooks/useUser";
import SectionHeading from "@components/SectionHeading";
import { DEFAULT_BODY } from "@pages/Sleep/SleepTodayPage";
import type { FC } from "react";
import { Link } from "react-router-dom";
import React from "react";

const SleepCoachCard: FC = () => {
  const { data } = useToday();
  const body = data?.body || DEFAULT_BODY;

  return (
    <>
      <SectionHeading heading="Connect" contentUrl={MORE_INFO.CONNECT_URL} from="Sleep Home" id="connect" />
      <div id="coach">
        <h3 className="sm:text-1xl mt-4 text-base text-lightYellowC1_2" id="sleep-coach">
          My Sleep Coach
          {body?.coach_unread > 0 && <span className="ml-3 h-full rounded-full bg-journal-red px-2 py-1 text-xs font-bold text-white">{body?.coach_unread}</span>}
        </h3>

        <Link to="/sleep-coach">
          <div className="mb-2 mt-4 flex items-center justify-between rounded-lg bg-darkPurpleC2 p-4 shadow">
            <img src={coachImage} alt="coach" className="w-32" />
            <p className="text-center text-base sm:text-2xl">Connect with your Sleep Coach</p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SleepCoachCard;
