import { MORE_INFO } from "@assets/staticData";
import SectionHeading from "@components/SectionHeading";
import { IMusicAction, MUSIC_STATES } from "@reducers/musicReducer";
import { FC, Fragment } from "react";
import SleepCard from "../SleepCard";
import Slider from "../Slider";
import React from "react";
interface ISleepCardSlider {
  data: any;
  title?: string;
  subTitle?: string;
  cardType: string;
  width?: number;
  height?: number;
  testId: string;
  scrollKey: string;
  setMusic?: (action: IMusicAction) => void;
  targetID?: string;
}

const SleepCardSlider: FC<ISleepCardSlider> = (props) => {
  const {
    data,
    title,
    subTitle,
    cardType,
    height,
    width,
    testId,
    scrollKey,
    setMusic,
  } = props;

  const handelMusicPlay = (music: any | undefined) => {
    setMusic &&
      setMusic({
        type: MUSIC_STATES.SET_MUSIC,
        payload: {
          showPlayer: true,
          musicType: music?.fullData?.type || '',
          // musicType: music?.fullData?.type === 'sound'? "sleepSound": music.fullData.type==='session'?'sleepSession':'meditation',
          currentSound: music.fullData,
          isPlaying: true,
        },
      });
  };

  return (
    <Fragment>
      {title && (
        <SectionHeading
          heading={title}
          contentUrl={MORE_INFO.DIVE_DEEPER_URL}
          from="Sleep Home"
          id="discover-dive-deeper"
        />
      )}
      <div
        className={subTitle==='Sleep Sounds'?'mt-1':""}
        id={subTitle === "Sleep Sounds" ? "sounds" : "essentials"}
        >
      <h3
        className="mt-4 text-base sm:text-lg text-lightYellowC1_2"
        id={subTitle === "Sleep Sounds" ? "sleep-sounds" : "sleep-essentials"}
      >
        {subTitle}
      </h3>
      <div
        className={`${props.targetID === "essentials" ? "border-2 rounded-md border-themeBlue p-1" : ""}`}
      >
        <Slider width="w-8" height="h-8" scrollKey={scrollKey}>
          {data.map((item: any, index: number) => (
            <SleepCard
              key={index}
              id={index}
              width={width}
              height={height}
              title={item.title}
              cardType={cardType}
              logo={item.logo_image}
              bgImage={item.image1}
              content={item.content_link}
              onClick={(data) => handelMusicPlay(data)}
              testId={testId}
              fullData={item}
            />
          ))}
        </Slider>
      </div>
      </div>
      {/* {sleepMusic && <MusicPlayer music={sleepMusic} handelClose={() => setSleepMusic('')} title={sleepTitle} logo_image={logoImage} />} */}
    </Fragment>
  );
};

export default SleepCardSlider;
