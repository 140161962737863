import {
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/solid";
import { FC } from "react";
import { teacherURL } from "@components/Utils/Functions";
import { capitalize } from "lodash";
import { localDel } from "@backend/storage";
import { ISleepSessionSlider } from "@components/SleepToday/SessionSlider/SleepSessionSlider";
import { FiSun } from "react-icons/fi";

const DaytimeSessionCard: FC<ISleepSessionSlider> = (props) => {
  const {
    id,
    testId = "sleep-sound-card",
    setCurrentSound,
    data,
    isPlaying,
  } = props;

  const { background_image, title, author_id, duration } = data;

  const handlePlay = () => {
    localDel("currentTime");
    setCurrentSound(isPlaying ? null : { ...data, currentId: id });
  };

  return (
    <div className="mx-auto w-full max-w-[400px]">
      <div className="bg-lightBlueC1 rounded-2xl mt-2 max-w-[400px]">
        <div
          style={{ backgroundImage: `url(${background_image})` }}
          className="relative flex h-80 max-w-[400px] cursor-pointer items-end justify-center rounded-2xl bg-cover bg-center bg-no-repeat p-3"
          data-testid={`${testId}-${id}`}
          onClick={handlePlay}
        >
          <PlayPauseButton isPlaying={isPlaying} onClick={handlePlay} />

          <div className="h-[68px] relative m-4 flex w-full items-center rounded-lg bg-gray-300 p-3">
            {data?.author_id && (
              <img
                className="absolute bottom-0"
                src={teacherURL(data.author_id)}
                alt="teacher"
                width="100"
                height="100"
              />
            )}
            <div className="w-1/3" />
            <div className="w-2/3">
              <p
                className="text-1xl leading-5 truncate-2-lines font-bold text-charcoalC1"
                data-testid={`sleep-sound-card-description-${id}`}
              >
                {title}
              </p>
              <p
                className="text-sm font-light text-charcoalC1"
                data-testid={`sleep-sound-card-title-${id}`}
              >
                {capitalize(author_id)}
              </p>
            </div>
          </div>
        </div>
        <div className="px-5 py-2 flex justify-between items-center">
          <div className="flex items-center gap-1">
            <SpeakerWaveIcon className="w-6 text-charcoalC1" />
            <p className="font-semibold text-sm text-charcoalC1">{`${duration} mins`}</p>
          </div>
          <div className="flex flex-col items-center gap-1">
            <FiSun className="w-6 rotate-[24deg] text-charcoalC1" />
            <p className="text-xs text-charcoalC1">Daytime</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaytimeSessionCard;

const PlayPauseButton: FC<{ isPlaying: boolean; onClick: () => void }> = ({
  isPlaying,
  onClick,
}) => (
  <div
    onClick={onClick}
    className="absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer text-white"
    role="button"
    aria-label={isPlaying ? "Pause" : "Play"}
  >
    {isPlaying ? (
      <PauseIcon data-testid="play-icon" />
    ) : (
      <PlayIcon data-testid="play-icon" />
    )}
  </div>
);
