import React, {
  useState,
  useEffect,
  FC,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

import { timeZones } from "@assets/staticData";
interface IMySelect {
  options: string[];
  value?: string;
  onChange?: (value: string) => void;
}

const MySelect: FC<IMySelect> = ({ options, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const optionsListRef = useRef<HTMLDivElement | null>(null);

  const selectDivRef = useRef<HTMLDivElement>(null);
  const [selectWidth, setSelectWidth] = useState(0);

  useEffect(() => {
    if (selectDivRef.current) {
      setSelectWidth(selectDivRef.current.offsetWidth);
    }
  }, []);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [options, searchTerm],
  );

  useEffect(() => {
    setSearchTerm(value || "");
  }, [value]);

  const handleSelectionChange = useCallback(
    (option: string) => {
      onChange?.(option);
      setSearchTerm("");
      setIsOpen(false);
      setHighlightedIndex(-1);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case "ArrowDown":
          if (!isOpen) {
            setIsOpen(true); // Open the dropdown if it's closed
            setSearchTerm("");
          }
          setHighlightedIndex((prevIndex) =>
            Math.min(prevIndex + 1, filteredOptions.length - 1),
          );
          break;
        case "ArrowUp":
          if (isOpen)
            setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
          break;
        case "Enter":
          if (isOpen && highlightedIndex >= 0)
            handleSelectionChange(filteredOptions[highlightedIndex]);
          break;
      }
    },
    [isOpen, highlightedIndex, filteredOptions, handleSelectionChange],
  );

  useEffect(() => {
    if (highlightedIndex >= 0 && optionsListRef.current) {
      const optionElements = optionsListRef.current.querySelectorAll("div");
      if (optionElements[highlightedIndex]) {
        optionElements[highlightedIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
  }, [highlightedIndex]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectDivRef.current &&
        !selectDivRef.current.contains(event.target as Node) &&
        optionsListRef.current &&
        !optionsListRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="relative w-full">
        <div
          ref={selectDivRef}
          onClick={() => {
            setIsOpen(!isOpen);
            setSearchTerm("");
          }}
          onKeyDown={handleKeyDown}
          tabIndex={0}
          className="flex cursor-pointer items-center justify-between rounded border p-2"
        >
          <input
            type="text"
            placeholder="Search Timezone..."
            value={isOpen ? searchTerm : value || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full"
          />
          <MdOutlineKeyboardArrowDown className="text-2xl" />
        </div>
      </div>
      {isOpen && (
        <div
          className="bg- absolute z-20  max-h-[300px] min-h-[40px] overflow-y-auto rounded-md border bg-darkGray2 shadow-lg"
          style={{ width: selectWidth }}
        >
          <div ref={optionsListRef}>
            {filteredOptions.map((option, index) => (
              <div
                key={option}
                onClick={() => handleSelectionChange(option)}
                className={`cursor-pointer p-2 hover:bg-lightGray9 ${highlightedIndex === index ? "bg-lightGray8" : ""}`}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

interface ITimezoneSelectionProps {
  className?: string;
  onChange: (value: string) => void;
  value: string;
}

const TimezoneSelection: FC<ITimezoneSelectionProps> = ({
  className,
  onChange,
  value,
}) => {
  return (
    <div className={`${className ? className : ""}`}>
      <MySelect options={timeZones} value={value} onChange={onChange} />
    </div>
  );
};

export default TimezoneSelection;
