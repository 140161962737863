import React, { useState, useRef, FC, ReactNode } from "react";
import { MdKeyboardArrowRight, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { CSSTransition } from "react-transition-group";

interface AccordionProps {
  title: string;
  children?: ReactNode;
}

const Accordion: FC<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="my-1 w-full rounded-lg border-b border-darkGray bg-darkGray2 p-3">
      <div
        className="flex cursor-pointer items-center justify-between rounded-md px-2 py-2 sm:p-4"
        onClick={() => setIsOpen(!isOpen)}
        data-testid="accordion-button"
      >
        <p className="max-w-[85%] text-base font-medium sm:max-w-[95%] sm:text-lg">
          {title}
        </p>
        {isOpen ? (
          <MdOutlineKeyboardArrowUp
            className="h-6 w-6 rounded-full bg-themeBlue text-white"
            data-testid="up-arrow-icon"
          />
        ) : (
          <MdKeyboardArrowRight
            className="h-6 w-6 rounded-full bg-themeBlue text-white"
            data-testid="right-arrow-icon"
          />
        )}
      </div>
      <CSSTransition
        in={isOpen}
        timeout={550}
        classNames="accordion-content"
        unmountOnExit
      >
        <div ref={contentRef} className="hide-scrollbar overflow-auto w-full">
          <div className="px-2 py-2 sm:p-4">{children}</div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Accordion;
