import { useUser } from "@backend/hooks/useUser";
import React, { FC } from "react";
import GroupUserItem from "./GroupUserItem";

const GroupDetail: FC = () => {
  const {
    data: {
      body: { group: groupData },
    },
  } = useUser();

  const { num_active, num_employees, num_engaged, logo_small_link, name, id } = groupData;
  const adoptionFn = (num_active: number, num_employees: number): string => {
    if (isNaN(num_active) || isNaN(num_employees) || num_employees === 0) {
      return "0.0";
    }
  
    const percentage = (num_active / num_employees) * 100;
    const roundedPercentage = Math.round(percentage * 10) / 10;
  
    return roundedPercentage.toFixed(1);
  };
  const adoption = adoptionFn(num_active, num_employees);

  return (
    <>
      <div className="mt-4">
        {logo_small_link ? <img className="h-32 w-32 rounded-full object-center" src={logo_small_link} alt="wcg-logo" /> : <div className="h-[100px] w-[100px] rounded-full bg-customPurple"></div>}
      </div>
      <h2 className="my-6 text-3xl font-medium">{name ? name : "WCG"}</h2>
      <div className="mb-12 p-2 text-center">Employees use code: {id}</div>
      <div className="mb-8 flex flex-col space-x-0 sm:flex-row sm:space-x-4">
        <GroupUserItem label={"Eligible Employees"} total={num_employees} />
        <GroupUserItem label={"Adopted Employees"} total={num_active} />
        <GroupUserItem label={"Engaged Employees"} total={num_engaged} />
        <GroupUserItem label={"Adoption"} total={adoption + "%"} />
      </div>
      {/* <div className="flex space-x-0 mb-8 flex-col sm:flex-row sm:space-x-12">
        
        <GroupUserItem label={'Engaged'} total={isNaN(engagement) ? '0' : engagement.toString() + '%'} />
      </div> */}
    </>
  );
};

export default GroupDetail;
