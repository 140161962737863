import React, { FC } from "react";
import { TabButton, ITab } from "./VerticalTabs";

interface IVerticalTabsMultiSelect {
  selected: string[];
  onChange: (tab: string) => void;
  tabs: ITab[];
}

const VerticalTabsMultiSelect: FC<IVerticalTabsMultiSelect> = ({
  selected,
  onChange,
  tabs,
}) => {
  return (
    <>
      {tabs.map((item, index) => {
        return (
          <TabButton
            id={item.id}
            key={index}
            name={item.text}
            selected={selected.includes(item.id)}
            onChange={onChange}
          />
        );
      })}
    </>
  );
};

export default VerticalTabsMultiSelect;
