import { AndroidFilled, AppleFilled, ArrowRightOutlined } from "@ant-design/icons";
import { localSessionGet, localSessionsPut } from "@backend/storage";
import { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";

function DownloadAppBanner() {
  const isIOS = navigator.userAgent.includes("iPhone OS") || navigator.userAgent.includes("iPad") || navigator.userAgent.includes("iPod");
  const isAndroid = navigator.userAgent.includes("Android");
  const isMobile = isIOS || isAndroid;
  // console.log(localSessionGet("app-banner"));
  const [isOpen, setOpen] = useState(localSessionGet("app-banner") ?? true);
  function closeBanner(e: any, isButton = false) {
    if (isButton) (e as Event).preventDefault();
    setOpen(false);
    localSessionsPut("app-banner", false);
  }

  return isMobile && isOpen ? (
    <Link
      onClick={closeBanner}
      to={isAndroid ? "https://play.google.com/store/apps/details?id=com.flutterflow.chorussleep&pli=1" : isIOS ? "https://apps.apple.com/us/app/chorus-sleep-relax/id1615658684" : ""}
      target="_blank"
      className="sticky mb-3 mt-1 flex items-center justify-between gap-3 rounded-lg bg-[#EC5759] p-3 px-4 font-medium text-white sm:gap-1 sm:p-2"
    >
      <div className="flex h-full w-full flex-col items-center gap-3 text-sm sm:flex-row sm:flex-wrap sm:justify-around sm:text-lg">
        <span>Chorus is better on our mobile app!</span>
        <div className="flex items-center gap-3 rounded border border-[hsl(0,0%,100%,1)] p-1 px-2">
          {isIOS ? <AppleFilled className="mb-1 text-3xl" /> : <AndroidFilled className="mb-1 text-2xl" />}
          <div className="break-words">{isIOS ? "Download for iPhone or iPad" : isAndroid ? "Download for Android" : ""}</div>
          <ArrowRightOutlined className="text-lg" />
        </div>
      </div>
      <button onClick={(e) => closeBanner(e, true)} className="border sm:pr-2">
        <RiCloseFill className="text-2xl" />
      </button>
    </Link>
  ) : null;
}

export default DownloadAppBanner;
