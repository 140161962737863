import { anaScreen } from "@backend/ana";
import { useUser } from "@backend/hooks/useUser";
import Container from "@components/Container";
import ResourceCard from "@components/Resources/ResourceCard";
import { FC, useEffect } from "react";

interface ResourcesProps {
  collapsed?: boolean;
}

const Resources: FC<ResourcesProps> = ({ collapsed }) => {
  useEffect(() => {
    anaScreen("leadership_resources", null);
  }, []);
  const {
    data: {
      body: { group },
    },
  } = useUser();

  interface ResourceItem {
    content: string;
    title: string;
    bg_color: string;
  }
  const resources: ResourceItem[] = group.resources;

  return (
    <Container collapsed={collapsed}>
      <div className="" data-testid="resource-page">
        <h1 className="text-3xl font-medium py-6 pl-6">Resources</h1>
        {resources.map((item, index: number) => {
          return <ResourceCard item={item} key={index} />;
        })}
      </div>
    </Container>
  );
};

export default Resources;
