interface ILabelView {
  value: string;
}

const LabelView: React.FC<ILabelView> = ({ value }) => (
  <p className="text-sm sm:text-base md:text-lg text-opacity-70 font-light text-white text-center">
    {value}
  </p>
);

export default LabelView;
