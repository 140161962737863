import { localGet } from "@backend/storage";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

export class APIError extends Error {
  code: string;
  title: string;
  msg: string;

  constructor(code: string, title: string, msg: string) {
    super(msg);
    this.msg = msg;
    this.code = code;
    this.title = title;
  }
}

export const sendRQApiJson = async (
  method: string,
  urlPath: string,
  at: string | null,
  bodyData: any,
): Promise<any> => {
  const headers: { [key: string]: string } = {
    "X-APP-ID": "nightmare2023",
  };

  if (method !== "GET" && method !== "DELETE") {
    headers["Content-Type"] = "application/json";
  }

  if (at) {
    headers["Authorization"] = `Bearer ${at}`;
  }

  const config: AxiosRequestConfig = {
    method,
    url: `${apiBase()}${urlPath}`,
    headers,
    data: bodyData,
  };

  console.log(`[**net-rq**] Starting ${method} ${urlPath} -- ${apiBase()} `);

  try {
    const response: AxiosResponse<any> = await axios(config);
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response && error.response.data && error.response.data.error) {
        const { code, title, msg } = error.response.data.error;
        throw new APIError(code, title, msg);
      }
    }
    throw new Error("Network error");
  }
};

export async function sendApiJson(
  method: string,
  urlPath: string,
  at: string | null,
  bodyData: any,
  noHandle?: boolean,
): Promise<any> {
  let headers: { [key: string]: string } = {
    "X-APP-ID": "nightmare2023",
  };

  if (method !== "GET" && method !== "DELETE") {
    headers["Content-Type"] = "application/json";
  }

  if (at) {
    headers["Authorization"] = `Bearer ${at}`;
  }

  let req: RequestInit = {
    method,
    headers: headers,
  };

  if (bodyData) {
    req["body"] = JSON.stringify(bodyData);
  }
  console.log(`[**net**] Starting ${method} ${urlPath} -- ${apiBase()} `);

  return await fetch(`${apiBase()}${urlPath}`, req)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      if (data.error) {
        if (!noHandle) {
          handleAPIError(data);
        }
      }
      return data;
    })
    .catch((err) => {
      handleNetError(err);
    });
}

export function getApiError(data: any, showMsg: boolean = false): string {
  if (!data || !data.error) {
    return "";
  }
  let title = "Error";
  let msg =
    "There was an error processing your request. Please try again in a moment or contact support@chorussleep.com";
  if (data.error.title) {
    title = data.error.title;
  }
  if (data.error.msg) {
    msg = data.error.msg;
  }
  if (showMsg) {
    return `${title} ${msg}`;
  }
  return title;
}

function handleAPIError(data: any) {
  let title = "Error";
  let msg =
    "There was an error processing your request. Please try again in a moment or contact support@chorussleep.com";
  if (data.error.title) {
    title = data.error.title;
  }
  if (data.error.msg) {
    msg = data.error.msg;
  }
  // alert(`${title}: ${msg}`);
}

function handleNetError(err: any) {
  console.log("**** Failed HTTP request::");
  console.log(err);
  // alert("Network error: Please try again in a moment or contact support@chorussleep.com");
}

export function apiBase() {
  if (!process.env.REACT_APP_API_ENDPOINT) {
    console.log(process.env);
    throw new Error("API ENDPOINT NOT SET!");
  }

  return process.env.REACT_APP_API_ENDPOINT;
}

export function getAT() {
  return localGet("at");
}

export function getUserFromStorage() {
  // console.log("**** getting user from storage!");
  return localGet("user");
}
