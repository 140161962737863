import { localSessionGet } from "@backend/storage";
import Container from "components/Container";
import GobackButton from "components/GobackButton";
import { type FC, useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getAT } from "@backend/api/service";
import { LoadingSmall } from "@components/Utils/Loading";

interface MoreInfoPageProps {
  collapsed?: boolean;
}

const MoreInfoPage: FC<MoreInfoPageProps> = ({ collapsed }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  // const { urlId1, urlId2, urlId3, hlp1, hlp2, url1, url2 } = useParams();
  const [url, setUrl] = useState<string>("about:blank");
  //   let fullUrl = `https://www.chorussleep.com/${urlId1}/${urlId2}/${urlId3}?at=${getAT()}&x-app-id=nightmare2023`;

  //   if (hlp1 && hlp2) {
  //       fullUrl = `https://www.chorussleep.com/${hlp1}/${hlp2}`;
  //   }
  //   else if (url1 && url2) {
  //     fullUrl = `https://www.chorussleep.com/${url1}/${url2}`;
  // }

  const view = localSessionGet("view");
  const [searchParams] = useSearchParams();
  const iframeRef = useRef(null);
  const hiddenParam = searchParams.get("hide");
  const staticLink = searchParams.get("link");

  useEffect(() => {
    setUrl("about:blank");
    if (staticLink) {
      setLoading(true);
      let fullUrl = "";
      const qstring = `?x-app-id=nightmare2023&hide=true&at=${getAT()}`;
      fullUrl = `${process.env.REACT_APP_WEB_ENDPOINT}/${staticLink}`;
      // console.log(fullUrl)
      console.log(`*** more info page setting url to ${fullUrl + qstring} ***`);
      let url = fullUrl + qstring;
      setUrl(url);
    }
  }, [staticLink]);

  // useEffect(() => {
  //   let fullUrl = "";
  //   const qstring = `?x-app-id=nightmare2023&hide=true&at=${getAT()}`;
  //   fullUrl = `${process.env.REACT_APP_WEB_ENDPOINT}/${urlId1}/${urlId2}/${urlId3}`;
  //   if (hlp1 && hlp2) {
  //     fullUrl = `${process.env.REACT_APP_WEB_ENDPOINT}/${hlp1}/${hlp2}`;
  //   } else if (url1 && url2) {
  //     fullUrl = `${process.env.REACT_APP_WEB_ENDPOINT}/${url1}/${url2}`;
  //   }
  //   console.log(`*** more info page setting url to ${fullUrl + qstring} ***`);
  //   setUrl(fullUrl + qstring);

  //   // * Changed Dependecies from [] (on mount) to any param change and pathname change.
  //   // * NEW: So that it will refetch data when params change.
  // }, [hlp1, hlp2, location.pathname, url1, url2, urlId1, urlId2, urlId3]);

  // useEffect(() => {
  //   if (view && view === "web") {
  //     searchParams.set('hide', 'true');
  //     setTimeout(() => {
  //       navigate({ pathname: location.pathname, search: searchParams.toString() });
  //     }, 0);
  //   }
  // }, [view])

  useEffect(() => {
    function setIframeHeight(event: MessageEvent<any>) {
      // if (event.origin === process.env.REACT_APP_WEB_ENDPOINT || event.origin === SLEEP_DEV_LINK) {
      const { height }: { height: number | undefined } = event.data;
      // @ts-ignore
      if (iframeRef.current && height) iframeRef.current.style.height = height + "px";
      // } else return;
    }
    window.addEventListener("message", setIframeHeight, false);

    return () => {
      window.removeEventListener("message", setIframeHeight);
    };
  }, []);

  // useEffect(() => {
  //   if (!loading) {
  //     const iframe = iframeRef.current as HTMLIFrameElement | null;
  //     // @ts-ignore
  //     iframe.contentWindow.parent.postMessage({ height: document.body.scrollHeight });
  //   }
  // }, [loading]);

  return (
    <Container className="h-full" showFullHeight={view === "web" || hiddenParam === "true"} showMaringAndPadding={hiddenParam === "true" ? false : true} collapsed={collapsed}>
      {hiddenParam !== "true" && <GobackButton />}
      {loading ? (
        <div className="grid h-full place-items-center">
          <LoadingSmall />
        </div>
      ) : null}
      <div className={`relative max-h-full ${loading ? "h-max" : "min-h-[100dvw]"} overflow-hidden`}>
        <iframe
          ref={iframeRef}
          src={location?.state?.url ? location.state.url : url}
          title="frame"
          loading="eager"
          // allowFullScreen
          onLoad={() => setLoading(false)}
          // className={`mt-3 min-h-[200rem] w-full overflow-hidden ${loading ? 'hidden' : 'block'}`}
          className={`mt-3 w-full ${loading ? "hidden" : "block"}`}
          frameBorder={"0"}
          scrolling="no"
          sandbox="allow-scripts allow-same-origin allow-popups"
          // referrerPolicy="origin"
        />
      </div>
    </Container>
  );
};

export default MoreInfoPage;
