import { LoadingSmall } from "@components/Utils/Loading";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { secondsToMinSec } from "@components/Utils/Functions";
import { teacherURL } from "@components/Utils/Functions";
import { IoIosArrowDown } from "react-icons/io";
import { RiForward10Fill, RiReplay10Fill } from "react-icons/ri";
import { ImStop2 } from "react-icons/im";

import React from "react";
import { useSearchParams } from "react-router-dom";
import { MusicType } from "../MusicPlayer";

interface FullPlayerProps {
  currentSound: any;
  isLoadingMusic: boolean;
  showMiniPlayerButton: boolean;
  isPlayingLesson?: boolean;
  currentTime?: number;
  fullPlayerButtonRef?: any;
  totalDuration: { current: number };
  handleBackward: () => void;
  togglePlay: () => void;
  handleReset: () => void;
  handleForward?: () => void;
  handleShowMiniModal?: () => void;
  staff_status?: "staff" | string;
  musicType?: MusicType;
}

const FullPlayer: React.FC<FullPlayerProps> = ({
  currentSound,
  isLoadingMusic = false,
  isPlayingLesson = false,
  currentTime = 0,
  totalDuration = { current: 0 },
  handleBackward,
  togglePlay,
  handleReset,
  handleForward,
  handleShowMiniModal,
  showMiniPlayerButton,
  staff_status,
  fullPlayerButtonRef,
  musicType,
}) => {
  const [searchParams] = useSearchParams();
  const hiddenParam = searchParams.get("hide");
  return (
    <div
      className={`${hiddenParam === "true" ? "full-height" : "full-player-container"}   bg-darkGray w-[728px] overflow-y-scroll-custom`}
    >
      <div
        style={{
          backgroundImage: `url(${currentSound.background_image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={`w-full relative min-h-[443px] sm:w-[400px] flex items-center flex-col p-4 shadow-md mx-auto full-player-custom ${hiddenParam === "true" ? "full-height" : "full-player"}  pt-[100px]`}
      >
        {showMiniPlayerButton && hiddenParam !== "true" && (
          <IoIosArrowDown
            className="absolute w-6 h-6 cursor-pointer top-4 right-4"
            onClick={handleShowMiniModal}
          />
        )}
        {isLoadingMusic && (
          <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full opacity-50 bg-activeMenu">
            <LoadingSmall />
          </div>
        )}

        {currentSound.author_id && teacherURL && (
          <img
            className="rounded-full"
            src={teacherURL(currentSound.author_id)}
            alt="teacher"
            width="100"
            height="100"
          />
        )}
        <p className="mt-8 text-2xl font-bold">{currentSound.title}</p>
        <p className="mt-6 text-base font-normal">{currentSound.description}</p>
        {currentSound.author_id && (
          <p className="text-sm font-light text-white mt-9 text-opacity-70">
            Teacher
          </p>
        )}

        <p className="mt-3 text-sm text-white capitalize">
          {currentSound.author_id}
        </p>

        <div className="absolute justify-center sm:bottom-5 bottom-12">
          <div className="flex items-start justify-center gap-x-3">
            
            {(musicType !=='sleepSound')  && (
              <div onClick={handleBackward}>
                <RiReplay10Fill className="inline-block w-6 h-6 cursor-pointer" />
              </div>
            )}
            <button
              style={{
                padding: 0,
                margin: 0,
                border: "none",
                backgroundColor: "transparent",
              }}
              ref={fullPlayerButtonRef}
              onClick={togglePlay}
            >
              {isPlayingLesson ? (
                <PauseIcon className="w-8 h-8 text-white cursor-pointer " />
              ) : (
                <PlayIcon className="w-8 h-8 text-white cursor-pointer " />
              )}
            </button>
            <div
              className="flex flex-col items-center text-[10px] cursor-pointer justify-center"
              onClick={handleReset}
            >
              <ImStop2 className="w-6 h-6" />
              <span>Stop / Close</span>
            </div>
            {(musicType !== "sleepSound") ? (
              <div onClick={handleForward}>
                <RiForward10Fill className="inline-block w-6 h-6 cursor-pointer" />
              </div>
            ):null}
          </div>
          {(staff_status === "staff" || musicType === "sleepSession" || musicType==='meditation') && (
            <div className="flex justify-center gap-1 mt-3">
              <p>{secondsToMinSec(Number(currentTime))}</p>
              <span>/</span>
              <p>{secondsToMinSec(Number(totalDuration?.current))}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullPlayer;
