import { format, parseISO } from "date-fns";
import { FC } from "react";
import "./ChatItem.css";

interface ChatItemProps {
  item: {
    from_user: boolean;
    message: string;
    id: string;
    coach_id: string;
    created: string;
  };
}

const ChatItem: FC<ChatItemProps> = ({ item }) => {
  // Parse and format date
  const formattedTime = format(
    parseISO(item.created),
    "EEE MMM dd, hh:mm:ss a",
  );

  // Conditional classes for better readability
  const justifyClass = item.from_user ? "justify-end" : "justify-start";
  const colorClass = item.from_user ? "bg-blueC1" : "bg-darkGray";

  return (
    <div>
      {!item.from_user && (
        <p className="text-white text-opacity-70">{item.coach_id}</p>
      )}
      <div className={`flex mt-3 t ${justifyClass}`} key={item.id}>
        <div className="massage-chat">
          <div
            className={`inline-block message-box rounded-lg ${colorClass} px-3 py-2 text-left break-words`}
          >
            <div className="flex justify-end text-xs">
              <div>{formattedTime}</div>
            </div>
            <p className="my-2 text-sm font-light ">{item.message}</p>
          </div>
          <div className="flex justify-end text-xs pt-2"></div>
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
