import { useUser } from '@backend/hooks/useUser';
import GroupDetail from './GroupDetail';
import { CHART_COLOR, CHART_TYPE } from '@components/Utils/constants/enum';
import CustomChart from './Chart/CustomChart';

/**
 *
 * @param {string|number} val yAxis value
 * @returns {string} Formatted yAxis value.
 * @description This fn() takes yAxis value that is between 0 - 5 (rating) and returns emoji's based on feedback.
 * For val < 2 😟
 * For val < 3 🙁
 * For val < 4 😐
 * For val == 5 😃
 */
function daytimeAndMoodFormatter(val: string | number) {
  if (Number(val) < 2) {
    return '😟 ' + val;
  } else if (Number(val) < 3) {
    return '🙁 ' + val;
  } else if (Number(val) < 4) {
    return '😐 ' + val;
  } else if (Number(val) < 5) {
    return '🙂 ' + val;
  } else if (Number(val) === 5) {
    return '😃 ' + val;
  }
}

const DashboardComponents = ({ statsData }: any) => {
  const {
    data: {
      body: { group: groupData },
    },
  } = useUser();

  const { num_active, num_engaged, dash_charts_approved } = groupData;

  const adoption = Number(((num_engaged / num_active) * 100).toFixed(2));

  let engagmentData = [adoption, 100 - adoption];
  let rawChartData = statsData?.body;

  type ChartSchemaType = {
    [key: string]: {
      title: string;
      chartType: CHART_TYPE;
      chartData?: any;
      processChartType?: string[];
      colors?: string[];
      minY?: number;
      maxY?: number;
      seriesTitle?: string[];
      showEmoji?: boolean;
      additionalInformation?: string;
      yaxisLabel?: string;
      xAxisTitle?: string;
      addPaddingInGraph?: boolean;
      formatter?: (val: any) => string;
      tickAmount?: number;
    };
  };

  const chartSchema: ChartSchemaType = {
    // [NOT CURRENTLY USED]
    mood: {
      title: 'Outcomes: Mood and Irritability   ',
      chartType: CHART_TYPE.LINE,
      showEmoji: false,
      minY: 1,
      maxY: 5,
      tickAmount: 4,
      // @ts-ignore
      formatter: daytimeAndMoodFormatter,
    },
    // [NOT CURRENTLY USED]
    daytime_functioning: {
      title: 'Outcomes: Daytime Functioning (Productivity Proxy)',
      chartType: CHART_TYPE.AREA,
      showEmoji: false,
      minY: 1,
      maxY: 5,
      tickAmount: 4,
      // @ts-ignore
      formatter: daytimeAndMoodFormatter,
    },
    // [NOT CURRENTLY USED] Original chart with top quartile of worst sleeping users. Would need to combine with fall_asleep_area
    // or create/finalize a new chart
    time_to_fall_asleep: {
      title: 'Outcomes: Time to fall asleep',
      chartType: CHART_TYPE.LINE,
      showEmoji: false,
      processChartType: [CHART_TYPE.AREA, CHART_TYPE.AREA],
      seriesTitle: ['All Users', 'Top quartile, longest sleep onset'],
    },
    sleep_duration: {
      title: 'Average Sleep Duration (hours/night)',
      chartType: CHART_TYPE.LINE,
      processChartType: [CHART_TYPE.AREA, CHART_TYPE.LINE, CHART_TYPE.LINE],
      colors: [CHART_COLOR.AVERAGE, CHART_COLOR.DANGER, CHART_COLOR.IDEAL],
      minY: 4,
      maxY: 9,
      seriesTitle: ['Average Sleep Duration', 'Danger Sleep Duration', 'Ideal Sleep Duration'],
      additionalInformation: 'Research shows regular sleep duration under 6 hours leads to many mental, physical, and other issues increasing healthcare cost',
      xAxisTitle: 'Month',
      yaxisLabel: 'Average Hours of Sleep Per Night',
    },
    wake_up_times_area: {
      title: 'Average Number of Awakenings in the Night',
      chartType: CHART_TYPE.LINE,
      showEmoji: false,
      processChartType: [CHART_TYPE.AREA, CHART_TYPE.AREA],
      colors: [CHART_COLOR.WAKE_UP],
      addPaddingInGraph: true,
      xAxisTitle: 'Month',
      yaxisLabel: 'Average Number of Awakenings in the Night',
      minY: 0,
      maxY: 8,
    },
    sleep_duration_bar: {
      title: 'Sleep Duration Distribution',
      chartType: CHART_TYPE.BAR,
      showEmoji: false,
      processChartType: [CHART_TYPE.BAR, CHART_TYPE.BAR],
      colors: [CHART_COLOR.AVERAGE_BLUE],
      yaxisLabel: 'Record Count',
      xAxisTitle: 'Total Hours of Sleep Per Night on Average',
    },
    fall_asleep_area: {
      title: 'Average Time to Fall Asleep',
      minY: 10,
      maxY: 60,
      chartType: CHART_TYPE.LINE,
      showEmoji: false,
      processChartType: [CHART_TYPE.AREA, CHART_TYPE.AREA],
      colors: [CHART_COLOR.WAKE_UP],
      xAxisTitle: 'Month',
      yaxisLabel: 'Average Time to Fall Asleep Minutes',
      addPaddingInGraph: true,
    },
  };

  let processedChartData = [];

  if (rawChartData) {
    for (const item of dash_charts_approved) {
      if (item in chartSchema && item in rawChartData) {
        let chartObject = {
          Component: CustomChart,
          props: chartSchema[item],
          space: ``,
          additionalInformation: chartSchema[item].additionalInformation,
          xAxisTitle: chartSchema[item].xAxisTitle,
        };
        chartObject.props.chartData = rawChartData[item];

        processedChartData.push(chartObject);
      }
    }
  }
  // Comment
  return [
    { Component: GroupDetail, space: 'row-start-1 col-start-1' },
    {
      Component: CustomChart,
      props: {
        labels: ['Platform Usage', 'Incomplete'],
        colors: ['#519dc0', '#91c2d7'],
        title: 'Platform Usage',
        chartData: adoption === 0 || isNaN(adoption) ? null : engagmentData,
        chartType: CHART_TYPE.PIE,
        showDataLabel: true,
      },
      additionalInformation: 'People browsing and exploring the app',
      space: '',
    },
    {
      Component: CustomChart,
      props: {
        labels: ['Deeper Engagement', 'Incomplete'],
        colors: ['#519dc0', '#91c2d7'],
        title: 'Deeper Engagement',
        chartData: [85.5, 14.5],
        chartType: CHART_TYPE.PIE,
        showDataLabel: true,
      },
      additionalInformation: 'People browsing, exploring and taking a specific action in the app',
      space: '',
    },
    ...processedChartData,
  ];
};

export default DashboardComponents;
