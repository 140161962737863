import { useMutation, useQuery } from "react-query";
import { USER_LOGS, USER_META, USER_QUERY, USER_SLEEP_HISTORY, USER_TODAY, USER_SESSON, USER_LOGIN_LT } from "./hooksConstant";
import {
  apiGetMetaData,
  apiGetSleepHistoryData,
  apiGetToday,
  apiGetUser,
  apiGetUserJournalLogs,
  apiUpdateUserSettings,
  apiUpdateUserEveningLogs,
  apiUpdateUsersLogs,
  syncUserTimeZone,
  getSessionById,
  apiPostUserLt,
} from "@backend/api/users";

export const useUser = () => {
  return useQuery([USER_QUERY], () => apiGetUser(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useLoginLt = (lt: string | null) => {
  return useQuery([USER_LOGIN_LT], () => apiPostUserLt(lt), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useToday = () => {
  return useQuery([USER_TODAY], () => apiGetToday(), {
    staleTime: 0, //0.2 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useSleepHistory = () => {
  return useQuery([USER_SLEEP_HISTORY], () => apiGetSleepHistoryData(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useUserLogs = (date: string) => {
  return useQuery([USER_LOGS, date], () => apiGetUserJournalLogs(date), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useModifyUserLogs = (refetch: Function) => {
  return useMutation((data: any) => apiUpdateUsersLogs(data), {
    onSettled: () => {
      refetch();
    },
  });
};

export const useModifyUserEveningLogs = (refetch: Function) => {
  return useMutation((data: any) => apiUpdateUserEveningLogs(data), {
    onSettled: (data, error) => {
      if (data) {
        console.log("useModifyUserEveningLogs was successful, returned data");
      } else if (error) {
        console.log("Mutation encountered an error:", error);
      }
      refetch();
    },
  });
};
export const useUpdateUserSettings = (refetch: Function) => {
  return useMutation((data: any) => apiUpdateUserSettings(data), {
    onSettled: (data, error) => {
      if (data) {
        console.log("useUpdateUserSettings was successful, returned data");
      } else if (error) {
        console.log("Mutation encountered an error:", error);
      }
      refetch();
    },
  });
};

export const useUpdateUserTimeZone = (refetch?: Function) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dataObj = {
    timezone: timeZone,
  };

  const mutation = useMutation((userPayload?: any) => syncUserTimeZone(userPayload || dataObj));

  const updateUserTimeZone = async (userPayload?: any) => {
    try {
      const data = await mutation.mutateAsync(userPayload || dataObj);
      refetch && refetch();
      return data;
    } catch (error) {
      console.log("Mutation encountered an error:", error);
      throw error; // Rethrow the error if you want to catch it in the calling code
    }
  };

  return updateUserTimeZone; // Returns a function that returns a promise
};

export const useUserMeta = () => {
  return useQuery([USER_META], () => apiGetMetaData(), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });
};

export const useSessions = (id: string, currentSound: any) => {
  return useQuery([USER_SESSON], () => getSessionById(id), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    enabled: currentSound === null,
  });
};
