import React, { FC, ReactNode } from "react";

interface IAccordionContainer {
  children?: ReactNode;
  className?: string;
}

const AccordionContainer: FC<IAccordionContainer> = ({
  children,
  className,
}) => {
  return (
    <div
      className={` bg-darkGray sm:p-4 px-2 py-2 rounded-lg ${className}`}
      data-testid="accordion-container"
    >
      {children}
    </div>
  );
};

export default AccordionContainer;
