import { IdcardFilled, SlidersOutlined } from "@ant-design/icons";
import { ChartBarIcon, DocumentTextIcon } from "@heroicons/react/24/solid";
import { MORE_INFO } from "@assets/staticData";
import DesignSystemPage from "pages/DesignSystemPage";
import ExampleLoadingPage from "pages/ExampleLoadingPage";
import CompanyAccount from "pages/Leadership/CompanyAccountPage";
import Dashboard from "pages/Leadership/DashboardPage";
import Resources from "pages/Leadership/ResourcesPage";
import EveningJournalPage from "pages/Sleep/EveningJournalPage";
import JournalView from "pages/Sleep/JournalViewPage";
import LessonDetail from "pages/Sleep/LessonDetail";
import MoreInfoPage from "pages/Sleep/MoreInfoPage";
import MorningJournalPage from "pages/Sleep/MorningJournalPage";
import PastLessonPage from "pages/Sleep/PastLessonPage";
import SettingsPage from "pages/Sleep/SettingsPage";
import SleepCoachPage from "pages/Sleep/SleepCoachPage";
import SleepHistoryPage from "pages/Sleep/SleepHistoryPage";
import SleepTodayPage from "pages/Sleep/SleepTodayPage";
import { AiFillGift, AiOutlineLineChart } from "react-icons/ai";
import { BiHelpCircle, BiMessageDetail } from "react-icons/bi";
import { FiBookOpen, FiSettings, FiBook, FiSun } from "react-icons/fi";
import { GiTeamIdea } from "react-icons/gi";
import AllLessonPage from "@pages/Sleep/AllLessonPage";
import DaytimePage from "@pages/DaytimePage";

export const PageTitleSleepHome = "Sleep Home";
export const PageTitlePastLesson = "View Past Lessons";

export const leaderShipRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    label: "Dashboard",
    icon: ChartBarIcon,
    props: {},
  },
  {
    path: "/resources",
    component: Resources,
    label: "Resources",
    icon: DocumentTextIcon,
    props: {},
  },
  {
    path: "/designsys",
    component: DesignSystemPage,
    props: {},
  },

  {
    path: "/company-account",
    component: CompanyAccount,
    label: "Company Account",
    icon: IdcardFilled,
    props: {},
  },

  {
    path: "/loading-test",
    component: ExampleLoadingPage,
    props: {},
  },
];

export const sleepRoutes = [
  {
    path: "/sleep-today",
    component: SleepTodayPage,
    label: PageTitleSleepHome,
    icon: SlidersOutlined,
    props: {},
  },
  // {
  //   path: '/player',
  //   component: FullplayerPage,
  //   props: {  },
  // },
  {
    path: "/sleep-history",
    label: "Sleep Tracker",
    icon: AiOutlineLineChart,
    component: SleepHistoryPage,
    props: { fromSidebar: true },
  },
  {
    path: "/sleep-coach",
    component: SleepCoachPage,
    props: { fromSidebar: true },
    label: "My Sleep Coach",
    icon: BiMessageDetail,
    isNotification: true,
  },

  {
    path: "https://www.chorussleep.com/team-challenge/?hide=true",
    component: MoreInfoPage,
    props: { newPage: true },
    label: "Register Team Challange",
    icon: GiTeamIdea,
  },

  {
    path: "/past-lessons",
    component: PastLessonPage,
    label: PageTitlePastLesson,
    icon: FiBookOpen,
    props: { fromSidebar: true },
  },
  {
    path: "/all-lessons",
    component: AllLessonPage,
    label: "All Lessons",
    icon: FiBook,
    props: {},
  },
  {
    path: "https://www.surveymonkey.com/r/V72ZWZ2",
    component: MoreInfoPage,
    props: { newPage: true },
    label: "Claim Your Gift",
    icon: AiFillGift,
  },
  {
    path: "/help/",
    // path: "/help/:hlp1/:hlp2",
    component: MoreInfoPage,
    props: { url: MORE_INFO.HELP.url, fromSidebar: true },
    label: "Help & Support",
    icon: BiHelpCircle,
  },
  {
    path: "/settings",
    component: SettingsPage,
    props: {},
    label: "Settings",
    icon: FiSettings,
  },
  {
    path: "/daytime-support",
    component: DaytimePage,
    label: "Daytime Support",
    icon: FiSun,
    props: {},
  },
  {
    path: "/morning-journal",
    component: MorningJournalPage,
    props: {},
  },

  {
    path: "/sleep-history/morning-journal",
    component: MorningJournalPage,
    props: {},
  },

  {
    path: "/sleep-history/journal-view",
    component: JournalView,
    props: {},
  },

  {
    path: "/evening-journal",
    component: EveningJournalPage,
    props: {},
  },

  {
    path: "/sleep-history/evening-journal",
    component: EveningJournalPage,
    props: {},
  },
  {
    path: "/lesson-detail/:id",
    component: LessonDetail,
    props: {},
  },
];
