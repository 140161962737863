import { localGet } from "@backend/storage";
import React, { FC, ReactNode } from "react";

interface IContainer {
  children: ReactNode;
  dataTestId?: string;
  className?: string;
  subClass?: string;
  showMaringAndPadding?: boolean;
  showFullHeight?: boolean;
  collapsed?: boolean;
}

const Container: FC<IContainer> = ({
  children,
  dataTestId,
  className,
  subClass,
  showFullHeight,
  showMaringAndPadding,
  collapsed,
}) => {
  const switchValue = localGet("sleep-portal");
  return (
    <div
      className={`flex w-full ${collapsed ? "justify-center" : "justify-end lg:justify-center"} ${className}`}
      data-testid={dataTestId}
      style={{ height: showFullHeight ? "100vh" : "" }}
    >
      <div
        className={`${subClass} ${showMaringAndPadding ? "mt-5 p-5" : "mt-4 p-1"} flex w-full flex-col sm:w-full md:w-[calc(100vw-300px)] ${switchValue ? "max-w-4xl" : "max-w-[1700px]"} `}
        data-testid="sub-class-div"
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
