import React, { useState, FC, useEffect } from "react";
import AccordionContainer from "./AccordionContainer";
import { TimePicker } from "react-ios-time-picker";
import { convertTime, formatTime } from "components/Utils/Functions";
import { useUpdateUserSettings, useUser } from "backend/hooks/useUser";
import { handleOpenTimePicker } from "@components/Utils/TimePicker";

const WakeupTimeSelection: FC = () => {
  const {
    data: {
      body: { settings },
    },
    refetch,
  } = useUser();

  const mutateUserSettings = useUpdateUserSettings(refetch);

  const [wakeupTime, setWakeupTime] = useState(
    () => formatTime(settings.wake_hour, settings.wake_min) || "07:00 AM",
  );

  useEffect(() => {
    setWakeupTime(
      formatTime(settings.wake_hour, settings.wake_min) || "07:00 AM",
    );
  }, [settings]);

  const onChange = (timeValue: string) => {
    setWakeupTime(timeValue);
  };

  const handleSave = async (data: string) => {
    const { hours, minutes } = convertTime(data);

    const apiData = { ...settings, wake_hour: hours, wake_min: minutes };

    await mutateUserSettings.mutate(apiData);
  };

  return (
    <AccordionContainer>
      <div onClick={handleOpenTimePicker}>
        <TimePicker
          onOpen={handleOpenTimePicker}
          onChange={onChange}
          value={wakeupTime}
          use12Hours
          inputClassName="border-none cursor-pointer small-white-text"
          id="wakeuptime"
          name="wakeuptimepicker"
          onSave={handleSave}
        />
      </div>
    </AccordionContainer>
  );
};

export default WakeupTimeSelection;
