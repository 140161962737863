import React, { FC } from "react";
import { FaCircle } from "react-icons/fa";
import { AiFillCheckSquare, AiFillStar } from "react-icons/ai";

interface KeyInsightsProps {
  data: {
    icon: string;
    content: string;
    title: string;
  };
}

const KeyInsights: FC<KeyInsightsProps> = (props) => {
  const {
    data: { icon, content, title },
  } = props;

  const iconList: {
    [key: string]: JSX.Element;
  } = {
    green_check: <AiFillCheckSquare className="text-green" />,
    blue_star: <AiFillStar className="text-blue-500" />,
    red_dot: <FaCircle className="text-red-500 " />,
  };

  return (
    <div className="flex items-start mb-3">
      <div className="flex items-center">
        <div className="h-4 w-4" data-testid={`${icon}-icon`}>
          {iconList[icon]}
        </div>
        <p className="ml-1 font-bold">{title}:&nbsp;&nbsp;</p>
      </div>
      <div
        className="key-insight"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default KeyInsights;
