import { Component, ErrorInfo, ReactNode } from 'react';
import ErrorBox from './Utils/ErrorBox';

class ErrorBoundary extends Component<{ children: ReactNode }> {
  state: { error: Error | boolean; message: string | null } = {
    error: false,
    message: null,
  };
  static getDerivedStateFromError(error: Error) {
    console.log('Error, ', error);
    return { error, message: error.message };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error(error);
  }
  render(): ReactNode {
    if (this.state.error) {
      return <ErrorBox msg={<span className="font-semibold text-red-500">"{this.state.message}"</span>} />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
