let timePickerInView = true;
let observer: MutationObserver;

export const handleOpenTimePicker = () => {
  document.body.style.overflow = "hidden";
  timePickerInView = true;
  observer = new MutationObserver(checkElement);
  observer.observe(document.body, { childList: true, subtree: true });
};

const checkElement = () => {
  const element = document.querySelector(".react-ios-time-picker"); // Change the selector accordingly
  if (element || timePickerInView) {
    timePickerInView = false;
  } else {
    document.body.style.overflow = "";
    timePickerInView = false;
    observer.disconnect();
  }
};
