import { MORE_INFO } from "@assets/staticData";
import CustomLink from "@components/General/CustomLink";
import SectionHeading from "@components/SectionHeading";
import { Fragment, type FC } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";

interface ISleepScoreChartObject {
  label: string;
  point: number;
  user_confirmed: boolean;
}

interface ISleepScoreChartProps {
  data?: ISleepScoreChartObject[];
}

const SleepScoreChart: FC<ISleepScoreChartProps> = (props) => {
  const { data } = props;
  let labels: string[] = [];
  let points: number[] = [];
  const navigate = useNavigate();
  const dataArray =
    data && data.length > 0 ? [...data.slice(0, 7)].reverse() : [];

  dataArray.forEach((item) => {
    labels.push(item.label);
    points.push(item.point);
  });

  interface State {
    options: {
      labels?: string[];
      colors?: string[];
      responsive: {
        breakpoint: number;
        options: {
          legend: {
            position: "bottom";
          };
        };
      }[];
      stroke: {
        show: boolean;
        colors: string[];
        curve: "smooth" | "straight" | "stepline";
      };
      xaxis: any;
      tooltip: {
        theme: "dark";
      };
      yaxis: any;
      grid: any;
    };
    series: any;
  }

  const state: State = {
    options: {
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      // stroke: {
      //   show: true,
      //   colors: ['#c091d7', '#51bfc0', '#bfc051', '#d7c091'],
      //   curve: 'smooth',
      // },

      colors: ["#767090"], // Changed line color to blue
      stroke: {
        show: true,
        colors: ["#187ddc"], // Changed stroke color to blue
        curve: "smooth",
      },

      xaxis: {
        categories: labels,
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      tooltip: {
        theme: "dark",
      },
      yaxis: {
        min: 0,
        max: 100,
        tickAmount: 4,
        labels: {
          formatter: (value: number) => `${value}%`,
          style: {
            colors: "#fff",
          },
        },
      },
      grid: {
        borderColor: "#282828",
      },
    },

    series: [
      {
        name: "Sleep Score",
        data: points,
      },
    ],
  };

  const handleSeePast = () => {
    navigate("/sleep-history", { state: { from: "Sleep Home" } });
  };

  return (
    <Fragment>
      <div className="flex items-center justify-between">
        <SectionHeading
          heading="Track & Measure"
          contentUrl={MORE_INFO.TRACK_URL}
          from="Sleep Home"
          id="track-measure"
        />
        <CustomLink label="See Past" handleClick={handleSeePast} />
      </div>
      <h3
        className="text-base sm:text-lg mt-4 text-lightYellowC1_2"
        id="sleep-score"
      >
        Sleep Score
      </h3>
      <div
        data-testid="sleep-score-chart"
        className="customized-chart mb-2 h-[250px] sm:h-[400px]"
      >
        <ReactApexChart
          options={state.options}
          series={state.series}
          type={"line"}
          width="100%"
          height="100%"
        />
      </div>

      <CustomLink
        label="See Sleep History"
        handleClick={handleSeePast}
        className="text-lg mb-4"
      />
    </Fragment>
  );
};

export default SleepScoreChart;
