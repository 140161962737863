/* eslint-disable react-hooks/exhaustive-deps */
import { localPut } from "backend/storage";
import LabelView from "components/General/LabelView";
import { convertToHoursAndMinutes } from "components/Utils/Functions";
import { format, parseISO } from "date-fns";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import InfoBlock from "../InfoBlock";

interface ISleepHistoryEditLogs {
  data: any;
}

const SleepHistoryEditLogs: FC<ISleepHistoryEditLogs> = ({ data }) => {
  const date = parseISO(data.day);
  const formatted = format(date, "EEE, MMM dd");
  const navigate = useNavigate();

  const handleMorningLogs = useCallback(() => {
    navigate("/sleep-history/morning-journal", {
      state: { journalDate: date, from: "Sleep History" },
    });
  }, [date, navigate]);

  const handleEveningLogs = useCallback(() => {
    navigate("/sleep-history/evening-journal", {
      state: { journalDate: date, from: "Sleep History" },
    });
  }, [date, navigate]);

  const handleSeeMore = useCallback(() => {
    navigate("/sleep-history/journal-view", {
      state: { journalObj: data, from: "Sleep History" },
    });
    localPut("JournalData", data);
  }, [date, navigate]);

  return (
    <div>
      <div className="mb-9">
        <div className="bg-darkGray2 p-4 rounded-lg shadow cursor-pointer mb-2 ">
          <p>{formatted}</p>
          {data?.sleep_log_confirmed && (
            <div className="flex justify-center">
              <InfoBlock title="Sleep Score" value={data.sleep_score} />
            </div>
          )}
          <div
            className={`flex ${data?.sleep_log_confirmed ? "justify-between" : "justify-end"}`}
          >
            {data?.sleep_log_confirmed && (
              <InfoBlock
                title="Total Time Asleep"
                value={convertToHoursAndMinutes(data.asleep_duration)}
              />
            )}
            {data?.irritable_choice || data?.progress_choice ? (
              <InfoBlock title="Wellbeing Score" value={data.day_score} />
            ) : null}
          </div>
          <div className="flex flex-nowrap gap-1 justify-between mt-2 items-center">
            <button
              className="rounded-lg bg-lightGray9 sm:p-2 px-2 py-1 sm:min-w-[180px] text-xs sm:text-base md:text-lg"
              onClick={handleMorningLogs}
            >
              Edit Morning
            </button>
            {data?.sleep_log_confirmed && (
              <div onClick={handleSeeMore}>
                <LabelView value="See More" />
              </div>
            )}

            <button
              className="rounded-lg bg-lightGray9 sm:p-2 px-2 py-1 sm:min-w-[180px] text-xs sm:text-base md:text-lg"
              onClick={handleEveningLogs}
            >
              Edit Evening
            </button>
          </div>
        </div>

        {!data?.sleep_log_confirmed && (
          <div className="flex justify-center">
            <p className="text-sm text-opacity-70 font-light text-white text-center">
              * Tap "edit morning log" to confirm
            </p>
          </div>
        )}
        <div className="border border-white opacity-30 w-1/2 m-auto mt-3 "></div>
      </div>
    </div>
  );
};

export default SleepHistoryEditLogs;
