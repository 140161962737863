/* eslint-disable react-hooks/exhaustive-deps */
import CaptionTitle from "components/CaptionTitle";
import Container from "components/Container";
import GobackButton from "components/GobackButton";
import {
  progressEmojiList,
  sleepPlayEmojiList,
} from "components/SleepToday/Journal/EveningJournalConstant";
import {
  convertTo24HourFormat,
  convertToHoursAndMinutes,
  convertToMinutes,
  findEmoji,
  formatDate,
  formatTime,
} from "components/Utils/Functions";
import { format } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useModifyUserEveningLogs,
  useSleepHistory,
  useToday,
  useUserLogs,
} from "@backend/hooks/useUser";
import CustomButton from "@components/CustomButton";
import JournalEmojiCard from "@components/SleepToday/Journal/JournalEmojiCard";
import JournalTextInput from "@components/SleepToday/Journal/JournalTextInput";
import NapTimeCard from "@components/SleepToday/Journal/NapTimeCard";
import { LoadingPage } from "@components/Utils/Loading";
import { anaScreen } from "@backend/ana";

interface EveningJournalPageProps {
  collapsed?: boolean;
}

const EveningJournalPage: FC<EveningJournalPageProps> = ({ collapsed }) => {
  const [selectedProgressEmoji, setSelectedProgressEmoji] =
    useState<string>("");
  const [selectedPlayEmoji, setSelectedPlayEmoji] = useState<string>("");
  const [journalText, setJournalText] = useState<string>("");
  const [dayScore, setDayScore] = useState(null);
  const [napTime, setNapTime] = useState("");
  const [asleepDuration, setAsleepDuration] = useState("");
  const [formattedDate, setFormattedDate] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();

  const stateDate = location.state?.journalDate;
  const apiDate = stateDate
    ? format(stateDate, "yyyy-MM-dd")
    : format(new Date(), "yyyy-MM-dd");

  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const { refetch: refetchUseToday } = useToday();
  const { refetch: refetchSleepHistory } = useSleepHistory();

  const { data, isLoading, isError, refetch } = useUserLogs(apiDate);
  const modifyLogs = useModifyUserEveningLogs(refetch);

  const fetchApiData = (data: any) => {
    // get the nap hours and min and if both value is 0 set 14:00
    let nap_hour_val = data.body.nap_hour;
    let nnap_min_val = data.body.nap_min;
    if (nap_hour_val === 0 && nnap_min_val === 0) {
      nap_hour_val = 14;
    }

    return {
      day: formatDate(data.body.day),
      napTime: formatTime(nap_hour_val, nnap_min_val),
      napDuration: convertToHoursAndMinutes(data.body.nap_duration),
      progressEmoji: findEmoji(progressEmojiList, data.body.progress_choice),
      playEmoji: findEmoji(sleepPlayEmojiList, data.body.irritable_choice),
      dayScore: data.body.day_score,
      journalText: data.body.journal_day,
    };
  };

  useEffect(() => {
    anaScreen("evening_journal", null);
  }, []);

  useEffect(() => {
    if (data?.body) {
      const apiData = fetchApiData(data);
      setFormattedDate(apiData.day);
      setNapTime(apiData.napTime);
      setAsleepDuration(apiData.napDuration);
      setSelectedProgressEmoji(apiData.progressEmoji || "");
      setSelectedPlayEmoji(apiData.playEmoji || "");
      setDayScore(apiData.dayScore);
      setJournalText(apiData.journalText);
    }
  }, [data]);

  const handelJournalTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setJournalText(e.target.value);
  };

  const handelSave = () => {
    let progressEmojiExist = progressEmojiList.find(
      (item) => item.title === selectedProgressEmoji,
    );
    let sleepPlayEmojiExist = sleepPlayEmojiList.find(
      (item) => item.title === selectedPlayEmoji,
    );

    const { hours, minutes } = convertTo24HourFormat(napTime);
    const apiData = {
      progress_choice: progressEmojiExist ? progressEmojiExist.apiValue : "",
      irritable_choice: sleepPlayEmojiExist ? sleepPlayEmojiExist.apiValue : "",
      nap_duration: convertToMinutes(asleepDuration),
      nap_hour: hours,
      nap_min: minutes,
      journal_day: journalText,
      day: data.body.day,
    };
    modifyLogs.mutate(apiData);
    setIsSaveClicked(true);
  };

  useEffect(() => {
    if (
      !modifyLogs.isLoading &&
      modifyLogs.isError === false &&
      isSaveClicked
    ) {
      refetchUseToday();
      refetchSleepHistory();
      navigate(-1);
    }
  }, [modifyLogs.isLoading, modifyLogs.isError, isSaveClicked]);

  if (isLoading || modifyLogs.isLoading) {
    return <LoadingPage />;
  }

  if (isError) {
    return (
      <div className="flex w-full justify-center">
        <div className="mt-5 w-full sm:w-full md:w-[640px] lg:w-[768px] flex justify-center ">
          <p className="text-white text-2xl mt-8">Invalid Day...</p>
        </div>
      </div>
    );
  }

  return (
    <Container collapsed={collapsed}>
      <GobackButton />
      <div className="flex flex-col items-center">
        <p className="text-white text-sm font-light text-center">
          How was your day today, {formattedDate}
        </p>
        <p className="text-white text-sm font-semibold text-center">
          Make your selections and confirm at the bottom
        </p>
        <p className="text-white text-sm font-semibold mt-5">
          Wellbeing Score: {dayScore}
        </p>
      </div>
      <div className="mt-9 mb-2">
        <CaptionTitle title="How well did you progress through your day?" />
      </div>
      <JournalEmojiCard
        minHeight="min-h-[115px]"
        emojiList={progressEmojiList}
        selectedEmoji={selectedProgressEmoji}
        setSelectedEmoji={setSelectedProgressEmoji}
      />
      <div className="mt-9">
        <CaptionTitle title="How irritable did you feel today?" />
      </div>
      <JournalEmojiCard
        minHeight="min-h-[115px]"
        emojiList={sleepPlayEmojiList}
        selectedEmoji={selectedPlayEmoji}
        setSelectedEmoji={setSelectedPlayEmoji}
      />
      <CaptionTitle
        title="When and how long did you nap?"
        className="mt-9 pb-3"
      />
      <NapTimeCard
        napTime={napTime}
        setNapTime={setNapTime}
        asleepDuration={asleepDuration}
        setAsleepDuration={setAsleepDuration}
      />
      <CaptionTitle title="Write your own journal" className="mt-9 pb-3" />
      <JournalTextInput
        value={journalText}
        onChange={handelJournalTextChange}
      />
      <div className="flex justify-center mt-10 mb-10">
        <CustomButton className="w-[200px]" onClick={handelSave}>
          Save Changes
        </CustomButton>
      </div>
    </Container>
  );
};

export default EveningJournalPage;
