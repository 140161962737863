import React from "react";
import { TOAST_TYPE, TOAST_POSITION } from "./constants/enum";
import AlertIcon from "@components/Icons/AlertIcon";

interface ToastProps {
  message: string;
  close: () => void;
  toastType?: TOAST_TYPE;
  position?: TOAST_POSITION;
}

const Toast: React.FC<ToastProps> = (props) => {
  const {
    message,
    close,
    toastType,
    position = TOAST_POSITION.BOTTOM_CENTER,
  } = props;

  return (
    <div
      className={`fixed p-4 flex items-center justify-center z-50 ${position}`}
    >
      <div className="min-w-[250px] bg-white  rounded-md p-4 md:p-6 flex flex-col md:flex-col items-center space-y-4 md:space-y-4  md:space-x-0">
        <AlertIcon iconType={toastType} />
        <p className="font-medium text-gray-600">{message}</p>
        <button
          className="text-gray-600 underline font-semibold"
          onClick={close}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Toast;
