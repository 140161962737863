/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FC, useEffect } from "react";
import AccordionContainer from "./AccordionContainer";
import VerticalTabs from "./VerticalTabs";
import { useUser, useUpdateUserSettings } from "@backend/hooks/useUser";
import { IQuestionData } from "./AgeSelection";
import { getQuestions } from "@pages/Sleep/SettingsPage";

interface IGenderSelectionProps {
  data: IQuestionData;
}

const GenderSelection: FC<IGenderSelectionProps> = ({ data }) => {
  const { refetch, data: { body: { settings } } = { body: { settings: {} } } } =
    useUser();

  const mutateUserSettings = useUpdateUserSettings(refetch);

  const tabs = data.choices;

  const [selectedTab, setSelectedTab] = useState(settings?.sex || "");

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };
  let questions = getQuestions(data, 2);
  const updateUserGender = () => {
    const apiData = { ...settings, sex: selectedTab };
    mutateUserSettings.mutate(apiData);
  };

  useEffect(() => {
    if (selectedTab !== settings.sex) {
      updateUserGender();
    }
  }, [selectedTab]);

  return (
    <AccordionContainer>
      {questions && Array.isArray(questions) && questions.length >= 2 && (
        <>
          <p
            className="sm:text-xl text-base small-white-text"
            data-testid="question1gender"
          >
            {questions[1]}
          </p>
          <p className="text-sm small-white-text" data-testid="question2gender">
            {questions[0] + "."}
          </p>
        </>
      )}

      <div className="flex flex-col items-center">
        <VerticalTabs
          tabs={tabs}
          selected={selectedTab}
          onChange={handleTabChange}
        />
      </div>
    </AccordionContainer>
  );
};

export default GenderSelection;
