import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import LessonCard from "./LessonCard";
import { MORE_INFO } from "@assets/staticData";
import CustomLink from "@components/General/CustomLink";
import NextLessonCard from "./NextLessonCard";
import SectionHeading from "@components/SectionHeading";

type TLesson = {
  title: string;
  day: string;
  duration: number;
  id: string;
};

interface IPastLesson {
  lessons: TLesson[];
}

const PastLesson: FC<IPastLesson> = (props) => {
  const { lessons } = props;
  const navigate = useNavigate();
  const handleViewPastLesson = () => {
    navigate("/past-lessons");
  };

  const allCompleted = (arr: any) => {
    return arr.every((obj: any) => obj?.progress?.completed === true);
  };

  let isAllCompleted = allCompleted(lessons);

  return (
    <div
      className="mb-3 mt-3"
      id="lessons"
      // id="past-lessons"
    >
      <div className="mb-2 mt-2 flex items-center justify-between">
        <h3 className="text-base text-lightYellowC1_2 sm:text-lg" id="lessons">
          Lessons
        </h3>
        {/* <p> </p> */}
      </div>
      <CustomLink label="View past lessons" handleClick={() => handleViewPastLesson()} />
      {lessons &&
        lessons.map((item: any) => {
          const { title, day, duration, id, progress, total_items } = item;
          return (
            <Link to={`/lesson-detail/${id}`} key={id} state={{ from: "Sleep Home" }}>
              <LessonCard title={title} day={day} key={id} id={id} duration={duration} progress={progress} totalItems={total_items} />
            </Link>
          );
        })}
      {isAllCompleted && <NextLessonCard />}
    </div>
  );
};

export default PastLesson;
