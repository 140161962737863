import { useQuery } from "react-query";
import { STATS_QUERY } from "./hooksConstant";
import { apiGetChartData } from "@backend/api/users";

export type Filters = {
  gender?: string;
  division?: string;
  department?: string;
};

/**
 *
 * @returns Filters state object that has value of filters on change and will return API response for Stats.
 */
const useStats = (filters: Filters) => {
  return useQuery(
    // queryKey will cache result based on filters. At first when no filters selected "stats",null,null,null will be used and when any of the filters are changed then they update accordingly. for example: "stats",'ADMI','male',null.
    [STATS_QUERY, filters.division, filters.gender, filters.department],
    () => {
      return apiGetChartData(filters);
    },
    {
      staleTime: 0, //5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    }
  );
};

export default useStats;
