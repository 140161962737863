export interface ISwitchReducer {
  switchToSleepToday: boolean;
}

export const SWITCH_STATES = {
  SWITCH_TO_SLEEP: "SWITCH_TO_SLEEP",
  SWITCH_TO_LEADERSHIP: "SWITCH_TO_LEADERSHIP",
} as const;

export type ISwitchAction =
  | { type: typeof SWITCH_STATES.SWITCH_TO_SLEEP }
  | { type: typeof SWITCH_STATES.SWITCH_TO_LEADERSHIP };

export function switchReducer(
  state: ISwitchReducer,
  action: ISwitchAction,
): ISwitchReducer {
  switch (action.type) {
    case SWITCH_STATES.SWITCH_TO_SLEEP:
      return { ...state, switchToSleepToday: true };
    case SWITCH_STATES.SWITCH_TO_LEADERSHIP:
      return { ...state, switchToSleepToday: false };
    default:
      throw new Error();
  }
}
