import { ReactNode, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useLocation, useMatch, useNavigate, useSearchParams } from 'react-router-dom';

import ErrorBox from '@components/Utils/ErrorBox';
import { LoadingPage } from '@components/Utils/Loading';
import LoginRegPage from '@pages/LoginRegPage';
import { localGet, localPut, localSessionGet } from './storage';

import { isStaff, isB2BGroup } from '@components/Utils/Functions';
import { isPathWhitelisted, extractAndStoreToken } from '@routes/routes';
import DisabledDashboard from '@components/DisabledDashboard';
import { getAT } from './api/service';
import { apiGetUser, apiPostUserLt } from './api/users';
import { USER_QUERY } from './hooks/hooksConstant';
import ConfirmLogin from '@pages/ConfirmLogin';
import { isSleepPortal } from 'App';
import { anaInit } from './ana';
// import {ReactQueryDevtools} from 'react-query/devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof Error && error.name === 'LoggedOutError') {
          return false;
        } else {
          return failureCount < 3;
        }
      },
    },
  },
});

interface AppPageWrapProps {
  children: ReactNode;
}

/**
 *
 * @description Query Provider and Auth Wrapper function.
 */
export function AppPageWrap(props: AppPageWrapProps): JSX.Element {
  // Initialize analytics asap so we're ready to go for getUser
  anaInit();

  return (
    <QueryClientProvider client={queryClient}>
      <HandleAuth>{props.children}</HandleAuth>
      {/* <ReactQueryDevtools/> */}
    </QueryClientProvider>
  );
}

interface HandleAuthProps {
  children: ReactNode;
}

/**
 *
 * @description Handles the user API call and navigates based on API response.
 *
 * In case of loading, a Spinner is shown.
 *
 * In case of error, an error message box is shown.
 *
 * In case of Disabled user, Disabled dashboard is shown.
 */
function HandleAuth(props: HandleAuthProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const isMatch = useMatch('/');
  const [searchParams] = useSearchParams();
  const hiddenParam = searchParams.get('hide');
  // * NEW REQ: Instead of page for confirmation we will use a dialog for confirmation of login-lt.
  const [showConfirmLoginModal, setShowConfirmLoginModal] = useState(false);

  // * This function is used for both login with lt and login without lt. If there is lt in search params then we will call loginLt fn to fetch user else simple users call.
  /**
   * @description
   * This function is used to make API call based on search param.
   *
   * If there is ?lt present and localStorage is empty then then it make login-lt API call.
   *
   * else it makes simple get user call.
   *
   * @param {URLSearchParams} searchParams Search parameters of location.
   */
  async function fetchUserData(searchParams: URLSearchParams) {
    if (searchParams.has('lt') && !getAT()) {
      return await apiPostUserLt(searchParams.get('lt')).catch(async () => {
        localPut('at', searchParams.get('lt'));
        return await apiGetUser();
      });
    } else {
      return await apiGetUser();
    }
  }

  // * Defining cache time.
  const { isLoading, data, error, isError, refetch } = useQuery([USER_QUERY], () => fetchUserData(searchParams), {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
  });

  if (isPathWhitelisted(location.pathname) && searchParams.has('at')) {
    extractAndStoreToken(location.search);
  }

  /**
   * @description
   * This fn() will trigger on mount and check for hashes and search params.
   *
   * Now there are some scenarios where we can get simple search params using window.location.search or searchParams.
   *
   * But there is this one case where both the hash and searchParams will be added to url.
   *
   * In this case the searchParams are null and hash has #id?lt=asdsadsad132. so we will split string into two parts.
   *
   * One with ? search params and other with # id.
   */
  async function handleCheckLtLogin() {
    // * creating URL object from current link.
    let url = new URL(window.location.href);
    // * if there is hash in URL.
    if (url.hash.length > 0) {
      // * Split the hash and searchParams.
      let [hash, searchParamsURL] = url.hash.split('?');
      // * If there is search Param? Then set searchParamsURL to this one.
      if (searchParamsURL?.length > 0) {
        // * If there are already search params but some got mixed with hash so append them
        if (window.location.search.length > 0) {
          window.location.search += `&${searchParamsURL}`;
        }
        // * If there were no search params but some got mixed up with hash so replace the search with this one.
        else {
          window.location.search = `?${searchParams}`;
        }
      }
      // * if it has hash then update the hash to the original hash without searchParams.
      if (hash?.length > 0) window.location.hash = hash;
    }
    // * If the searchParam is lt.
    if (url.searchParams.has('lt')) {
      // console.log(window.location);
      // * place current token to check if user is logged in or not.
      let localToken = getAT();
      // * take the lt token out of search params.
      let token = url.searchParams.get('lt');
      // * Remove the lt token from searchParams / url.
      url.searchParams.delete('lt');
      // * update the hash
      if (url.hash) url.hash = url.hash.split('?')[0];
      // * Update the URL.
      window.history.replaceState({}, document.title, url.toString());
      // * New Login Implementation.
      // * If we get a new token as search Params at address ?at=  then check the following.
      // * if the user is already logged in do nothing.
      // * else send a new request to API endpoint
      if (!localToken) {
        // * If the user is not logged in previously. then show confirmation dialog because we have already made the request above depending upon searchParams.
        // * Log them in!
        localPut('at', token);
        // * DONE
        // ! DON'T do this as this forces several views into hide=true which we don't want on the web, only on android
        // * DONE
        // TODO show modal popup on top of page, not redirect them here
        // localPut('confirm-login','false')
        setShowConfirmLoginModal(true);
      }
    }
    // * If the user is using the lt version to login then it is possible that he may change the URL and go anywhere without clicking on Continue button so we will check if the user was logged in using lt method and then we will show the modal every time if it was until he clicks continue
    // ? This Won't work now as we don't want to show him every time even on reload.
    if (localGet('confirm-login') === 'false') {
      setShowConfirmLoginModal(true);
    }
  }
  // * Check lt token on every mount or refresh
  useEffect(() => {
    handleCheckLtLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = data?.body?.user ?? null;
  const group = data?.body?.group ?? null;
  const view = localSessionGet('view');

  useEffect(() => {
    const isStaff = user?.staff_status === 'staff' ?? false;
    const dayTimeEnabled = group?.daytime_enabled;
    // ** Local storage for sleep-portal will NOT be set yet. It get get set AFTER this call!
    // So until we fix that properly, we need to DEFAULT to sleep-portal true
    // TODO get this from one central place like the App.tsx.isSleepPortal()
    // * ----------------------------
    //  ! DONE. Tell me to remove comments later if this is good enough;
    const sleepPortal = isSleepPortal();

    // const sleepPortal = localGet("sleep-portal") || searchParams.get("sleep-portal")==="true" || true;
    const baseRoute = sleepPortal ? '/sleep-today' : '/dashboard';
    const routeParams = hiddenParam ? `?hide=${hiddenParam}` : '';
    // console.log(`********** useEffect for login with params [isError ${isError}] [error ${error}] [data ${data}] [isMatch ${isMatch}] [isStaff ${isStaff}] [location path ${location.pathname}] [day enabled ${dayTimeEnabled}] [route params ${routeParams}] [baseRoute ${baseRoute}] [useremail ${user?.email}] [group name ${group?.name}] [hidden ${hiddenParam}] [sleepPortal ${sleepPortal}]`)

    // Previous code implementation.
    // This is used to navigate the user based on error and data.
    if (user) {
      if (!isError && !error && data && isMatch) {
        navigate(`${baseRoute}${routeParams}`);
      } else if (!isStaff && location.pathname === '/all-lessons') {
        navigate(`${baseRoute}${routeParams}`);
      } else if (!dayTimeEnabled && location.pathname.includes('daytime-support')) {
        navigate(`${baseRoute}${routeParams}`);
      }
    }
  }, [isError, error, data, isMatch, navigate, user, location.pathname, group, hiddenParam, searchParams]);

  // * if the query is loading, show loader / spinner.
  if (isLoading) {
    return <LoadingPage />;
  } else {
    // * if there was no Auth token then redirect to login.
    if (data === 'No auth token present') {
      return <LoginRegPage refetch={refetch} />;
    }
    // * if there is any error, show custom error box component
    if (isError) {
      return <ErrorBox msg={error} />;
    }

    if ((!user || !group) && isError) {
      return <ErrorBox msg={'There was an error finding your user'} />;
    }
    if (!isStaff(user)) {
      if (!view && !isB2BGroup(group)) {
        return <DisabledDashboard email={user?.email} />;
      }
    }
    // * Converted the modal (dialog) to a notification like bottom right popup.
    return (
      <>
        {showConfirmLoginModal ? <ConfirmLogin changeModalStatus={setShowConfirmLoginModal} className="login-confirm-dialog" /> : null}
        {/* </dialog> */}
        {props.children}
      </>
    );
  }
}
