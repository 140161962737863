import {
  convertToHoursAndMinutes,
  formatTime,
} from "components/Utils/Functions";
import { AiOutlineLineChart } from "react-icons/ai";
import { BiBed } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";

export const sleepProgressEmojiList = [
  {
    icon: "veryPoor",
    title: "Bad",
    phrase: "I got a lot less done then usual",
    apiValue: "bad",
  },
  {
    icon: "poor",
    title: "Not So Good",
    phrase: "I got through my day",
    apiValue: "poor",
  },
  {
    icon: "fair",
    title: "Okay",
    phrase: "I got most of my daily tasks done",
    apiValue: "fair",
  },
  {
    icon: "good",
    title: "Good",
    phrase: "I was able to take on a bit more than usual",
    apiValue: "good",
  },
  {
    icon: "veryGood",
    title: "Great",
    phrase: "I was able to take on much more then usual",
    apiValue: "great",
  },
];

export const progressEmojiList = [
  {
    icon: "dontKnow",
    title: `I don't know`,
    apiValue: "dont_know",
  },
  {
    icon: "veryPoor",
    title: "Bad",
    phrase: "I got a lot less done then usual",
    apiValue: "bad",
  },
  {
    icon: "poor",
    title: "Not So Good",
    phrase: "I got through my day",
    apiValue: "not_good",
  },
  {
    icon: "fair",
    title: "Okay",
    phrase: "I got most of my daily tasks done",
    apiValue: "ok",
  },
  {
    icon: "good",
    title: "Good",
    phrase: "I was able to take on a bit more than usual",
    apiValue: "good",
  },
  {
    icon: "veryGood",
    title: "Great",
    phrase: "I was able to take on much more then usual",
    apiValue: "great",
  },
];

export const sleepPlayEmojiList = [
  {
    icon: "dontKnow",
    title: `I don't know`,
    apiValue: "dont_know",
  },
  {
    icon: "veryPoor",
    title: "Very Much",
    phrase: "I felt irritable for almost the whole day",
    apiValue: "very_much",
  },
  {
    icon: "poor",
    title: "Quite a bit",
    phrase: "I felt irritable for more than half of the day",
    apiValue: "quite_a_bit",
  },
  {
    icon: "fair",
    title: "Some what",
    phrase: "I felt irritable during some parts of the day",
    apiValue: "somewhat",
  },
  {
    icon: "good",
    title: "A little bit",
    phrase: "I rerely felt irritable",
    apiValue: "a_little_bit",
  },
  {
    icon: "veryGood",
    title: "Not at all",
    phrase: "I did not feel irritable at all",
    apiValue: "not_at_all",
  },
];

export const journalDetailCardsData = (stateObj: any) => {
  return [
    {
      icon: <AiOutlineLineChart className="text-2xl text-themeBlue" />,
      color: "text-themeBlue",
      label: "Scores",
      fieldsArr: [
        {
          label: "Sleep Score",
          value: stateObj?.sleep_score || 0,
        },
        {
          label: "Wellbeing Score",
          value: stateObj?.day_score || 0,
        },
      ],
    },
    {
      icon: <BiBed className="text-2xl text-journal-orange" />,
      color: "text-journal-orange",
      label: "In Bed",
      fieldsArr: [
        {
          label: "In Bed",
          value: formatTime(stateObj?.in_bed_hour, stateObj?.in_bed_min) || "",
        },
        {
          label: "Time Asleep",
          value: formatTime(stateObj?.asleep_hour, stateObj?.asleep_min) || "",
        },
        {
          label: "Total Time Asleep",
          value: convertToHoursAndMinutes(stateObj?.asleep_duration) || "",
        },
      ],
    },
    {
      icon: <BsEyeFill className="text-2xl text-journal-red" />,
      color: "text-journal-red",
      label: "Awake",
      fieldsArr: [
        {
          label: "Number of Times Awake",
          value: stateObj?.wake_up_times || "",
        },
        {
          label: "Total Amount of Time Times Awake",
          value: stateObj?.wake_up_duration || "",
        },
      ],
    },
    {
      icon: <BiBed className="text-2xl text-journal-green" />,
      color: "text-journal-green",
      label: "Wake Up",
      fieldsArr: [
        {
          label: "Wake Up",
          value:
            formatTime(stateObj?.wake_up_hour, stateObj?.wake_up_min) || "",
        },
        {
          label: "Got Out of Bed",
          value:
            formatTime(stateObj?.out_of_bed_hour, stateObj?.out_of_bed_min) ||
            "",
        },
      ],
    },
  ];
};
