import React, { FC, Fragment } from "react";
import DayCard from "../DayCard";
import Slider from "@components/SleepToday/Slider";
import { MUSIC_STATES, IMusicAction } from "@reducers/musicReducer";

interface IDayCardSlider {
  data: any;
  title?: string;
  subTitle?: string;
  cardType: string;
  width?: number;
  height?: number;
  testId: string;
  scrollKey: string;
  setMusic?: (action: IMusicAction) => void;
  targetID?: string;
}

const DayCardSlider: FC<IDayCardSlider> = (props) => {
  const {
    data,
    subTitle,
    cardType,
    height,
    width,
    testId,
    scrollKey,
    setMusic,
  } = props;

  const handelMusicPlay = (music: any | undefined) => {
    setMusic &&
      setMusic({
        type: MUSIC_STATES.SET_MUSIC,
        payload: {
          showPlayer: true,
          musicType: data.type || '',
          currentSound: music.fullData,
          isPlaying: true,
        },
      });
  };

  return (
    <Fragment>
      {subTitle && (
        <h3 className="my-2 text-base font-semibold sm:text-1xl text-charcoalC1" id="daytime-essentials">
          {subTitle}
        </h3>
      )}

      <div
        className={`${props.targetID === "essentials" ? "border-2 rounded-md border-themeBlue p-1":""}`}
      >
        <Slider width="w-8" height="h-8" scrollKey={scrollKey}>
          {data?.map((item: any, index: number) => (
            <DayCard
              key={index}
              id={item.id}
              width={width}
              height={height}
              title={item.title}
              cardType={cardType}
              logo={item.logo_image}
              bgImage={item.image1}
              content={item.content_link}
              onClick={(data) => handelMusicPlay(data)}
              testId={testId}
              fullData={item}
            />
          ))}
        </Slider>
      </div>
    </Fragment>
  );
};

export default DayCardSlider;
