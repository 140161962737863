import React, { ReactNode } from "react";

interface JournalCardProps {
  children: ReactNode;
  icon?: ReactNode;
  outerTitle?: string;
  date?: string | null | undefined;
  iconClass?: string;
  titleClass?: string;
  dateClass?: string;
  minHeight?: string;
}

const JournalCard = ({
  children,
  icon,
  outerTitle,
  date,
  iconClass,
  titleClass,
  dateClass,
  minHeight = "min-h-[100px]",
}: JournalCardProps) => {
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between">
        <div className={`flex items-center ${iconClass ? iconClass : ""}`}>
          {icon}
          <p
            className={`text-base sm:text-xl ml-2 ${titleClass ? titleClass : ""}`}
          >
            {outerTitle}
          </p>
        </div>
        {date && (
          <p className={`px-4 text-sm ${dateClass ? dateClass : ""}`}>{date}</p>
        )}
      </div>
      <div className={`bg-darkGray2 p-2 sm:p-4 rounded-md mt-2 ${minHeight}`}>
        {children}
      </div>
    </div>
  );
};

export default JournalCard;
