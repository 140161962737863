import React, { FC } from "react";
import { ISwitchAction, ISwitchReducer } from "reducers/switchReducer";
import TopRightUserMenu from "./TopRightUserMenu";
import { Bars3Icon } from "@heroicons/react/24/solid";
import { IMusicAction } from "@reducers/musicReducer";

interface HeaderProps {
  collapsed: boolean;
  dispatch: React.Dispatch<ISwitchAction>;
  dispatchMucicState: React.Dispatch<IMusicAction>;
  state: ISwitchReducer;
  toggleCollapse: () => void;
  isMobile?: boolean;
}

const Header: FC<HeaderProps> = ({
  collapsed,
  dispatch,
  dispatchMucicState,
  toggleCollapse,
  state,
  isMobile,
}) => {
  const getLeftMenuStyle = () => {
    if (collapsed && isMobile) {
      return "pl-0";
    } else if (collapsed && !isMobile) {
      return "pl-[60px]";
    } else {
      return "pl-[230px] z-50 bg-darkGray2 w-full sm:w-16 h-16";
    }
  };
  return (
    <header
      className={`fixed z-[2] w-full border-b border-white border-opacity-5 bg-darkGray2`}
    >
      <div
        className={`relative flex h-16 w-full items-center p-4 ${!collapsed && isMobile ? "" : "justify-between"}`}
      >
        <button
          name="btn-collapsed"
          onClick={toggleCollapse}
          aria-label="btn-collapsed"
          data-testid="collapse-button"
          className={getLeftMenuStyle()}
        >
          <Bars3Icon className="h-5 w-5" />
        </button>
        <div
          className={`${!collapsed && isMobile ? "fixed right-4 ml-3" : "relative"}`}
        >
          <TopRightUserMenu
            dispatch={dispatch}
            state={state}
            dispatchMucicState={dispatchMucicState}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
