import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowDownIcon } from "../../assets/icons/ArrowDownIcon";
import { CheckIcon } from "@assets/icons/CheckIcon";
import { format, parseISO } from "date-fns";
import { scrollIntoView } from "@backend/scroller";
import ChallengeIcons from "./ChallengeIcons";

type ChallengeTaskProps = {
  isOpen?: boolean;
  isChecked?: boolean;
  title: string;
  type: "infinite" | "finite";
  description: string;
  completedDate: string;
  className?: string;
  moveTo: string;
  points: number;
  onClick?: () => void;
  pageType?: string;
  icon?: string;
};

const ChallengeTask: FC<ChallengeTaskProps> = ({ isOpen, isChecked = false, title, description, completedDate, className = "", moveTo, type, onClick, pageType = "sleep-today", points, icon }) => {
  // const navigate = useNavigate();
  const date = parseISO(completedDate);
  const navigate = useNavigate();
  const formattedDate = format(date, "MMM d, yyyy hh:mm");
  const handleScrollNavigate = () => {
    if (onClick) {
      onClick();
    }
    // ? Removed hardcoded scroll to target using router to the one being used in refresh. If this approach is not good then we can remove and use the previous one?
    // pageType === "sleep-today" && moveTo
    //   ? onScrollClick && onScrollClick(moveTo)
    //   : navigate("/sleep-today", { state: { scrollToTargetID: moveTo } });
    if (pageType === "sleep-today" && moveTo) {
      if (!moveTo.includes("daytime")) {
        let el = document.getElementById(moveTo);
        scrollIntoView(el);
        if (window.location.hash !== `#${moveTo}`) window.location.href = `#${moveTo}`;
      } else {
        // * For Pages other than sleep => daytime page.
        navigate(`/daytime-support#${moveTo}`);
        // * scroll to target when the navigation is done, delay added for successful scroll
        setTimeout(() => {
          let el = document.getElementById(moveTo);
          scrollIntoView(el, undefined, true);
        }, 1000);
      }
    }
  };

  const ArrowButton = () => (
    <div className="flex w-10 items-center justify-center">
      <div className="cursor-pointer p-2">
        <ArrowDownIcon className="-rotate-90" color={isChecked ? "#767090" : "#ffffff"} width={12} height={12} />
      </div>
    </div>
  );

  return (
    <div className={`${isChecked ? "bg-darkPurpleC2/30" : "bg-darkPurpleC2"} mb-2 flex cursor-pointer items-center rounded-lg p-3 shadow ${className}`} onClick={handleScrollNavigate}>
      {/* If finite challenges then show tick / no tick icon */}
      {type === "finite" ? isChecked ? <CheckIcon /> : <div className="h-6 w-6 rounded-full bg-lightishBeigeWhiteC1_3" /> : null}
      {/* If infinite challenges show Points for each item */}
      {type === "infinite" && (
        <div className="m-auto min-w-[3.5em] rounded-lg bg-darkGray2 px-2 py-0.5 text-center text-sm font-bold text-white sm:min-w-[4em] sm:px-2.5 sm:py-1 sm:text-[inherit]">{points}</div>
      )}
      <div className="mr-2 flex w-full items-center justify-between sm:mr-0">
        <div className="ml-2 w-full sm:ml-4">
          <div className="flex items-center gap-2">
            {/* render icons based on infinite challenges icon name */}
            <ChallengeIcons iconName={icon} key={icon} className="min-h-[20px] min-w-[20px] fill-white sm:min-h-[23px] sm:min-w-[23px]" />
            <div>
              <p className="font-bold text-white">{title}</p>
              <p className="md:text-md text-sm font-normal text-lightPurpleC1_2 lg:text-[16px]">{description}</p>
              {isChecked && completedDate && isOpen && <p className="mt-2 text-xs font-light text-lightPurpleC1_2">completed on {formattedDate}</p>}
            </div>
          </div>
        </div>
        {moveTo && <ArrowButton />}
      </div>
    </div>
  );
};

export default ChallengeTask;
