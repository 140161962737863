/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, FC, useEffect } from "react";
import AccordionContainer from "./AccordionContainer";
import { IQuestionData } from "./AgeSelection";
import { useUser, useUpdateUserSettings } from "@backend/hooks/useUser";
import { getQuestions } from "@pages/Sleep/SettingsPage";
import SleepTextBox from "./SleepTextBox";
import { ChangeEvent } from "react";
import CustomButton from "@components/CustomButton";
import VerticalTabsMultiSelect from "./VerticalTabsMultiSelect";

interface ISleepGoalProps {
  data: IQuestionData;
}

const SleepGoal: FC<ISleepGoalProps> = ({ data }) => {
  const { refetch, data: { body: { settings } } = { body: { settings: {} } } } =
    useUser();

  const tabs = [
    { id: "fall_asleep_faster", text: "I want to fall asleep faster" },
    { id: "stay_asleep", text: "I want to stay asleep through the night" },
  ];
  const [selectedTabs, setSelectedTabs] = useState<string[]>([]);
  const [sleepGoalOther, setSleepGoalOther] = useState("");
  const [initialData, setInitialData] = useState<{
    tabs: string[];
    sleepGoalOther: string;
  }>({
    tabs: [],
    sleepGoalOther: "",
  });

  let questions = getQuestions(data, 2);

  const mutateUserSettings = useUpdateUserSettings(refetch);

  const updateUserSettings = () => {
    const apiData = {
      ...settings,
      sleep_goal_many: [...selectedTabs, sleepGoalOther],
    };
    mutateUserSettings.mutate(apiData);

    // Data has been saved, reset the initial data
    setInitialData({ tabs: selectedTabs, sleepGoalOther });
  };

  useEffect(() => {
    const unmatchedItem = settings.sleep_goal_many.find(
      (item: string) => !tabs.some((tab) => tab.id === item),
    );
    setSleepGoalOther(!unmatchedItem ? "" : unmatchedItem);

    const matchedItems = settings.sleep_goal_many.filter((item: string) =>
      tabs.some((tab) => tab.id === item),
    );
    setSelectedTabs(matchedItems);

    // Set initial data when the component mounts
    if (!initialData.tabs.length && initialData.sleepGoalOther === "") {
      setInitialData({
        tabs: matchedItems,
        sleepGoalOther: !unmatchedItem ? "" : unmatchedItem,
      });
    }
  }, [settings]);

  const handelSleepGoalChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSleepGoalOther(event.target.value);
  };

  const handelSave = () => {
    updateUserSettings();
  };

  const handleMultiTabChange = (item: string) => {
    setSelectedTabs(
      selectedTabs.includes(item)
        ? selectedTabs.filter((i) => i !== item)
        : [...selectedTabs, item],
    );
  };

  const isDataChanged =
    JSON.stringify(selectedTabs) !== JSON.stringify(initialData.tabs) ||
    sleepGoalOther !== initialData.sleepGoalOther;

  return (
    <AccordionContainer>
      <p
        className="small-white-text text-base sm:text-lg"
        data-testid="sleepgoalquestion"
      >
        {questions && questions[0]}
      </p>

      <div className="flex flex-col items-center justify-center">
        <VerticalTabsMultiSelect
          selected={selectedTabs}
          tabs={tabs}
          onChange={handleMultiTabChange}
        />

        <SleepTextBox onChange={handelSleepGoalChange} value={sleepGoalOther} />

        <CustomButton
          className="min-w-[150px] max-w-[280px] w-full sm:min-w-[200px]"
          onClick={handelSave}
          disabled={!isDataChanged}
        >
          Save
        </CustomButton>
      </div>
    </AccordionContainer>
  );
};

export default SleepGoal;
